import { IconProps } from "../../types";
const MenoIcon = ({
    strokeColor = "black",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.8413 11.4676C14.8413 15.1504 11.9459 18.1352 11.9459 18.1352C11.9459 18.1352 9.05151 15.1504 9.05151 11.4676C9.05151 7.78578 11.9459 4.80005 11.9459 4.80005C11.9459 4.80005 14.8413 7.78578 14.8413 11.4676V11.4676Z"
                stroke={strokeColor}
                strokeWidth="1.2"
                stroke-linejoin="round"
            />
            <path
                d="M14.1346 8.12341C15.6994 6.83662 17.3828 6.36084 17.3828 6.36084C17.3828 6.36084 18.7219 10.5612 17.2216 13.8119C15.7203 17.0645 11.9458 18.1357 11.9458 18.1357"
                stroke={strokeColor}
                strokeWidth="1.2"
                stroke-linejoin="round"
            />
            <path
                d="M17.9254 10.4827C19.8449 10.1826 21.5463 10.6755 21.5463 10.6755C21.5463 10.6755 21.0171 15.1085 18.3664 17.1692C15.7147 19.2291 11.9459 18.136 11.9459 18.136"
                stroke={strokeColor}
                strokeWidth="1.2"
                stroke-linejoin="round"
            />
            <path
                d="M9.84846 8.19853C8.25805 6.85666 6.50925 6.35999 6.50925 6.35999C6.50925 6.35999 5.17015 10.5613 6.67047 13.812C8.17174 17.0646 11.9463 18.1358 11.9463 18.1358"
                stroke={strokeColor}
                strokeWidth="1.2"
                stroke-linejoin="round"
            />
            <path
                d="M5.99482 10.4864C4.06299 10.1777 2.34549 10.6754 2.34549 10.6754C2.34549 10.6754 2.87563 15.1084 5.52632 17.1691C8.17797 19.229 11.9458 18.1359 11.9458 18.1359C11.9458 18.1359 11.9439 18.1103 11.9364 18.0618"
                stroke="black"
                strokeWidth="1.2"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default MenoIcon;
