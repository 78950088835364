import { IconProps } from "../../types";

const NavLeftIcon = ({
    strokeColor = "#EA515A",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75995 11.52C5.75995 11.3088 5.85232 11.1072 6.01859 10.944L10.7849 6.00001C10.9512 5.83681 11.136 5.76001 11.3299 5.76001C11.7364 5.76001 12.0504 6.07681 12.0504 6.50881C12.0504 6.71041 11.9858 6.91201 11.8472 7.04641L10.5725 8.41921L7.94915 10.8768L9.74115 10.7424H18.461C18.8951 10.7424 19.2 11.0688 19.2 11.52C19.2 11.9712 18.8951 12.2976 18.461 12.2976H9.74115L7.93991 12.1632L10.5725 14.6208L11.8472 15.9936C11.9858 16.128 12.0504 16.3296 12.0504 16.5312C12.0504 16.9632 11.7364 17.28 11.3299 17.28C11.136 17.28 10.9512 17.2032 10.7849 17.04L6.01859 12.096C5.85232 11.9328 5.75995 11.7312 5.75995 11.52Z"
                fill={fill}
                stroke={strokeColor}
                strokeWidth="0.481884"
            />
        </svg>
    );
};

export default NavLeftIcon;
