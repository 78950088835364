import { IconProps } from "../../types";

const WhatsAppIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.00006 22L3.41362 16.8669C2.5397 15.3603 2.08125 13.654 2.08602 11.9097C2.08602 6.44392 6.55593 2 12.043 2C14.7078 2 17.2102 3.03137 19.087 4.90399C20.9685 6.77662 22.0048 9.26711 22 11.9144C22 17.3802 17.5301 21.8241 12.0383 21.8241H12.0335C10.3668 21.8241 8.7288 21.4059 7.27226 20.6169L2.00006 22ZM7.52537 18.8251L7.82623 19.0057C9.09652 19.7567 10.5531 20.1511 12.0383 20.1559H12.043C16.6037 20.1559 20.319 16.4629 20.319 11.9192C20.319 9.71863 19.4595 7.65114 17.8978 6.09221C16.3362 4.53327 14.2541 3.67776 12.043 3.67776C7.48239 3.673 3.76701 7.36597 3.76701 11.9097C3.76701 13.4639 4.20159 14.98 5.03253 16.2918L5.22833 16.6055L4.39261 19.6426L7.52537 18.8251Z"
            fill="white"
        />
        <path
            d="M2.34875 21.6531L3.71456 16.6958C2.86929 15.2462 2.42516 13.597 2.42516 11.9145C2.42994 6.63881 6.74226 2.34698 12.0431 2.34698C14.6171 2.34698 17.0288 3.34508 18.8435 5.15117C20.6582 6.95725 21.6563 9.36219 21.6563 11.9192C21.6563 17.1949 17.3392 21.4867 12.0431 21.4867H12.0383C10.429 21.4867 8.84827 21.0827 7.44426 20.3223L2.34875 21.6531Z"
            fill="url(#paint0_linear_1549_2088)"
        />
        <path
            d="M2.00006 22L3.41362 16.8669C2.5397 15.3603 2.08125 13.654 2.08602 11.9097C2.08602 6.44392 6.55593 2 12.043 2C14.7078 2 17.2102 3.03137 19.087 4.90399C20.9685 6.77662 22.0048 9.26711 22 11.9144C22 17.3802 17.5301 21.8241 12.0383 21.8241H12.0335C10.3668 21.8241 8.7288 21.4059 7.27226 20.6169L2.00006 22ZM7.52537 18.8251L7.82623 19.0057C9.09652 19.7567 10.5531 20.1511 12.0383 20.1559H12.043C16.6037 20.1559 20.319 16.4629 20.319 11.9192C20.319 9.71863 19.4595 7.65114 17.8978 6.09221C16.3362 4.53327 14.2541 3.67776 12.043 3.67776C7.48239 3.673 3.76701 7.36597 3.76701 11.9097C3.76701 13.4639 4.20159 14.98 5.03253 16.2918L5.22833 16.6055L4.39261 19.6426L7.52537 18.8251Z"
            fill="url(#paint1_linear_1549_2088)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.55508 7.76527C9.36884 7.35178 9.17304 7.34227 8.99634 7.33752C8.85308 7.33276 8.68593 7.33276 8.51879 7.33276C8.35165 7.33276 8.08422 7.39455 7.85499 7.6417C7.62576 7.88885 6.98584 8.48771 6.98584 9.70919C6.98584 10.9259 7.87887 12.1046 8.00303 12.271C8.12719 12.4373 9.727 15.0181 12.2533 16.0115C14.3545 16.8385 14.7843 16.6721 15.238 16.6293C15.6917 16.5866 16.7088 16.0305 16.919 15.4506C17.1243 14.8708 17.1243 14.3765 17.0622 14.2719C17.0002 14.1674 16.833 14.1056 16.5847 13.982C16.3364 13.8584 15.1138 13.2596 14.8846 13.174C14.6554 13.0932 14.4882 13.0504 14.3259 13.2976C14.1587 13.5447 13.6812 14.1008 13.5379 14.2672C13.3946 14.4335 13.2466 14.4525 12.9983 14.329C12.7499 14.2054 11.9476 13.944 10.9973 13.098C10.2571 12.4421 9.75566 11.6293 9.61239 11.3822C9.46912 11.135 9.59806 11.002 9.72223 10.8784C9.83206 10.7691 9.97056 10.5885 10.0947 10.4459C10.2189 10.3033 10.2619 10.1987 10.343 10.0324C10.4242 9.86603 10.386 9.72345 10.3239 9.59987C10.2619 9.48105 9.77476 8.25482 9.55508 7.76527Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1549_2088"
                x1="12.002"
                y1="21.6521"
                x2="12.002"
                y2="2.34631"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#20B038" />
                <stop offset="1" stop-color="#60D66A" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1549_2088"
                x1="12.002"
                y1="21.9981"
                x2="12.002"
                y2="2"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F9F9F9" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
);

export default WhatsAppIcon;
