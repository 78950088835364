import { createApi } from "@reduxjs/toolkit/query/react";

import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

import { transform as transformPodReaction } from "api/dataTransformations/PodTransformation/Reaction";
import { dynamicBaseQuery } from "api";

interface GetReactionsRequest {
  roomId: string;
  cursor?: string | undefined;
  timestampRef: number;
}

interface GetReactionsResponse {
  reactions: Pods.Reaction[];
  cursor: string | undefined;
}

export const api = createApi({
  reducerPath: "api/pods-reactions",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    getReactions: builder.query<GetReactionsResponse, GetReactionsRequest>({
      query: ({ roomId, cursor }) => ({
        url: `audio_rooms/${roomId}/reactions${
          cursor ? `?cursor=${cursor}` : ""
        }`,
        method: "GET",
      }),

      transformResponse: ({
        reactions,
        cursor,
      }: {
        cursor: string | undefined;
        reactions: Api.AudioRoomReaction[];
      }) => ({ cursor, reactions: reactions.map(transformPodReaction) }),
    }),
  }),
});

export const { useGetReactionsQuery } = api;
