import AlertIcon from "./Icons/Outline/Alert";
import BadgesIcon from "./Icons/Outline/Badges";
import BlockedUsersIcon from "./Icons/Outline/BlockedUsers";
import CloseIcon from "./Icons/Outline/Close";
import DiscoverySettingsIcon from "./Icons/Outline/DiscoverySettings";
import DashboardIcon from "./Icons/Outline/Dashboard";
import FeedSettingsIcon from "./Icons/Outline/FeedSettings";
import GroupsIcon from "./Icons/Outline/Groups";
import EditIcon from "./Icons/Outline/Edit";
import GuidlinesIcon from "./Icons/Outline/Guidlines";
import HelpIcon from "./Icons/Outline/Help";
import CopyIcon from "./Icons/Outline/Copy";
import NavRightIcon from "./Icons/Outline/NavRight";
import NavLeftIcon from "./Icons/Outline/NavLeft";
import BoostIcon from "./Icons/filled/Boost";
import ReadReceiptsIcon from "./Icons/Outline/ReadReceipts";
import InfoIcon from "./Icons/Outline/Info";
import WaveIcon from "./Icons/filled/Wave";
import FacebookIcon from "./Icons/social/Facebook";
import PinterestIcon from "./Icons/social/Pinterest";
import RedditIcon from "./Icons/social/Reddit";
import TelegramIcon from "./Icons/social/Telegram";
import TikTokIcon from "./Icons/social/TikTok";
import XIcon from "./Icons/social/X";
import WhatsAppIcon from "./Icons/social/WhatsApp";
import BabyIcon from "./Icons/Outline/Baby";
import ChildIcon from "./Icons/Outline/Child";
import FertilityIcon from "./Icons/Outline/Fertility";
import LogoutIcon from "./Icons/Outline/Logout";
import MenoIcon from "./Icons/Outline/Meno";
import PregnantIcon from "./Icons/Outline/Pregnant";
import SubscriptionIcon from "./Icons/Outline/Subscription";

export const iconMapping = {
    alert: AlertIcon,
    badges: BadgesIcon,
    baby: BabyIcon,
    boost: BoostIcon,
    blockedUsers: BlockedUsersIcon,
    child: ChildIcon,
    close: CloseIcon,
    discoverySettings: DiscoverySettingsIcon,
    dashboard: DashboardIcon,
    copy: CopyIcon,
    edit: EditIcon,
    feedSettings: FeedSettingsIcon,
    fertility: FertilityIcon,
    groups: GroupsIcon,
    guidlines: GuidlinesIcon,
    help: HelpIcon,
    logout: LogoutIcon,
    meno: MenoIcon,
    pregnant: PregnantIcon,
    navRight: NavRightIcon,
    info: InfoIcon,
    navLeft: NavLeftIcon,
    readReceipts: ReadReceiptsIcon,
    subscription: SubscriptionIcon,
    wave: WaveIcon,

    //social icons
    facebook: FacebookIcon,
    pinterest: PinterestIcon,
    reddit: RedditIcon,
    telegram: TelegramIcon,
    tiktok: TikTokIcon,
    x: XIcon,
    whatsApp: WhatsAppIcon,
};
