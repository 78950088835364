import { ApiChild } from "types/api/me/children";
import {
  Child,
  // ChildPregnancy,
  ChildTTC,
  // Experience,
  ChildAny,
  ChildPregnancy,
  ChildMenopause,
  ChildOtherLifeStage
} from "types/Child";

const transformChild = ({
  id,
  gender,
  dob,
  dob_precise,
  first_name,
  age
}: ApiChild): Child => ({
  id,
  gender,
  firstName: first_name,
  dob,
  dobPrecise: dob_precise,
  age: {
    years: age?.years,
    months: age?.months
  }
});

const transformTTC = ({ id, ttc_since }: ApiChild): ChildTTC => ({
  id,
  gender: "TTC" as "TTC",
  ttcSince: ttc_since!
});

const transformPregnancy = ({
  id,
  dob,
  dob_precise,
  age,
  experiences
}: ApiChild): ChildPregnancy => ({
  id,
  gender: "P",
  dob,
  dobPrecise: dob_precise,
  age: {
    years: age?.years,
    months: age?.months,
    weeksPregnant: age?.weeks_pregnant
  },
  experiences: experiences!
});

const transformMenopause = ({ id, experiences }: ApiChild): ChildMenopause => ({
  id,
  gender: "MP",
  experiences: experiences!
});

const transformOtherLifeStage = ({ id }: ApiChild): ChildOtherLifeStage => ({
  id,
  gender: "OLS"
});

interface IGenderMap {
  [key: string]: (
    apiChild: ApiChild
  ) => Child | ChildTTC | ChildPregnancy | ChildMenopause | ChildOtherLifeStage;
}

const transformGenderMap: IGenderMap = {
  TTC: transformTTC,
  P: transformPregnancy,
  M: transformChild,
  F: transformChild,
  O: transformChild,
  NA: transformChild,
  MP: transformMenopause,
  OLS: transformOtherLifeStage
};

export const transform = (apiChild: ApiChild): ChildAny => {
  // const newExperiences =
  //   experiences &&
  //   experiences.map((experience) => experience.code as Experience);

  const transformFunction = transformGenderMap[apiChild.gender];
  return transformFunction && transformFunction(apiChild);
};

// export const revert = (child: ChildAny): ApiChildRequest => {
//   const { firstName, dob, dobPrecise, age } = child as Child;
//   const { ttcSince } = child as ChildTTC;

//   return {
//     id: child.id,
//     first_name: firstName,
//     gender: child.gender as Gender,
//     dob: dob as string,
//     age: age as any,
//     dob_precise: dobPrecise,
//     ttc_since: ttcSince,
//   };
// };

const revertTTC = (child: ChildAny) => {
  const { ttcSince } = child as ChildTTC;

  return { gender: "TTC", ttc_since: `${ttcSince}-01` };
};

const revertPregnancy = (child: ChildAny) => {
  const { age, experiences } = child as ChildPregnancy;
  return {
    gender: "P",
    age: {
      weeks_pregnant: age?.weeksPregnant
    },
    experiences: experiences?.map(({ code }) => code)
  };
};

const revertChild = (child: ChildAny) => {
  const { gender, firstName, experiences, dob, dobPrecise, age } =
    child as Child;
  return {
    gender,
    first_name: firstName,
    dob,
    dob_precise: dobPrecise,
    experiences: experiences?.map(({ code }) => code),
    age
  };
};

const revertMenopause = (child: ChildAny) => {
  const { gender, experiences } = child as ChildMenopause;
  return {
    gender,
    experiences: experiences?.map(({ code }) => code)
  };
};

const revertOtherLifeStage = (child: ChildAny) => {
  const { gender } = child as ChildOtherLifeStage;
  return {
    gender
  };
};

const ChildRevertMap: {
  [key: string]: (child: ChildAny) => any;
} = {
  TTC: revertTTC,
  P: revertPregnancy,
  M: revertChild,
  F: revertChild,
  O: revertChild,
  NA: revertChild,
  MP: revertMenopause,
  OLS: revertOtherLifeStage
};

export const revert = (child: ChildAny) => {
  const revertFunction = ChildRevertMap[child.gender];
  return revertFunction && { id: child.id, ...revertFunction(child) };
};
