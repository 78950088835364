import { Author, AuthorIncognito } from "types/Author";
import { Post, PostPoll, PostText } from "types/Post";

export function hasAuthor(author: Author | AuthorIncognito): author is Author {
  return "badges" in author;
}

export function hasImage(post: Post): post is PostText {
  return "image" in post && post.image !== undefined;
}

export function hasPoll(post: Post): post is PostPoll {
  return "options" in post;
}

export function hasActivePoll(post: Post): post is PostPoll {
  return "options" in post && post.voted !== true;
}

export function hasInactivePoll(post: Post): post is PostPoll {
  return "options" in post && post.voted === true;
}
