import { Author } from "types/Author";
import { Api } from "types/api/author";

import ProfileBadgeTransformation from "api/dataTransformations/ProfileBadgeTransformation";

export function transform(apiAuthor: Api.Author): Author {
  return {
    uid: apiAuthor.uid,
    name: apiAuthor.name,
    avatarUrl: apiAuthor.avatar_url,
    location: {
      name: apiAuthor.location?.name
    },
    badges: apiAuthor.badges,
    profileBadges: apiAuthor.profile_badges?.map(ProfileBadgeTransformation),
    liveness: {
      online: apiAuthor.liveness.online,
      lastSeenAt: apiAuthor.liveness.last_seen_at
    }
  };
}

export default transform;
