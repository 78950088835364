import { createContext, useMemo, useEffect } from "react";
import { useAppSelector } from "hooks";
import {
  selectAuthenticated,
  selectLoginMethod
} from "reducers/SessionReducer";
import { useLazyGetRegistrationQuery } from "api/me/registration";

interface AuthContextType {
  authenticated: boolean;
}

export const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const authenticated = useAppSelector(selectAuthenticated);
  const loginMethod = useAppSelector(selectLoginMethod);

  const [refreshRegistration] = useLazyGetRegistrationQuery();

  useEffect(() => {
    if (loginMethod) {
      refreshRegistration({
        method: loginMethod,
        target: import.meta.env.VITE_REGISTRATION_MODE
      });
    }
  }, [window.location.pathname, loginMethod]);

  const value: AuthContextType = useMemo(
    () => ({
      authenticated: !!authenticated
    }),
    [authenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
