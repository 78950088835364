import { createApi } from "@reduxjs/toolkit/query/react";

import {
  ChildAny,
  Child,
  ChildTTC,
  ChildPregnancy,
  ChildMenopause,
  ChildOtherLifeStage
} from "types/Child";

import { ApiChild } from "types/api/me/children";

import {
  transform as transformChild,
  revert as revertChild
} from "api/dataTransformations/ChildTransformation";

import { dynamicBaseQuery } from "api";

type MeChildrenRequest =
  | Omit<Child, "id">
  | Omit<ChildTTC, "id">
  | Omit<ChildPregnancy, "id">
  | Omit<ChildMenopause, "id">
  | Omit<ChildOtherLifeStage, "id">;

type MeChildrenResponse = {
  child:
    | Child
    | ChildTTC
    | ChildPregnancy
    | ChildMenopause
    | ChildOtherLifeStage;
};

type MeChildDeleteRequest = {
  id: number;
};

type MeChildDeleteResponse = {};

type MeChildUpdateRequest = Child | ChildTTC | ChildPregnancy | ChildMenopause;

type MeChildUpdateResponse = {
  child: ChildAny;
};

export const api = createApi({
  reducerPath: "api/me/children",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    createChild: builder.mutation<MeChildrenResponse, MeChildrenRequest>({
      query: (child: MeChildrenRequest) => ({
        url: `me/children`,
        method: "POST",
        body: {
          child: revertChild(child as ChildAny)
        }
      }),
      transformResponse: (apiChild: ApiChild): MeChildrenResponse => ({
        child: transformChild(apiChild)
      })
    }),

    updateChild: builder.mutation<MeChildUpdateResponse, MeChildUpdateRequest>({
      query: (child: MeChildUpdateRequest) => ({
        url: `me/children/${child.id}`,
        method: "PATCH",
        body: {
          child: revertChild(child as ChildTTC)
        }
      }),
      transformResponse: (apiChild: ApiChild): MeChildUpdateResponse => ({
        child: transformChild(apiChild)
      })
    }),

    deleteChild: builder.mutation<MeChildDeleteResponse, MeChildDeleteRequest>({
      query: ({ id }) => ({
        url: `me/children/${id}`,
        method: "DELETE"
      })
    })
  })
});

export const {
  useCreateChildMutation,
  useDeleteChildMutation,
  useUpdateChildMutation
} = api;
