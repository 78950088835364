import React, { useEffect, useState } from "react";
// import classNames from "classnames";
import { Pods } from "types/Pods";
import styles from "./ReactionAnimation.module.scss";

export interface Props {
  children: React.ReactNode;
  reaction: Pods.Reaction | undefined;
  onComplete: (reaction: Pods.Reaction) => void;
}

// Hardcoding, because it's been updated twice in 2 years and
// itsn't worth an API call for every session. - JP
const REACTION_CODE_MAP: { [key: string]: string } = {
  happy: "😂",
  excited: "❤️",
  bored: "👍",
  sad: "😥",
  angry: "😯",
  hug: "🤗",
  wave: "👋",
  clap: "👏",
  agree: "💯",
};

function ReactionAnimation({
  children,
  reaction,
  onComplete,
}: Props): JSX.Element {
  const [playing, setPlaying] = useState(false);
  const [content, setContent] = useState<string>();

  useEffect(() => {
    if (!playing && reaction) {
      setPlaying(true);

      setTimeout(() => {
        setPlaying(false);
        onComplete(reaction);
      }, 2000);

      setContent(REACTION_CODE_MAP[reaction.code]);
    }
  }, [reaction]);

  return (
    <div className={styles.wrapper}>
      {children}

      <div className={styles.animationContainer}>
        {reaction && content && (
          <div className={styles.animation}>{content}</div>
        )}
      </div>
    </div>
  );
}

export default ReactionAnimation;
