import { createApi } from "@reduxjs/toolkit/query/react";

import { ApiMeRegistrationResponse } from "types/api/me/registration";

import { transform as transformMe } from "api/dataTransformations/MeTransformation";
import { transformRegBody } from "api/dataTransformations/RegistrationTransformation";

import { dynamicBaseQuery } from "api";

import {
    MeRegistratonCompleteRequest,
    MeRegistratonCompleteResponse,
    MeRegistratonRequest,
    MeRegistratonResponse,
    MeRegistratonSkipStepRequest,
    MeRegistratonSkipStepResponse,
    MeRegistratonStepCompleteRequest,
    MeRegistratonStepCompleteResponse,
} from "./types";
import { ExperimentStatus } from "@/reducers/MeReducer/types";

export const api = createApi({
    reducerPath: "api/me/registration",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        getRegistration: builder.query<
            MeRegistratonResponse,
            MeRegistratonRequest
        >({
            query: ({ method, target }) => ({
                url: `me/registration/${method}?target=${target}`,
                method: "GET",
            }),
            transformResponse: ({
                user,
                registration,
            }: ApiMeRegistrationResponse): MeRegistratonResponse => ({
                me: transformMe(user),
                registration: transformRegBody(registration),
                experiments: user.experiments as Record<
                    string,
                    ExperimentStatus
                >,
            }),
        }),
        registrationComplete: builder.mutation<
            MeRegistratonCompleteResponse,
            MeRegistratonCompleteRequest
        >({
            query: ({ method }) => ({
                url: `me/registration/${method}`,
                method: "PUT",
                body: {
                    target: import.meta.env.VITE_REGISTRATION_MODE,
                },
            }),
        }),

        registrationStepComplete: builder.mutation<
            MeRegistratonStepCompleteResponse,
            MeRegistratonStepCompleteRequest
        >({
            query: ({ method, step }) => ({
                url: `me/registration/${method}/complete`,
                method: "PUT",
                body: {
                    registration: {
                        step
                    },
                },
            }),
        }),

        registrationSkipStep: builder.mutation<
            MeRegistratonSkipStepResponse,
            MeRegistratonSkipStepRequest
        >({
            query: ({ method, step }) => ({
                url: `me/registration/${method}/skip`,
                method: "PUT",
                body: {
                    registration: { step },
                },
            }),
        }),
    }),
});

export const {
    useGetRegistrationQuery,
    useLazyGetRegistrationQuery,
    useRegistrationCompleteMutation,
    useRegistrationStepCompleteMutation,
    useRegistrationSkipStepMutation,
} = api;
