import classNames from "classnames";
import styles from "./Button.module.scss";

export interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submit?: boolean;
  id?: string;
  testId?: string;
  value?: string | number;
}

function Button({
  children,
  className,
  disabled,
  onClick,
  submit,
  id,
  testId,
  value
}: Props): JSX.Element {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={classNames(styles.wrapper, className)}
      onClick={onClick}
      disabled={disabled}
      id={id}
      data-testid={testId}
      value={value}
    >
      {children}
    </button>
  );
}

export default Button;
