import { useAppSelector, useAppDispatch } from "hooks";
import {
  selectParticipantVolumeLevelsByAgoraId,
  selectParticipantMuteStatusByAgoraId,
  selectNextReactionByUserUid,
  deleteReactionQueueByUserUid,
} from "reducers/ActivePodReducer";

import type { Pods } from "types/Pods";
import Participant from "components/Pods/Participant";
import ReactionAnimation from "components/Pods/ReactionAnimation";

export interface Props {
  participant: Pods.Participant;
  className?: string;
  showProBadge?: boolean;
  showRole?: boolean;
}

function ParticipantContainer({
  participant,
  showProBadge = false,
  showRole = false,
  className,
}: Props): JSX.Element {
  const { agoraId } = participant;
  const dispatch = useAppDispatch();

  const volume = useAppSelector((state) =>
    selectParticipantVolumeLevelsByAgoraId(state, agoraId)
  );

  const muted = useAppSelector((state) =>
    selectParticipantMuteStatusByAgoraId(state, agoraId)
  );

  const reaction = useAppSelector((state) =>
    selectNextReactionByUserUid(state, participant.userUid)
  );

  const handleReactionComplete = ({ userUid }: Pods.Reaction) => {
    dispatch(deleteReactionQueueByUserUid(userUid));
  };

  return (
    <ReactionAnimation reaction={reaction} onComplete={handleReactionComplete}>
      <Participant
        className={className}
        muted={muted}
        participant={participant}
        showProBadge={showProBadge}
        showRole={showRole}
        volume={volume}
      />
    </ReactionAnimation>
  );
}

ParticipantContainer.Mock = Participant.Mock;

export default ParticipantContainer;
