import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

export const transform = (
  source: Api.AudioRoomParticipant
): Pods.Participant => ({
  agoraId: source.agora_id,
  userUid: source.user_uid,
  firstName: source.first_name,
  avatarUrl: source.avatar_url,
  status: source.status,
  listeningOnly: source.listening_only,
  blocked: source.blocked,
  badges: source.badges,
  canBeTipped: source.can_be_tipped,
  tip: source.tip,
  locationName: source.location_name,
});

export default transform;
