import React from "react";
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import "./i18n";
import AuthProvider from "providers/AuthProvider";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";

import * as Sentry from "@sentry/react";

import ReactGA from "react-ga4";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

if (import.meta.env.VITE_GA_MEASUREMENT_ID) {
    ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID);
}

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        new Sentry.Replay(),
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],
    ignoreErrors: ["top.GLOBALS"],
    debug: true,
    tracesSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_SAMPLE_RATE || "1.0"
    ),
    environment: import.meta.env.VITE_ENVIRONMENT,
});

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
