/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";
import { Pods } from "types/Pods";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import Avatar from "components/Avatar";

import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import slugify from "helpers/slugify";
import styles from "./PodScheduledCard.module.scss";

export interface Props {
  room: Pods.Room;
}

function PodActiveCard({ room }: Props): JSX.Element {
  const { t } = useTranslation("PodScheduledCard");

  const hostNames = useMemo(
    () =>
      room.featuredParticipants
        .filter((participant) =>
          ["host", "cohost"].includes(participant.status)
        )
        .slice(0, 3)
        .map((host) => host.firstName)
        .join(", "),
    [room]
  );

  const dateScheduledAt = useMemo(
    () => (room.scheduledAt ? new Date(room.scheduledAt) : new Date()),
    [room.scheduledAt]
  );

  const timeZoneName = useMemo(
    () =>
      dateScheduledAt
        .toLocaleDateString("en-US", { timeZoneName: "long" })
        .split(",")[1],
    [dateScheduledAt]
  );

  return (
    <a
      href={`${import.meta.env.VITE_WEBSITE_URL}/pods/${room.id}/${slugify(
        room.title
      )}`}
      className={styles.wrapper}
    >
      <header>
        <h3>{room.title}</h3>
        <time dateTime={room.scheduledAt!}>
          {format(dateScheduledAt, "d MMM, h:mmaaa")}
        </time>
        <span className={styles.timezone}>{timeZoneName}</span>
      </header>

      <footer>
        <div className={styles.avatarsContainer}>
          {room.featuredParticipants
            .slice(0, 1)
            .map(({ userUid, avatarUrl, firstName }) => (
              <Avatar
                key={userUid}
                avatarUrl={avatarUrl}
                firstName={firstName}
                className={styles.avatar}
              />
            ))}
        </div>
        <div className={styles.hosts}>
          <span>{t("hosts", { count: 1 })}</span> {hostNames}
        </div>
      </footer>
    </a>
  );
}

PodActiveCard.Mock = function mock() {
  return (
    <div className={styles.wrapper}>
      <header>
        <h3>
          <Skeleton />
        </h3>
        <time>
          <Skeleton />
        </time>
        <span className={styles.timezone}>
          <Skeleton />
        </span>
      </header>

      <footer>
        <div className={styles.avatarsContainer}>
          <Skeleton circle width={66} height={66} />
        </div>
        <div className={styles.hosts}>
          <span>
            <Skeleton />
          </span>{" "}
          <Skeleton />
        </div>
      </footer>
    </div>
  );
};

export default PodActiveCard;
