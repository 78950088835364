import { useCallback, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "hooks/useOnClickOutside";

import useAppLink from "hooks/useAppLink";
import AppBarButton, {
    AppButtonFeature,
} from "components/AppBarButton/AppBarButton";
import Avatar from "components/Avatar";
import { useAppSelector } from "hooks";
import { selectAvatarUrl } from "reducers/MeReducer";
import imageAndroid from "./assets/android.svg";
import imageApple from "./assets/apple.svg";
import imageLogo from "./assets/logo.svg";
import imageMenu from "./assets/menu.svg";
import imageClose from "./assets/close.svg";
import styles from "./AppBar.module.scss";

import Popup from "@/DesignSystem/components/Popup";
import Select from "@/DesignSystem/components/Popup/Select";
import { generateMenuItems } from "./menuItems";
import classNames from "classnames";
import BetaIcon from "@/DesignSystem/components/Icon/Icons/special/beta";
import BetaMobileIcon from "@/DesignSystem/components/Icon/Icons/special/betaMobile";
import { useGetMeQuery } from "@/api/me";

function AppBar(): JSX.Element {
    const { t } = useTranslation("ComponentsAppBar");
    const avatarUrl = useAppSelector(selectAvatarUrl);
    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = window.location;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const getAppLink = useAppLink();
    const { data } = useGetMeQuery({});

    const menuItems = generateMenuItems({
        stripeAccess: data ? data.me.stripeAccess : false,
    });
    const handleClose = useCallback(() => {
        setIsMenuOpen(false);
    }, [setIsMenuOpen]);

    useOnClickOutside(ref, handleClose);

    const handleMenuToggle = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);
    }, [setIsMenuOpen, isMenuOpen]);

    const handleAppDownload = useCallback(async () => {
        const url = await getAppLink();
        window.location.href = url;
    }, []);

    const selectedFeature = useMemo(() => {
        switch (pathname) {
            case "/discovery":
                return AppButtonFeature.Discovery;
            case "/chat":
                return AppButtonFeature.Chat;
            case "/feed":
                return AppButtonFeature.Feed;
            case "/pods":
                return AppButtonFeature.Pods;
            case "/notifications":
                return AppButtonFeature.Notifications;
            case "/posts":
                return AppButtonFeature.Posts;
            default:
                return undefined;
        }
    }, [pathname]);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.logoContainer}>
                    <div className={styles.beta}>
                        <BetaIcon />
                    </div>
                    <div className={styles.logoContainer}>
                        <a href="/" title="Peanut">
                            <img src={imageLogo} alt={t("generic.peanut")} />
                        </a>
                        <div className={styles.mobileOnlyLogo}>
                            <BetaMobileIcon />
                        </div>
                    </div>
                </div>

                <div className={styles.buttons}>
                    <AppBarButton
                        feature={AppButtonFeature.Discovery}
                        selected={
                            selectedFeature === AppButtonFeature.Discovery
                        }
                    />

                    <AppBarButton
                        feature={AppButtonFeature.Chat}
                        selected={selectedFeature === AppButtonFeature.Chat}
                    />
                    <AppBarButton
                        feature={AppButtonFeature.Feed}
                        selected={selectedFeature === AppButtonFeature.Feed}
                    />
                    <AppBarButton
                        feature={AppButtonFeature.Pods}
                        selected={selectedFeature === AppButtonFeature.Pods}
                    />
                    <AppBarButton
                        feature={AppButtonFeature.Notifications}
                        selected={
                            selectedFeature === AppButtonFeature.Notifications
                        }
                    />
                    <AppBarButton
                        feature={AppButtonFeature.Posts}
                        selected={selectedFeature === AppButtonFeature.Posts}
                    />
                </div>

                <div className={styles.menuContainer} ref={ref}>
                    <Popup
                        content={
                            <Select
                                items={menuItems}
                                translation="ComponentsAppBar"
                            />
                        }
                    >
                        <Avatar
                            avatarUrl={avatarUrl!}
                            firstName="Test"
                            baseImageSize={38}
                            className={styles.menuIconDesktop}
                        />
                    </Popup>

                    <>
                        <span onClick={handleMenuToggle}>
                            <img
                                src={imageMenu}
                                alt="menu"
                                className={styles.menuIcon}
                            />
                        </span>
                    </>

                    <div
                        className={classNames(styles.menu, styles.mobileMenu, {
                            [styles.menuOpen]: isMenuOpen,
                        })}
                    >
                        <button
                            type="button"
                            className={styles.menuClose}
                            onClick={handleClose}
                        >
                            <img src={imageClose} alt={t("menu.close")} />
                        </button>
                        <ul className={styles.menuItems}>
                            {menuItems.map(
                                ({ title, onClick, show }: any) =>
                                    !!show && (
                                        <li key={title} onClick={onClick}>
                                            <button
                                                type="button"
                                                onClick={onClick}
                                            >
                                                {t(`${title}`)}
                                            </button>
                                        </li>
                                    )
                            )}
                        </ul>
                    </div>
                </div>

                <div className={styles.downloadContainer}>
                    <div>
                        <button
                            type="button"
                            onClick={handleAppDownload}
                            title={t("genric.download_on_apple")}
                            className={styles.appstoreButton}
                        >
                            <img src={imageApple} alt={t("generic.apple")} />
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={handleAppDownload}
                            title={t("genric.download_on_apple")}
                            className={styles.appstoreButton}
                        >
                            <img
                                src={imageAndroid}
                                alt={t("generic.android")}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppBar;
