import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Group } from "types/Group";
import Card from "components/Card/Card";

import Avatar from "components/Avatar";
import styles from "./GroupCard.module.scss";

export interface Props {
    className?: string;
    group: Group;
}

function GroupSummaryCard({ className, group }: Props): JSX.Element {
    const { t } = useTranslation("ComponentGroupCard");

    // TODO: These images are static sizes on the server. We should probably make them dynamic like UGC.
    const groupImageUrl = useMemo(
        () => group.bannerUrl?.replace("{width}", "40"),
        [group.bannerUrl]
    );
    if (!group) {
        return <div>Loading...</div>;
    }

    return (
        <Card
            className={classNames(styles.GroupCard, className)}
            href={`/groups/${group.uid}`}
            // @ts-ignore
            style={{ "--background-image": `url(${groupImageUrl})` }}
        >
            <div>
                <h4>{group.name}</h4>
            </div>
            <footer>
                <div className={styles.avatar}>
                    {group.author && (
                        <Avatar
                            avatarUrl={group.author?.avatarUrl!}
                            firstName={group.author?.name!}
                            baseImageSize={28}
                        />
                    )}
                </div>
                <div className={styles.author}>
                    {group.author && (
                        <span>
                            {t("by {{author}}", { author: group.author?.name })}
                        </span>
                    )}
                </div>
                {!!group.counts ?? (
                    <div className={styles.userCount}>{group.counts.users}</div>
                )}
            </footer>
        </Card>
    );
}

export default GroupSummaryCard;
