import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useEffect, useRef, useState } from "react";

export function useInfiniteScroll(onLoadNext: () => void) {
  const refLoader = useRef<HTMLDivElement | null>(null);
  const loaderElement = useIntersectionObserver(refLoader, {});
  const [hasLoadedMore, setHasLoadedMore] = useState<boolean>(false);

  const handleLoadNext = () => {
    if (!hasLoadedMore) return;
    onLoadNext();
    setHasLoadedMore(true);
  };

  useEffect(() => {
    if (loaderElement?.isIntersecting) {
      handleLoadNext();
    }
  }, [loaderElement?.isIntersecting]);

  return refLoader;
}

export default useInfiniteScroll;
