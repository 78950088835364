import React from "react";
import { Route } from "react-router";
import SuspenseWrapper from "SuspenseWrapper";

const FeedLayout = React.lazy(() => import("layouts/FeedLayout"));

const FeedPage = React.lazy(() => import("pages/FeedPage"));
const ComingSoonPage = React.lazy(() => import("pages/ComingSoonPage"));

const GroupRoutes = [
    <Route element={<FeedLayout />}>
        <Route
            path="/feed/explore"
            element={
                <SuspenseWrapper>
                    <ComingSoonPage />
                </SuspenseWrapper>
            }
        />
        <Route
            path="/feed"
            element={
                <SuspenseWrapper>
                    {/* <FeedPage /> --- this needeeng fix */}
                    <FeedPage />
                    {/* <ComingSoonPage /> */}
                </SuspenseWrapper>
            }
        />
    </Route>,
];

export default GroupRoutes;
