/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { stripePublicApi} from "api/stripe";
import { PURGE } from "redux-persist";
import type { RootState } from "store/Store";
import { StripeState } from "./types";


export const initialState: StripeState = {
  data: undefined,
  isLoading: undefined,
  error: undefined
};

export const StripePublicSlice = createSlice({
  name: "stripePublic",
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      stripePublicApi.endpoints.stripePub.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;
        state.isLoading = false;
        state.error = undefined;
      }
    );

    builder.addMatcher(
      stripePublicApi.endpoints.stripePub.matchRejected,
      (state, { payload }) => {
        state.data = undefined;
        state.isLoading = false;
        state.error = payload;
      }
    );

    builder.addMatcher(
      stripePublicApi.endpoints.stripePub.matchPending,
      (state, { payload }) => {
        state.data = undefined;
        state.isLoading = true;
        state.error = undefined;
      }
    );

    builder.addMatcher(
      (action) => action.type === PURGE,
      () => initialState
    );
  }
});

export const selectStripePublic = ({ stripe }: RootState): StripeState => {
  return stripe;
};
export const { resetState } = StripePublicSlice.actions;
export const { reducer } = StripePublicSlice;
