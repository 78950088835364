import { z } from "zod";

import { Group } from "types/Group";
import slugify from "helpers/slugify";
import { SchemaAuthor } from "./Author";

const SchemaGroupExperience = z
  .object({
    code: z.string(),
    name: z.string(),
    category: z.string()
  })
  .transform((data) => ({
    code: data.code,
    name: data.name,
    category: data.category
  }));

const SchemaGroupTag = z
  .object({
    tag_id: z.number(),
    val: z.string(),
    icon_url: z.string().url(),
    icon_color: z.string(),
    group_code: z.string(),
    group_icon_color: z.string()
  })
  .transform((data) => ({
    id: data.tag_id,
    name: data.val,
    iconUrl: data.icon_url,
    iconColor: data.icon_color,
    groupCode: data.group_code,
    groupIconColor: data.group_icon_color
  }));

export const SchemaGroup = z
  .object({
    id: z.number(),
    name: z.string(),
    uid: z.string(),
    description: z.string(),
    author: SchemaAuthor.optional(),
    join_mode: z.string(),
    local: z.boolean(),
    group_type: z.string(),
    banner_url: z.string(),
    user_count: z.number(),
    post_count: z.number(),
    can_share: z.boolean(),
    unread_post_count: z.number(),
    active_at: z.string().optional(),
    tags: z.array(SchemaGroupTag),
    experiences: z.array(SchemaGroupExperience),
    locked: z.boolean(),
    banner: z.object({
      url: z.string()
    })
  })
  .transform(
    (data): Group => ({
      id: data.id,
      name: data.name,
      uid: data.uid,
      slug: slugify(data.name),
      description: data.description,
      bannerUrl: data.banner_url,
      author: data.author,
      joinMode: data.join_mode,
      local: data.local,
      groupType: data.group_type as any,
      activeAt: data.active_at,
      tags: data.tags,
      experiences: data.experiences,
      locked: data.locked,
      counts: {
        users: data.user_count,
        posts: data.post_count,
        unread: data.unread_post_count
      },
      permissions: {
        canShare: data.can_share
      }
    })
  );

export default SchemaGroup;
