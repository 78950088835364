interface Selected {
    id?: string;
    productId?: string;
    offers: {
      formattedPrice: string;
      discountedPrice: number;
      code?: string;
    }[];
    price: number;
  }
  
  function genSubscriptionGA(selected: Selected, currency: string): any {
    const analytics = {
      currency,
      value: selected.offers.length > 0 && selected.offers[0]?.formattedPrice ? selected.offers[0].discountedPrice : selected.price,
      items: [
        {
          id: selected.id || "",
          name: selected.productId || "",
          price: selected.offers.length > 0 ? selected?.offers[0]?.discountedPrice : selected.price,
          ...(selected.offers.length > 0
            ? {
                coupon: selected.offers[0]?.code || "",
                discount: selected.price - (selected.offers[0]?.discountedPrice ?? 0),
              }
            : {})
        }
      ]
    };
    return analytics;
  }

  export default genSubscriptionGA;

  