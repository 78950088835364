import React from "react";
import {
    Routes,
    Route,
    useNavigate,
    Navigate,
    redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";

import "unfonts.css";

///NEW DESIGN SYSTEM
import "DesignSystem/common/colors.scss";
import "DesignSystem/common/typography.scss";
import "DesignSystem/common/fontSizes.scss";

import "stylesheets/basics.css";
import "stylesheets/colors.scss";
import "stylesheets/fonts.css";
import "stylesheets/forms.scss";
import "stylesheets/typography.scss";
import "stylesheets/variables.scss";

import "react-loading-skeleton/dist/skeleton.css";

import SuspenseWrapper from "SuspenseWrapper";
import DevTools from "components/DevTools";
import Error403 from "pages/Errors/Error403";
import Error503 from "pages/Errors/Error503";
import AppLayout from "layouts/AppLayout";
import PodPage from "pages/PodPage";
import PodsPage from "pages/PodsPage";

import DevToolsPage from "pages/DevToolsPage";
import RedirectPage from "pages/RedirectPage/RedirectPage";

import { UserEventSource } from "types/UserEvent";
import useUserEventDispatch from "hooks/useUserEventDispatch";

import FeedRoutes from "routes/FeedRoutes";
import GroupRoutes from "routes/GroupRoutes";
import SuccessPage from "@/pages/Success";
import ErrorTemplate from "@/pages/Errors/ErrorTemplate";

const SubscriptionPage = React.lazy(() => import("pages/Subscription"));

const ComingSoonPage = React.lazy(() => import("pages/ComingSoonPage"));

const DefaultLayout = React.lazy(() => import("layouts/DefaultLayout"));
const RequireAuth = React.lazy(() => import("components/RequireAuth"));
const RegistrationLayout = React.lazy(
    () => import("layouts/RegistrationLayout")
);

const HomePage = React.lazy(() => import("pages/HomePage"));

const DownloadPage = React.lazy(() => import("pages/DownloadPage"));
const DownloadPublicPage = React.lazy(() => import("pages/DownloadPublicPage"));
const AccountWarning = React.lazy(() => import("layouts/AccountWarning"));
const LoginLayout = React.lazy(() => import("pages/LoginLayout"));

const LoginPhone = React.lazy(() => import("pages/Login/Phone"));

const PostPage = React.lazy(() => import("pages/PostPage"));
const RegistrationComplete = React.lazy(
    () => import("pages/Registration/Complete")
);
const RegistrationEmail = React.lazy(() => import("pages/Registration/Email"));
const RegistrationProfile = React.lazy(
    () => import("pages/Registration/Profile")
);

const RegistrationDownload = React.lazy(
    () => import("pages/Registration/DownloadPage")
);

const RegistrationProfilePicture = React.lazy(
    () => import("pages/Registration/ProfilePicture")
);
const RegistrationProfilePictureCamera = React.lazy(
    () => import("pages/Registration/ProfilePictureCamera")
);
const RegistrationProfilePicturePreview = React.lazy(
    () => import("pages/Registration/ProfilePicturePreview")
);
const RegistrationPrivacy = React.lazy(
    () => import("pages/Registration/Privacy")
);

const RegistrationPeanutPlus = React.lazy(
    () => import("pages/Registration/PeanutPlusPage")
);

const RegistrationPeanutPlusConfirmation = React.lazy(
    () => import("pages/Registration/PeanutPlusConfirmationPage")
);

const RegistrationLifestagePage = React.lazy(
    () => import("pages/Registration/LifestagePage")
);
const RegistrationLifestageTTCPage = React.lazy(
    () => import("pages/Registration/LifestageTTCPage")
);
const RegistrationLifestagePregnancy = React.lazy(
    () => import("pages/Registration/LifestagePregnancy")
);
const RegistrationLifestageMotherhood = React.lazy(
    () => import("pages/Registration/LifestageMotherhood")
);
const RegistrationLifestageMenopause = React.lazy(
    () => import("pages/Registration/LifestageMenopause")
);
const RegistrationSelfieCheckIntroduction = React.lazy(
    () => import("pages/Registration/SelfieCheckIntroduction")
);
const RegistrationSelfieCheckCamera = React.lazy(
    () => import("pages/Registration/SelfieCheckCamera")
);

const RegistrationTags = React.lazy(() => import("pages/Registration/Tags"));

const RegistrationLifestageSummary = React.lazy(
    () => import("pages/Registration/LifestageSummary")
);

const OnboardingLayout = React.lazy(() => import("layouts/OnboardingLayout"));
const OnboardingPage = React.lazy(() => import("pages/OnboardingPage"));
const OnboardingSummaryPage = React.lazy(
    () => import("pages/OnboardingSummaryPage")
);

const PlusOfferPage = React.lazy(() => import("pages/PlusOfferPage"));

const LoginPage = React.lazy(() => import("pages/LoginPage"));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
    useUserEventDispatch();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{import.meta.env.VITE_DEFAULT_TITLE}</title>
            </Helmet>
            <SentryRoutes>
                {/* 👇️ only match this when no other routes match */}
                <Route
                    path="*"
                    element={<ErrorTemplate pageType="errorGeneric" />}
                />
                <Route
                    path="__dev"
                    element={
                        <SuspenseWrapper>
                            <DevToolsPage />
                        </SuspenseWrapper>
                    }
                />
                <Route
                    element={
                        <SuspenseWrapper>
                            <DefaultLayout />
                        </SuspenseWrapper>
                    }
                >
                    <Route
                        path="/download"
                        element={
                            <SuspenseWrapper>
                                <DownloadPublicPage />
                            </SuspenseWrapper>
                        }
                    />
                    <Route
                        element={
                            <SuspenseWrapper>
                                <AccountWarning />
                            </SuspenseWrapper>
                        }
                    >
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <LoginLayout />
                                </SuspenseWrapper>
                            }
                        >
                            <Route
                                path="/login"
                                element={
                                    <SuspenseWrapper>
                                        <LoginPage />
                                    </SuspenseWrapper>
                                }
                            />
                            <Route
                                path="/login/phone"
                                element={
                                    <SuspenseWrapper>
                                        <LoginPhone />
                                    </SuspenseWrapper>
                                }
                            />

                            <Route
                                element={
                                    <SuspenseWrapper>
                                        <RegistrationLayout />
                                    </SuspenseWrapper>
                                }
                            >
                                <Route
                                    path="/registration/complete"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationComplete />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/download"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationDownload />
                                        </SuspenseWrapper>
                                    }
                                />
                                <Route
                                    path="/registration/email"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationEmail />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/peanut-plus"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationPeanutPlus />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/peanut-plus/confirmation"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationPeanutPlusConfirmation />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/profile"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationProfile />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/profile-picture"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationProfilePicture />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/profile-picture/camera"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationProfilePictureCamera />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/profile-picture/preview"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationProfilePicturePreview />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/privacy"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationPrivacy />
                                        </SuspenseWrapper>
                                    }
                                />
                                <Route
                                    path="/registration/lifestage"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationLifestagePage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route path="/registration/lifestage/ttc">
                                    <Route
                                        index
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageTTCPage />
                                            </SuspenseWrapper>
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageTTCPage />
                                            </SuspenseWrapper>
                                        }
                                    />
                                </Route>

                                <Route path="/registration/lifestage/pregnancy">
                                    <Route
                                        index
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestagePregnancy />
                                            </SuspenseWrapper>
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestagePregnancy />
                                            </SuspenseWrapper>
                                        }
                                    />
                                </Route>

                                <Route path="/registration/lifestage/motherhood">
                                    <Route
                                        index
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageMotherhood />
                                            </SuspenseWrapper>
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageMotherhood />
                                            </SuspenseWrapper>
                                        }
                                    />
                                </Route>

                                <Route path="/registration/lifestage/menopause">
                                    <Route
                                        index
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageMenopause />
                                            </SuspenseWrapper>
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            <SuspenseWrapper>
                                                <RegistrationLifestageMenopause />
                                            </SuspenseWrapper>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="/registration/lifestage/summary"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationLifestageSummary />
                                        </SuspenseWrapper>
                                    }
                                />
                                <Route
                                    path="/registration/selfie/camera"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationSelfieCheckCamera />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/selfie"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationSelfieCheckIntroduction />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/registration/tags"
                                    element={
                                        <SuspenseWrapper>
                                            <RegistrationTags />
                                        </SuspenseWrapper>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            element={
                                <SuspenseWrapper>
                                    <RequireAuth />
                                </SuspenseWrapper>
                            }
                        >
                            <Route
                                element={
                                    <SuspenseWrapper>
                                        <AppLayout />
                                    </SuspenseWrapper>
                                }
                            >
                                {...FeedRoutes}
                                {...GroupRoutes}
                                <Route
                                    path="/"
                                    element={
                                        <SuspenseWrapper>
                                            <HomePage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/discovery"
                                    element={
                                        <SuspenseWrapper>
                                            <ComingSoonPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/chat"
                                    element={
                                        <SuspenseWrapper>
                                            <ComingSoonPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/feed"
                                    element={
                                        <SuspenseWrapper>
                                            <ComingSoonPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/notifications"
                                    element={
                                        <SuspenseWrapper>
                                            <ComingSoonPage />
                                        </SuspenseWrapper>
                                    }
                                />
                                <Route
                                    path="/posts"
                                    element={
                                        <SuspenseWrapper>
                                            <ComingSoonPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/download"
                                    element={
                                        <SuspenseWrapper>
                                            <DownloadPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/pods/:id"
                                    element={
                                        <SuspenseWrapper>
                                            <PodPage />
                                        </SuspenseWrapper>
                                    }
                                />
                                <Route
                                    path="/pods"
                                    element={
                                        <SuspenseWrapper>
                                            <PodsPage />
                                        </SuspenseWrapper>
                                    }
                                />

                                <Route
                                    path="/posts/:uid"
                                    element={
                                        <SuspenseWrapper>
                                            <PostPage
                                                source={UserEventSource.Feed}
                                            />
                                        </SuspenseWrapper>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            element={
                                <SuspenseWrapper>
                                    <OnboardingLayout />
                                </SuspenseWrapper>
                            }
                        >
                            <Route
                                path="/onboarding"
                                element={
                                    <SuspenseWrapper>
                                        <OnboardingPage />
                                    </SuspenseWrapper>
                                }
                            />
                        </Route>
                        <Route
                            path="/onboarding/summary"
                            element={
                                <SuspenseWrapper>
                                    <OnboardingSummaryPage />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/plus-offer"
                            element={
                                <SuspenseWrapper>
                                    <PlusOfferPage />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/payments/cancel"
                            element={
                                <RedirectPage to="/registration/peanut-plus/" />
                            }
                        />

                        <Route
                            path="/payments/success"
                            element={
                                <RedirectPage to="/registration/peanut-plus/confirmation" />
                            }
                        />
                        <Route
                            path="/subscription"
                            element={<Navigate to="/" />}
                        />
                        <Route
                            path="/subscription/:id"
                            element={
                                <SuspenseWrapper>
                                    <SubscriptionPage />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/subscription/success"
                            element={
                                <SuspenseWrapper>
                                    <SuccessPage
                                        eventTag="purchase"
                                        translation="SuccessPeanutPlus"
                                    />
                                </SuspenseWrapper>
                            }
                        />

                        {/* ///we should be able to handle errors without declaring a new path */}
                        <Route
                            path="/subscription/404"
                            element={
                                <SuspenseWrapper>
                                    <ErrorTemplate
                                        subscriptionError
                                        pageType="errorCheckout"
                                    />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/subscription/401"
                            element={
                                <SuspenseWrapper>
                                    <ErrorTemplate
                                        pageType="errorGeneric"
                                        hrefRedirect={
                                            import.meta.env.VITE_WEBSITE_URL
                                        }
                                    />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/subscription/409"
                            element={
                                <SuspenseWrapper>
                                    <ErrorTemplate
                                        pageType="errorSubscribed"
                                        hrefRedirect={
                                            import.meta.env
                                                .VITE_WEBSITE_DOWNLOAD_URL
                                        }
                                    />
                                </SuspenseWrapper>
                            }
                        />

                        <Route
                            path="/403"
                            element={
                                <SuspenseWrapper>
                                    <Error403 />
                                </SuspenseWrapper>
                            }
                        />
                        <Route
                            path="/503"
                            element={
                                <SuspenseWrapper>
                                    <Error503 />
                                </SuspenseWrapper>
                            }
                        />
                    </Route>
                </Route>
            </SentryRoutes>
            {import.meta.env.VITE_ENVIRONMENT !== "production" && <DevTools />}
        </>
    );
}

export default App;
