import { IconProps } from "../../types";

const CopyIcon = ({ size, fill = "#1C1C1E", strokeColor }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.2227 5.32031L17.4297 3.52734C17.1133 3.21094 16.6914 3 16.2344 3H10.1875C9.23828 3 8.5 3.77344 8.5 4.6875V6.375H5.6875C4.73828 6.375 4 7.14844 4 8.0625V19.3125C4 20.2617 4.73828 21 5.6875 21H13.5625C14.4766 21 15.25 20.2617 15.25 19.3125V17.625H18.0625C18.9766 17.625 19.75 16.8867 19.75 15.9375V6.51562C19.75 6.05859 19.5391 5.63672 19.2227 5.32031ZM13.3516 19.3125H5.89844C5.75781 19.3125 5.6875 19.2422 5.6875 19.1016V8.27344C5.6875 8.16797 5.75781 8.0625 5.89844 8.0625H8.5V15.9375C8.5 16.8867 9.23828 17.625 10.1875 17.625H13.5625V19.1016C13.5625 19.2422 13.457 19.3125 13.3516 19.3125ZM17.8516 15.9375H10.3984C10.2578 15.9375 10.1875 15.8672 10.1875 15.7266V4.89844C10.1875 4.79297 10.2578 4.6875 10.3984 4.6875H14.125V7.78125C14.125 8.27344 14.4766 8.625 14.9688 8.625H18.0625V15.7266C18.0625 15.8672 17.957 15.9375 17.8516 15.9375ZM18.0625 6.9375H15.8125V4.6875H16.1289C16.1992 4.6875 16.2344 4.72266 16.2695 4.75781L17.9922 6.48047C18.0273 6.51562 18.0625 6.55078 18.0625 6.62109V6.9375Z"
            fill={strokeColor}
        />
    </svg>
);

export default CopyIcon;
