import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import useReadMore from "hooks/useReadMore";
import { useTranslation } from "react-i18next";

import styles from "./ExpandableParagraph.module.scss";

export interface Props {
  numberOfLines: number;
  children: React.ReactNode;
  className?: string;
  showReadLess?: boolean;
}

function ExpandableParagraph({
  children,
  className,
  numberOfLines,
  showReadLess = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const ref = useRef(null);
  const showReadMore = useReadMore(ref);

  const handleReadMore = useCallback(() => {
    setShowAll(true);
    setHasExpanded(true);
  }, [setShowAll]);

  const handleReadLess = useCallback(() => {
    setShowAll(false);
  }, [setShowAll]);

  return (
    <>
      <p
        className={classNames(styles.p, className)}
        ref={ref}
        style={
          showAll
            ? {
                WebkitLineClamp: 99,
              }
            : {
                WebkitLineClamp: numberOfLines,
              }
        }
      >
        {children}
      </p>
      <button
        type="button"
        onClick={handleReadMore}
        className={classNames(styles.button, {
          [styles.hidden]: !showReadMore,
        })}
      >
        {t("readMore", { ns: "Generic" })}
      </button>
      {showReadLess && hasExpanded && (
        <button
          type="button"
          onClick={handleReadLess}
          className={classNames(styles.button, {
            [styles.hidden]: showReadMore,
          })}
        >
          {t("readLess", { ns: "Generic" })}
        </button>
      )}
    </>
  );
}

export default ExpandableParagraph;
