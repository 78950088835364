import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Avatar from "components/Avatar";
import type { UserReaction } from "types/UserReaction";
import Skeleton from "react-loading-skeleton";
import styles from "./UserReactionListItem.module.scss";

export interface Props {
  className?: string;
}

function UserReactionListItemSkeleton({ className }: Props): JSX.Element {
  return (
    <li className={classNames(styles.UserReactionListItem, className)}>
      <div className={styles.label}>
        <Skeleton />
      </div>
      <div className={styles.avatar}>
        <Skeleton className={styles.avatar} circle />
      </div>
      <div className={styles.meta}>
        <div>
          <Skeleton />
        </div>

        <div>
          <Skeleton />
        </div>
      </div>
      <div className={styles.actions}>
        <Skeleton className={styles.skeletonButton} />
      </div>
    </li>
  );
}

export default UserReactionListItemSkeleton;
