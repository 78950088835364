/* eslint-disable no-param-reassign */
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/Store";

interface ShowReactionsParams {
  postUid: string;
  postTitle: string;
  reactionCount: number;
}
export interface SecondaryPanelAction {
  action: "PostReactions" | "MyGroups" | "None";
  params: ShowReactionsParams | any;
}

export interface LayoutState {
  secondaryPanelOpen: boolean;
  secondaryPanelAction?: SecondaryPanelAction;
}

export const actionActAs = createAction<string>("session/actAs");

export const initialState: LayoutState = {
  secondaryPanelOpen: false,
  secondaryPanelAction: { action: "MyGroups", params: {} }
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSidebarOpen: (state) => {
      state.secondaryPanelOpen = true;
    },

    setSidebarClosed: (state) => {
      state.secondaryPanelOpen = false;
    },

    setSecondaryPanelAction: (
      state,
      { payload }: PayloadAction<SecondaryPanelAction>
    ) => {
      state.secondaryPanelAction = payload;
    }
  }
});

export const selectSecondaryPanel = ({
  layout: { secondaryPanelOpen, secondaryPanelAction }
}: RootState) => ({
  secondaryPanelOpen,
  secondaryPanelAction
});

export const { setSidebarClosed, setSidebarOpen, setSecondaryPanelAction } =
  LayoutSlice.actions;

export const { reducer } = LayoutSlice;
