import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Skeleton from "react-loading-skeleton";
import type { Pods } from "types/Pods";
import imageMicrophone from "./assets/microphone.svg";
import imageMicrophoneMuted from "./assets/microphoneMuted.svg";
import imageProBadge from "./assets/pro.svg";

import styles from "./Participant.module.scss";

import imageDefaultAvatar from "./assets/defaultAvatar.webp";

export interface Props {
  className?: string;
  muted?: boolean;
  participant: Pods.Participant;
  showProBadge?: boolean;
  showRole?: boolean;
  volume?: number;
}

function Participant({
  className,
  muted = false,
  participant: { avatarUrl, firstName, status },
  showProBadge,
  showRole,
  volume = 0
}: Props): JSX.Element {
  const { t } = useTranslation("PodsParticipant");

  const processedAvatarUrl = useMemo(
    () =>
      avatarUrl
        ? avatarUrl?.replace("{width}x{height}", "60x60")
        : imageDefaultAvatar,
    [avatarUrl]
  );
  const speaking = useMemo(() => volume > 0, [volume]);
  const speaker = useMemo(
    () => status && ["host", "cohost", "speaker"].includes(status),
    [status]
  );
  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.speaking]: speaking },
        className
      )}
    >
      <div className={styles.avatar}>
        <div
          className={styles.volumeIndicator}
          style={{ opacity: volume > 0 ? volume / 100 : 0 }}
        />
        <img src={processedAvatarUrl} alt={firstName} loading="lazy" />

        {speaker && (
          <div
            className={classNames(
              styles.speaker,
              !muted ? styles.speakerUnmuted : styles.speakerMuted
            )}
          >
            {!muted ? (
              <img src={imageMicrophone} alt="Unmuted" />
            ) : (
              <img src={imageMicrophoneMuted} alt="Muted" />
            )}
          </div>
        )}
      </div>

      <div>
        <span>
          {firstName}{" "}
          {showProBadge && <img src={imageProBadge} alt={t("generic.pro")} />}
        </span>

        {(speaker || showRole) && <span>{t(`status.${status}`)}</span>}
      </div>
    </div>
  );
}

Participant.Mock = function mock({
  className,
  showStatus = false
}: {
  className?: string;
  showStatus?: boolean;
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.avatar}>
        <Skeleton circle width={60} height={60} />
      </div>

      <div>
        <span>
          <Skeleton />
        </span>

        {showStatus && (
          <span>
            <Skeleton />
          </span>
        )}
      </div>
    </div>
  );
};

export default Participant;
