import React, { useCallback, useState } from "react";
import { Props as ButtonProps } from "components/Buttons/Button";
import DownloadOverlay from "components/DownloadOverlay";

export interface Props {
  children?: React.ReactNode;
  component: React.FC<ButtonProps>;
  className?: string;
}

function DownloadActionWrapper({
  children,
  className,
  component: Component,
}: Props): JSX.Element {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<DOMRect>();

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!visible) {
        setPosition(event.currentTarget.getBoundingClientRect());
      }
      setVisible(!visible);
    },
    [setVisible, visible]
  );

  return (
    <>
      <div>
        <Component className={className} onClick={handleClick}>
          {children}
        </Component>
      </div>
      <DownloadOverlay
        onClose={handleClose}
        visible={visible}
        position={position}
      />
    </>
  );
}

export default DownloadActionWrapper;
