/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { api as apiMeSubscriptions } from "api/me/subscriptions";

import { PURGE } from "redux-persist";

import type { RootState } from "store/Store";
import { SubscriptionState, SubscriptionGAParams } from "./types";




export const initialState: SubscriptionState = {
  selectedTier: undefined,
  currency: undefined,
  subscriptionId: undefined,
};

export const SessionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiMeSubscriptions.endpoints.getPublicSubscriptionTiers.matchFulfilled,
      (state, { payload }) => {
        const offerTier = payload.tiers.find(
          (tier) => tier.offers.length > 0 && tier.interval === "month"
        );
        state.selectedTier = offerTier;
        state.currency = payload.currency;
        state.subscriptionId = payload.subscriptionId;
      }
    );

    builder.addMatcher(
      (action) => action.type === PURGE,
      () => initialState
    );
  }
});



// export const {
//   setSession,
//   setMethod,
//   setLoginDestination,
//   setLoginDestinationFromSource,
//   logout
// } = SessionSlice.actions;



// Temporary single offer for MVP. Will need reworking.
export const selectSubscriptionOffer = ({
  subscription: { subscriptionId, currency, selectedTier }
}: RootState):
  | {
    analytics: SubscriptionGAParams;
    fullPriceFormatted: string;
    discountedPriceFormatted: string;
    interval: string;
    intervalCount: number;
    subscriptionId: string;
    offerCode: string;
    tierId: string;
  }
  | undefined => {
  if (!selectedTier) {
    return undefined;
  }

  const tier = selectedTier;
  const offer = tier.offers[0];

  return {
    analytics: {
      currency: currency!,
      value: offer?.discountedPrice,
      items: [
        {
          id: "conversion_onboarding_first_month_discounted",
          name: "Peanut Plus - First month discounted",
          coupon: offer.code,
          discount: tier.price - offer.discountedPrice,
          price: offer.discountedPrice
        }
      ]
    },
    fullPriceFormatted: tier.formattedPrice,
    discountedPriceFormatted: offer.formattedPrice,
    interval: tier.interval,
    intervalCount: tier.intervalCount,
    subscriptionId: subscriptionId!,
    offerCode: offer.code,
    tierId: tier.id
  };
};


export const { reducer } = SessionSlice;
