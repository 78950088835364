import { TextProps } from "./types";

import styles from "./index.module.scss";
import classNames from "classnames";

const Text = ({
    children,
    variant = "body",
    underline,
    bold,
    use = "span",
    ...props
}: TextProps) => {
    //TO BE MOVED
    const typographyVariant = {
        [styles.header1]: variant === "h1",
        [styles.header2]: variant === "h2",
        [styles.header3]: variant === "h3",
        [styles.body]: variant === "body",
    };

    const styleVariant = {
        [styles.underline]: underline,
        [styles.bold]: bold,
    };

    const Component =
        variant === "h1"
            ? "h1"
            : variant === "h2"
            ? "h2"
            : variant === "h3"
            ? "h3"
            : use === "p"
            ? "p"
            : "span";

    return (
        <Component
            className={classNames(
                styles.default,
                typographyVariant,
                styleVariant,
                {
                    classNames,
                }
            )}
            {...props}
        >
            {children}
        </Component>
    );
};

export default Text;
