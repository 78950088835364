import { RegistrationStep } from "@/api/me/registration/types";

const REGISTRATION_STEP_MAP: { [key in RegistrationStep]: string | undefined } =
    {
        peanut_plus: "/registration/peanut-plus",
        privacy_policy: "/registration/privacy",
        email: "/registration/email",
        name_age: "/registration/profile",
        photo: "/registration/profile-picture",
        children: "/registration/lifestage",
        packs: "/registration/tags",
        gender_detection: "/registration/selfie",
        device_location: undefined,
        device_notification: undefined,
        confirmation: "/registration/lifestage/summary",
        confirmation_phase_two: "/registration/complete",
        onboarding_complete: "/registration/complete",
        onboarding_complete_phase_two: "/registration/complete",
        banned: "/",
        download_mobile_app: "/registration/download",
    };

export default REGISTRATION_STEP_MAP;
