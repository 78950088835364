import React from "react";
import { Route } from "react-router";
import { UserEventSource } from "types/UserEvent";

import SuspenseWrapper from "SuspenseWrapper";

const GroupLayout = React.lazy(() => import("layouts/GroupLayout"));

const PostPage = React.lazy(() => import("pages/PostPage"));
const ComingSoonPage = React.lazy(() => import("pages/ComingSoonPage"));

const GroupRoutes = [
  <Route path="/groups/:groupUid/:groupSlug" element={<GroupLayout />}>
    <Route index element={<ComingSoonPage />} />
    <Route
      path="posts/:uid"
      element={
        <SuspenseWrapper>
          <PostPage source={UserEventSource.PagesGroup} />
        </SuspenseWrapper>
      }
    />
  </Route>
];

export default GroupRoutes;
