import { IconProps } from "../../types";

const AlertIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8571 17.1429H5.14285V16.2627C5.14285 15.3717 5.54973 14.5674 6.1618 13.92V13.92C6.83587 13.207 7.2 12.2232 7.2 10.4674C7.2 7.19433 9.43003 5.14285 12 5.14285C14.5654 5.14285 16.8 7.20253 16.8 10.4674C16.8 12.217 17.1646 13.2021 17.8391 13.9184V13.9184C18.4503 14.5674 18.8571 15.3716 18.8571 16.2631V17.1429Z"
            stroke={strokeColor}
            strokeWidth="1.54521"
        />
        <path
            d="M10.2857 17.1429V18V18C10.2857 18.9468 11.0532 19.7143 12 19.7143H12.1905C13.1373 19.7143 13.9048 18.9468 13.9048 18V18V17.1429"
            stroke={strokeColor}
            strokeWidth="1.54521"
        />
    </svg>
);

export default AlertIcon;
