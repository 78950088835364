import React from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import PodsActiveCards from "containers/Pods/PodsActiveCards";
import PodsScheduledCards from "containers/Pods/PodsScheduledCards";
import styles from "./PodsPage.module.scss";

// i18next.addResourceBundle("en", "podsPage", require("./i18n/en.json"));

function PodsPage(): JSX.Element {
  const { t } = useTranslation("PagesPodsPage");

  return (
    <div className={styles.wrapper}>
      <main>
        <h1>{t("title")}</h1>
        <section>
          <header>
            <h2>{t("scheduled")}</h2>
          </header>
          <PodsScheduledCards />
        </section>
        <section>
          <header>
            <h2>{t("listenNow")}</h2>
          </header>
          <PodsActiveCards />
        </section>
      </main>
    </div>
  );
}

export default PodsPage;
