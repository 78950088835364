import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import QRCode from "react-qr-code";

import ActionButton from "components/Buttons/ActionButton";
import usePortal from "react-useportal";
import useOnClickOutside from "hooks/useOnClickOutside";
import useAppLink from "hooks/useAppLink";
import imageClose from "./assets/close.svg";
import imageStars from "./assets/stars.svg";

import styles from "./DownloadOverlay.module.scss";

export interface Props {
  onClose: () => void;
  visible?: boolean;
  position: DOMRect | undefined;
}

function DownloadOverlay({
  onClose,
  visible = false,
  position
}: Props): JSX.Element {
  const [url, setUrl] = useState<string>();
  const { t } = useTranslation("DownloadOverlay");

  const { Portal } = usePortal({
    bindTo: document && document.getElementById("downloadOverlay")!
  });
  const getAppLink = useAppLink();

  useEffect(() => {
    if (visible && url === undefined) {
      (async () => {
        setUrl(await getAppLink());
      })();
    }
  }, [visible]);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onClose();
    },
    [onClose]
  );

  const refButton = useRef<HTMLDivElement>(null);
  useOnClickOutside(refButton, handleClose);

  const handleDownloadClick = useCallback(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  if (!visible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <Portal>
      <div
        className={classNames(styles.container, { [styles.visible]: visible })}
        style={position && { top: position.top, left: position.left }}
        ref={refButton}
      >
        <div className={styles.wrapper}>
          <button
            type="button"
            onClick={handleClose}
            data-testid="downloadOverlay-close"
          >
            <img src={imageClose} alt={t("generic.close")} />
          </button>

          <div className={styles.content}>
            <span className={styles.smallContent}>
              {t("small.downloadPod")}
            </span>
            <span className={styles.mediumContent}>
              {t("medium.scanToDownload")}{" "}
              <span>{t("medium.participateInPod")}</span>
            </span>
            <div className={styles.actions}>
              <ActionButton
                onClick={handleDownloadClick}
                className={styles.downloadButton}
              >
                {t("download", { ns: "Generic" })}
              </ActionButton>
            </div>
          </div>

          <footer>
            <span>{t("rating.rated", { ns: "Generic" })} </span>
            <img
              src={imageStars}
              alt={`${t("rating.stars", { ns: "Generic" })} ${t(
                "generic.rating.by",
                { ns: "Generic" }
              )}`}
              className={styles.stars}
            />
            <span>{t("rating.by", { ns: "Generic" })}</span>
          </footer>

          {url && (
            /* @ts-ignore */
            <QRCode value={url} className={styles.downloadQRCode} size={80} />
          )}
        </div>
      </div>
    </Portal>
  );
}

export default DownloadOverlay;
