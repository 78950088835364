import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useGetMyGroupsSectionQuery } from "api/Pages/GroupsApi";
import GroupCard from "components/GroupCard";
import styles from "./SecondaryMyGroups.module.scss";

export interface Props {
    className?: string;
}

function SecondaryMyGroups({ className }: Props): JSX.Element {
    const { t } = useTranslation("ComponentsSecondaryMyGroups");
    const { data } = useGetMyGroupsSectionQuery({});
    if (!data) {
        return <div>Loading...</div>;
    }
    return (
        <div className={classNames(styles.SecondaryMyGroups, className)}>
            <header>
                <h3>{t("suggestedForYou")}</h3>
            </header>

            {data?.groups?.map((group) => (
                <GroupCard group={group} key={group.uid} />
            ))}
        </div>
    );
}

export default SecondaryMyGroups;
