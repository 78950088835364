import { IconProps } from "../../types";

const GroupsIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="4.9609"
            y="3.99475"
            width="6.54189"
            height="6.54189"
            rx="1.29116"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linejoin="round"
        />
        <rect
            x="4.9609"
            y="13.4397"
            width="6.54189"
            height="6.54189"
            rx="1.29116"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linejoin="round"
        />
        <rect
            x="14.4294"
            y="3.99475"
            width="6.54189"
            height="6.54189"
            rx="1.29116"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linejoin="round"
        />
        <rect
            x="14.4294"
            y="13.4397"
            width="6.54189"
            height="6.54189"
            rx="1.29116"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linejoin="round"
        />
    </svg>
);

export default GroupsIcon;
