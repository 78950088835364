import { IconProps } from "../../types";
const NavRightIcon = ({
    strokeColor = "#EA515A",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.24 11.52C18.24 11.3088 18.1476 11.1072 17.9814 10.944L13.215 6.00001C13.0487 5.83681 12.864 5.76001 12.67 5.76001C12.2636 5.76001 11.9495 6.07681 11.9495 6.50881C11.9495 6.71041 12.0142 6.91201 12.1527 7.04641L13.4275 8.41921L16.0508 10.8768L14.2588 10.7424H5.53897C5.10483 10.7424 4.8 11.0688 4.8 11.52C4.8 11.9712 5.10483 12.2976 5.53897 12.2976H14.2588L16.06 12.1632L13.4275 14.6208L12.1527 15.9936C12.0142 16.128 11.9495 16.3296 11.9495 16.5312C11.9495 16.9632 12.2636 17.28 12.67 17.28C12.864 17.28 13.0487 17.2032 13.215 17.04L17.9814 12.096C18.1476 11.9328 18.24 11.7312 18.24 11.52Z"
                fill={fill}
                stroke={strokeColor}
                strokeWidth="0.481884"
            />
        </svg>
    );
};

export default NavRightIcon;
