import React, { useEffect, useState } from "react";

import useTestingLogin from "hooks/useTestingLogin/useTestingLogin";
import { useNavigate } from "react-router";

export interface Props {
  className?: string;
}

function DevToolsPage(): JSX.Element {
  const { login } = useTestingLogin();
  const [auth, setAuth] = useState<string>();
  const [destination, setDestination] = useState<string>("/");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newAuth = params.get("auth");
    const newDestination = params.get("destination");
    if (newAuth) {
      setAuth(newAuth);
    }
    if (newDestination) {
      setDestination(decodeURI(newDestination));
    }
  }, [window.location.search]);
  // Override the login
  useEffect(() => {
    if (auth) {
      login(auth);
      navigate(destination);
    }
  }, [auth]);

  return <h1>Dev Tools: {auth}</h1>;
}

export default DevToolsPage;
