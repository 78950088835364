import { IconProps } from "../../types";

const BadgesIcon = ({
    strokeColor,
    size = 24,
    fill = "black",
    color,
}: IconProps) => (
    <div>
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.37402 16.9969L4.37204 16.9958C4.22102 16.9129 4.14007 16.7669 4.14007 16.6203V7.37989C4.14007 7.23321 4.22102 7.08728 4.37204 7.00431L4.37205 7.00432L4.37402 7.00322L12.7119 2.38152C12.7121 2.38137 12.7124 2.38123 12.7127 2.38108C12.8691 2.29551 13.0631 2.2955 13.2195 2.38107C13.2198 2.38122 13.2201 2.38137 13.2203 2.38152L21.5582 7.00322L21.5602 7.00431C21.7112 7.08728 21.7921 7.23321 21.7921 7.37989V16.6229C21.7921 16.7678 21.7104 16.9159 21.5602 16.9984L21.5583 16.9995L13.2203 21.6186C13.2201 21.6188 13.2198 21.6189 13.2195 21.6191C13.0631 21.7046 12.8691 21.7046 12.7127 21.6191C12.7124 21.6189 12.7121 21.6188 12.7119 21.6186L4.37402 16.9969Z"
                stroke={strokeColor}
                strokeWidth="1.20508"
            />
            <path
                d="M8.0809 10.1278C8.19683 9.75595 8.51416 9.51564 8.88937 9.51564H11.2854C11.3456 9.51564 11.3986 9.47552 11.4172 9.4158L12.1576 7.04076C12.2736 6.66887 12.5909 6.42859 12.9661 6.42859C13.3413 6.42859 13.6586 6.6689 13.7746 7.04076L14.515 9.4158C14.5336 9.47552 14.5866 9.51564 14.6468 9.51564H17.0428C17.418 9.51564 17.7354 9.75595 17.8513 10.1278C17.9673 10.4997 17.8461 10.8886 17.5425 11.1184L15.6041 12.5862C15.5554 12.6231 15.5351 12.6881 15.5537 12.7478L16.2941 15.1228C16.41 15.4946 16.2888 15.8836 15.9853 16.1133C15.6818 16.3432 15.2895 16.3432 14.986 16.1133L13.0475 14.6455C12.9988 14.6086 12.9333 14.6086 12.8846 14.6455L10.9461 16.1133C10.7944 16.2283 10.6204 16.2857 10.4465 16.2857C10.2725 16.2857 10.0986 16.2282 9.94682 16.1133C9.64328 15.8836 9.52209 15.4946 9.63798 15.1228L10.3783 12.7478C10.3969 12.6881 10.3767 12.6231 10.328 12.5862L8.38955 11.1184C8.08609 10.8885 7.96493 10.4997 8.0809 10.1278ZM8.80788 10.5185L10.7463 11.9863C11.0451 12.2126 11.1693 12.6107 11.0552 12.9769L10.3148 15.3519C10.2877 15.4388 10.3347 15.4904 10.3651 15.5134C10.3956 15.5365 10.4573 15.5671 10.5281 15.5134L12.4665 14.0456C12.6159 13.9325 12.7911 13.8759 12.9662 13.8759C13.1413 13.8759 13.3164 13.9325 13.4659 14.0456L15.4043 15.5134C15.4752 15.5671 15.5368 15.5365 15.5673 15.5134C15.5977 15.4904 15.6447 15.4387 15.6176 15.3519L14.8772 12.9769C14.7631 12.6107 14.8872 12.2127 15.186 11.9864L17.1245 10.5185C17.1954 10.4648 17.1864 10.3943 17.1748 10.357C17.1632 10.3197 17.1306 10.2572 17.043 10.2572H14.647C14.2775 10.2572 13.9526 10.0111 13.8385 9.64499L13.0981 7.26995C13.071 7.18308 13.0039 7.17011 12.9662 7.17011C12.9286 7.17011 12.8615 7.18308 12.8344 7.26995L12.094 9.64499C11.9798 10.0111 11.6549 10.2572 11.2855 10.2572H8.88952C8.80183 10.2572 8.76925 10.3197 8.75765 10.357C8.74588 10.3943 8.73695 10.4648 8.80788 10.5185Z"
                fill={fill}
                stroke={strokeColor}
                strokeWidth="0.34431"
            />
        </svg>
    </div>
);

export default BadgesIcon;
