import { IconProps } from "../../types";

const TikTokIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.7577 9.49755C16.9152 10.3279 18.3332 10.8165 19.8648 10.8165V7.8589C19.5749 7.85896 19.2858 7.82863 19.0023 7.76834V10.0964C17.4709 10.0964 16.053 9.60781 14.8952 8.7775V14.8131C14.8952 17.8324 12.4563 20.2799 9.44787 20.2799C8.32536 20.2799 7.28203 19.9393 6.41534 19.3552C7.40453 20.3703 8.78402 20.9999 10.3102 20.9999C13.3188 20.9999 15.7578 18.5525 15.7578 15.533V9.49755H15.7577ZM16.8217 6.51369C16.2302 5.86511 15.8418 5.02695 15.7577 4.10032V3.71991H14.9404C15.1461 4.89765 15.8479 5.90384 16.8217 6.51369ZM8.31816 17.0383C7.98765 16.6034 7.80905 16.0714 7.80985 15.5244C7.80985 14.1435 8.92541 13.0238 10.3017 13.0238C10.5582 13.0237 10.8131 13.0631 11.0576 13.141V10.1173C10.7719 10.078 10.4836 10.0613 10.1954 10.0674V12.4209C9.95083 12.3431 9.69575 12.3036 9.43919 12.3038C8.0629 12.3038 6.9474 13.4234 6.9474 14.8045C6.9474 15.781 7.505 16.6264 8.31816 17.0383Z"
            fill="#FF004F"
        />
        <path
            d="M14.8952 8.77743C16.053 9.60775 17.4709 10.0963 19.0023 10.0963V7.76828C18.1474 7.58555 17.3907 7.13724 16.8217 6.51369C15.8478 5.90378 15.1461 4.89759 14.9404 3.71991H12.7934V15.5329C12.7886 16.9101 11.6749 18.0252 10.3016 18.0252C9.4923 18.0252 8.77333 17.638 8.31799 17.0383C7.50488 16.6264 6.94729 15.7809 6.94729 14.8045C6.94729 13.4235 8.06279 12.3039 9.43908 12.3039C9.70277 12.3039 9.95693 12.3451 10.1953 12.421V10.0675C7.23978 10.1288 4.86279 12.5523 4.86279 15.533C4.86279 17.0209 5.45472 18.3697 6.41542 19.3553C7.28211 19.9393 8.32543 20.2799 9.44794 20.2799C12.4564 20.2799 14.8953 17.8324 14.8953 14.8131V8.77743H14.8952Z"
            fill="black"
        />
        <path
            d="M19.002 7.76835V7.13887C18.2312 7.14004 17.4755 6.9234 16.8215 6.5137C17.4004 7.1498 18.1628 7.5884 19.002 7.76835ZM14.9402 3.71999C14.9205 3.60743 14.9055 3.49414 14.895 3.38041V3H11.9306V14.8131C11.9259 16.1901 10.8123 17.3052 9.43885 17.3052C9.03562 17.3052 8.65491 17.2091 8.31776 17.0384C8.7731 17.6381 9.49207 18.0252 10.3014 18.0252C11.6746 18.0252 12.7884 16.9102 12.7932 15.533V3.71999H14.9402ZM10.1952 10.0676V9.39742C9.94754 9.36345 9.69781 9.3464 9.44777 9.34652C6.43906 9.34646 4.00012 11.794 4.00012 14.8131C4.00012 16.7059 4.95867 18.374 6.41525 19.3553C5.45455 18.3698 4.86263 17.0208 4.86263 15.533C4.86263 12.5524 7.23955 10.1288 10.1952 10.0676Z"
            fill="#00F2EA"
        />
    </svg>
);

export default TikTokIcon;
