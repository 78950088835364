import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import { UserEvent } from "types/UserEvent";
import * as Sentry from "@sentry/browser";

export const api = createApi({
    reducerPath: "api/UserEvent",
    baseQuery: dynamicBaseQuery,

    endpoints: (builder) => ({
        createUserEvent: builder.mutation<void, UserEvent | UserEvent[]>({
            query: (events) => ({
                url: `me/user_events`,
                method: "POST",
                body: {
                    timestamp: new Date().toISOString(),
                    events: Array.isArray(events) ? events : [events],
                },
            }),
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "UserEventsApi",
                    error,
                });

                return error;
            },
        }),
    }),
});

export const { useCreateUserEventMutation } = api;
