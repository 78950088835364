import { useTranslation } from "react-i18next";
import imageStar from "@/assets/stars/star.svg";
import imageStarHalf from "@/assets/stars/star_half.svg";
import styles from "./styles.module.scss";
import classNames from "classnames";

const RatingMobile = ({ rated = false, center = false }) => {
  const { t } = useTranslation("Rating");
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.rating, { [styles.center]: center })}>
        <p className={styles.subtitle}>{rated && t("ratingInfo.rated")}</p>
        {[...Array(4)].map((_, index) => (
          <img key={index} src={imageStar} alt={t("ratingInfo.rating")} />
        ))}
        <img src={imageStarHalf} alt={t("ratingInfo.rating")} />
      </div>
      <p className={classNames({ [styles.title]: center })}>
        {t("ratingInfo.rating")}
      </p>
    </div>
  );
};
export default RatingMobile;
