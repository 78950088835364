/*
    Uses an image manifest to generate a webpack friendly picture element for
    An example manifest is in manifest.js
*/

const BREAKPOINTS: { [key: string]: number } = {
  small: 640,
  medium: 768,
  large: 1024,
  xlarge: 1280,
  xxlarge: 1536,
};

const FORMATS = ["webp", "jpg", "png", "svg"];

type ManifestByResoloution = {
  [key: string]: string;
};

interface IProps {
  alt: string;
  className?: string;
  manifest: any;
  src: string;
}

function ImageNextGen({ alt, className, manifest, src }: IProps): JSX.Element {
  // Combines the different resolutions in to a srcSet
  // e.g.
  //  elva-fairy-320w.jpg,
  //    elva-fairy-480w.jpg 1.5x,
  //    elva-fairy-640w.jpg 2x
  const srcSet = (entries: ManifestByResoloution) =>
    Object.entries(entries)
      .map(([resolution, image]) => {
        if (image === undefined) return undefined;
        if (resolution === "1x") {
          return image;
        }
        return `${image} ${resolution}`;
      })
      .filter((x) => x)
      .join(", ");

  // Generates a media query for the breakpoint
  const mediaQuery = (breakpoint: string) => {
    if (breakpoint === "small") {
      return `(max-width:${BREAKPOINTS.medium}px)`;
    }

    return `(min-width:${BREAKPOINTS[breakpoint]}px)`;
  };

  return (
    <picture>
      {/* For each possible breakpoint */}
      {Object.keys(BREAKPOINTS)
        .map((breakpointKey) => {
          // Get the breakpoint for the image
          const breakpoint = manifest[breakpointKey];
          if (!breakpoint) return undefined;

          // For each format in the manifest...
          return FORMATS.map((formatKey) => {
            const format = breakpoint[formatKey];

            if (format === undefined) return undefined;

            return (
              <source
                key={`${breakpointKey}-${formatKey}`}
                media={mediaQuery(breakpointKey)}
                srcSet={srcSet(format)}
              />
            );
          }).filter((x) => x);
        })
        .filter((x) => x)}

      <img src={src} alt={alt} className={className} />
    </picture>
  );
}

export default ImageNextGen;
