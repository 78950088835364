import { useGetPodsQuery } from "api/pods";
import PodScheduledCard from "components/Cards/PodScheduledCard";
import React from "react";
import styles from "./PodsScheduledCards.module.scss";

function PodsScheduledCards(): JSX.Element {
  const { data } = useGetPodsQuery(
    {
      status: "scheduled",
      limit: 2,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    }
  );

  return (
    <div className={styles.wrapper}>
      {data &&
        data.map((room) => <PodScheduledCard key={room.id} room={room} />)}
    </div>
  );
}

export default PodsScheduledCards;
