export enum UserEventSource {
  Alerts = "alerts",
  Chat = "chat",
  Comment = "comment",
  Discovery = "discovery",
  DiscoveryAlternative = "discovery/alternative",
  DiscoverySettings = "discovery/settings",
  DiscoveryWaves = "discovery/waves",
  Feed = "feed",
  Foregrounded = "foregrounded",
  Groups = "groups",
  Home = "home",
  Icebreakers = "icebreakers",
  LiveAudio = "live_audio",
  Me = "me",
  MeSettings = "me/settings",
  NeighbourhoodChat = "neighbourhood_chat",
  Notification = "notification",
  Onboarding = "onboarding",
  Other = "other",
  Pages = "pages",
  PagesCategory = "pages/category",
  PagesDiscover = "pages/discover",
  PagesFeed = "pages/feed",
  PagesGroup = "pages/group",
  PagesGroups = "pages/groups",
  PagesMyFeed = "pages/my_feed",
  PagesNew = "pages/new",
  PagesSearch = "pages/search",
  PagesSettings = "pages/settings",
  PagesSimilar = "pages/similar",
  PagesTag = "pages/tag",
  PagesTrending = "pages/trending",
  PagesTrendingAlt = "pages/trending_alt",
  PeanutDiscovery = "peanut_discovery",
  PeanutDiscoveryAlternative = "peanut_discovery/alternative",
  PeanutDiscoveryOnline = "peanut_discovery/online",
  PeanutInvite = "peanut_invite",
  PeanutMessage = "peanut_message",
  PeanutMessageResponse = "peanut_message_response",
  PeanutPages = "peanut_pages",
  Pods = "pods",
  PodsLive = "pods/live",
  PodsScheduled = "pods/scheduled",
  Post = "post",
  Profile = "profile",
  ProfileAnswer = "profile/answer",
  ProfilePhoto = "profile/photo",
  Push = "push",
  Share = "share",
  SystemGenerated = "system_generated",
  PeanutPlusUpsell = "peanut_plus_upsell",
  WhoWavedAtMeUpsell = "who_waved_at_me_upsell",
  PremiumPostUpsell = "premium_post_upsell",
  TipUpsell = "tip_upsell",
  PostBoostUpsell = "post_boost_upsell",
  DiscoveryPrivateModeUpsell = "discovery_private_mode_upsell",
  IncognitoPostsUpsell = "incognito_posts_upsell",
  AdvancedFiltersUpsell = "advanced_filters_upsell",
  WonderWaveUpsell = "wonder_wave_upsell",
  AdvancedFilters = "advanced_filters"
}

export enum OnboardingAction {
  Start = "start",
  Complete = "complete"
}

export enum OnboardingEditSubject {
  FirstName = "first_name",
  Birthday = "birthday",
  Email = "email",
  Description = "description",
  Tags = "tags"
}

export enum OnboardingEditAction {
  Edit = "edit",
  SetPrefill = "set_prefill",
  SetDefault = "set_default"
}

export enum OnboardingSubjectType {
  Onboarding = "onboarding",
  OnboardingEdit = "onboarding_edit"
}

export type OnboardingUserEvent = {
  subject: {
    type: OnboardingSubjectType;
    id: string;
  };
  action: OnboardingAction;
  source: UserEventSource.Other;
  created_at: string;
};

export type OnboardingEditUserEvent = {
  subject: {
    type: OnboardingSubjectType.OnboardingEdit;
    id: string;
  };
  action: OnboardingEditAction;
  source: UserEventSource.Other;
  created_at: string;
};

export type OnboardingEditStringUserEvent = OnboardingEditUserEvent & {
  context_text: string;
};

export type PostImpression = {
  subject: {
    type: "post";
    id: string;
  };
  action: "view";
  source: UserEventSource;
  created_at: string;
};

export type Tap = {
  subject: {
    type: "post";
    id: string;
  };
  action: "tap";
  source: UserEventSource.PagesGroup | UserEventSource.PagesMyFeed;
  created_at: string;
};

export type UserEvent =
  | OnboardingUserEvent
  | OnboardingEditUserEvent
  | OnboardingEditStringUserEvent
  | PostImpression
  | Tap;
