/* eslint-disable no-param-reassign */
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

import { api as apiSession } from "api/session";
import { api as apiRegistration } from "api/me/registration";

import { Registration } from "types/Registration";

import type { RootState } from "store/Store";

import { LoginDestination } from "types/LoginDestination";
import { PURGE } from "redux-persist";
import REGISTRATION_STEP_MAP from "constants/registrationStepMap";
import { SessionState } from "./types";
import { LoginMethod } from "@/api/me/registration/types";

export const actionActAs = createAction<string>("session/actAs");

export const initialState: SessionState = {
    loginDestination: { feature: "home" },
    referralParams: {},
};

export const SessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setSession: (
            state,
            {
                payload,
            }: PayloadAction<{ authToken: string; registration: Registration }>
        ) => {
            state.authToken = payload.authToken;
            state.registration = payload.registration;
        },
        setMethod: (state, { payload }: PayloadAction<LoginMethod>) => {
            state.method = payload;
        },
        setLoginDestination: (
            state,
            { payload }: PayloadAction<LoginDestination>
        ) => {
            state.loginDestination = payload;
        },
        setLoginDestinationFromSource: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            const DESTINATIONS = [
                ["pod", /\/pods\/(.*)\/([0-9a-z-]+)/, ["id", "slug"]],
                ["group", /\/groups\/(.*)\/([0-9a-z-]+)/, ["id", "slug"]],
            ];

            const destination = DESTINATIONS.map(([feature, regex, args]) => {
                const match = payload.match(regex as RegExp);

                if (!match) return undefined;

                const [, ...argValues] = match;

                const params = argValues.reduce((result, value, index) => {
                    const arg = (args as string[])[index];
                    result[arg] = value;
                    return result;
                }, {} as any);

                return [feature, params];
            }).filter((x) => x)[0];

            state.loginDestination = destination
                ? { feature: destination[0], params: destination[1] }
                : { feature: "home" };
        },

        setReferralParams: (state, { payload }: PayloadAction<string>) => {
            const searchParams = new URLSearchParams(payload);

            const newParams = {
                utmSource: searchParams.get("utm_source") || undefined,
                utmMedium: searchParams.get("utm_medium") || undefined,
                utmCampaign: searchParams.get("utm_campaign") || undefined,
                branchMatchId:
                    searchParams.get("_branch_match_id") || undefined,
                branchReferrer:
                    searchParams.get("_branch_referrer") || undefined,
            };

            // Existing params take precedence.
            state.referralParams = { ...newParams, ...state.referralParams };
        },
        logout: () =>
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            apiSession.endpoints.createSession.matchFulfilled,
            (state, { payload }) => {
                state.authToken = payload.session.authToken;
                state.registration = payload.registration;
            }
        );

        builder.addMatcher(
            apiRegistration.endpoints.getRegistration.matchFulfilled,
            (state, { payload: { registration } }) => {
                state.registration = registration;
            }
        );

        // Dev Tools: Sets the auth token.
        builder.addMatcher(
            (action) => action.type === actionActAs.toString(),
            (state, { payload }) => {
                state.authToken = payload;
            }
        );

        builder.addMatcher(
            (action) => action.type === PURGE,
            () => initialState
        );
    },
});

export const selectAuthenticated = ({
    session: { authToken, registration },
    me: { me },
}: RootState) =>
    !!authToken &&
    registration &&
    (["partial", "complete"].includes(registration.status) ||
        me?.registrationStatus === "complete");

export const selectRegistrationStatus = ({
    session: { registration },
}: RootState) => registration?.status;

export const selectAccountBlock = ({ session: { registration } }: RootState) =>
    registration?.blocked;

export const selectLoginMethod = ({ session: { method } }: RootState) => method;

export const selectLoginDestination = ({
    session: { loginDestination },
}: RootState) => loginDestination || { feature: "home" };

export const selectRegistrationPath = ({
    session: { registration },
}: RootState) => {
    if (registration?.status !== "incomplete") return undefined;

    return REGISTRATION_STEP_MAP[registration.currentStep];
};

export const selectBranchUrl = ({ session: { referralParams } }: RootState) => {
    const baseUrl = new URL(
        import.meta.env.VITE_PEANUT_PLUS_REGISTATION_DOWNLOAD_URL
    );

    baseUrl.search = new URLSearchParams({
        ...referralParams,
    }).toString();

    return baseUrl.toString();
};
export const {
    setSession,
    setMethod,
    setLoginDestination,
    setLoginDestinationFromSource,
    setReferralParams,
    logout,
} = SessionSlice.actions;

export const { reducer } = SessionSlice;
