import { z } from "zod";
import { Post, PostBase, PostMention, PostPoll, PostText } from "types/Post";
import parseJSONDate from "date-fns/parseJSON";
import { SchemaAuthorOrIncognito } from "./Author";

export const SchemaPostMention = z
  .object({
    user_uid: z.string(),
    anchor: z.string()
  })
  .transform(
    (mention): PostMention => ({
      userUid: mention.user_uid,
      anchor: mention.anchor
    })
  );

export const SchemaPost = z
  .object({
    uid: z.string(),
    author: SchemaAuthorOrIncognito,
    pinned: z.boolean(),
    post_type: z.enum(["text_poll", "text_post"]),
    title: z.string(),
    body: z.string().optional(),
    title_mentions: z.array(SchemaPostMention),
    body_mentions: z.array(SchemaPostMention),
    sensitive: z.boolean().optional(),
    view_count: z.number(),
    like_count: z.number(),
    comment_count: z.number(),
    duplicate: z.boolean(),
    image: z
      .object({
        uid: z.string(),
        url: z.string().url(),
        nsfw: z.boolean().nullish()
      })
      .optional(),
    reactions: z.object({
      default: z.string(),
      counters: z.record(z.string().min(1), z.number())
    }),
    comments_enabled: z.boolean(),
    category_id: z.number(),
    group_id: z.number().optional(),
    public: z.boolean(),
    can_change_category: z.boolean(),
    can_change_comments_enabled: z.boolean(),
    can_edit: z.boolean(),
    can_delete: z.boolean(),
    can_hide: z.boolean(),
    can_report: z.boolean(),
    can_rate: z.boolean(),
    can_pin: z.boolean(),
    can_unpin: z.boolean(),
    can_change_sensitive: z.boolean(),
    can_share: z.boolean(),
    liked_by_me: z.boolean(),
    followed_by_me: z.boolean(),
    posted_by_me: z.boolean(),
    created_at: z.string().optional(),
    premium_status: z.enum(["none", "gold", "platinum"]),
    locked: z.boolean(),
    options: z
      .array(
        z.object({
          uid: z.string(),
          body: z.string(),
          vote_count: z.number(),
          voted_by_me: z.boolean()
        })
      )
      .optional(),
    reacted_with: z.string().optional(),
    voted_by_me: z.boolean().optional()
  })
  .transform((data): Post | undefined => {
    const basePost: PostBase = {
      uid: data.uid,
      title: data.title,
      groupId: data.group_id,
      body: data.body,
      type: data.post_type as "text_poll" | "text_post",
      titleMentions: data.title_mentions,
      bodyMentions: data.body_mentions,
      createdAt: data.created_at
        ? parseJSONDate(data.created_at).toISOString()
        : undefined,
      author: data.author,
      categoryId: data.category_id,
      permissions: {
        canChangeCategory: data.can_change_category,
        canChangeCommentsEnabled: data.can_change_comments_enabled,
        canChangeSensitive: data.can_change_sensitive,
        canDelete: data.can_delete,
        canEdit: data.can_edit,
        canHide: data.can_hide,
        canPin: data.can_pin,
        canRate: data.can_rate,
        canReport: data.can_report,
        canShare: data.can_share,
        canUnpin: data.can_unpin
      },
      status: {
        commentsEnabled: data.comments_enabled,
        duplicate: data.duplicate,
        followedByMe: data.followed_by_me,
        likedByMe: data.liked_by_me,
        locked: data.locked,
        pinned: data.pinned,
        postedByMe: data.posted_by_me,
        premiumStatus: data.premium_status,
        public: data.public,
        sensitive: data.sensitive
      },
      counts: {
        comments: data.comment_count,
        likes: data.like_count,
        views: data.view_count
      },
      reactions: {
        default: data.reactions.default,
        reactedWith: data.reacted_with,
        counters: data.reactions.counters
      }
    };

    if (basePost.type === "text_poll") {
      return {
        ...basePost,
        options: data.options?.map((option) => ({
          uid: option.uid,
          body: option.body,
          count: option.vote_count,
          selected: option.voted_by_me
        })),
        voted: data.voted_by_me
      } as PostPoll;
    }
    if (basePost.type === "text_post") {
      return {
        ...basePost,
        image: data.image
          ? {
              uid: data.image.uid,
              url: data.image.url,
              nsfw: data.image.nsfw
            }
          : undefined
      } as PostText;
    }

    return undefined;
  });

export default SchemaPost;

/*

{
    "post": {
        "uid": "3ui-gha",
        "author": {
            "uid": "ym-nzagi3tujiy2",
            "name": "Carol",
            "avatar_url": "https://assets-staging.teampeanut.com/user/{width}x{height}/43p-jaqfgnqmcvre.jpg",
            "location": {
                "name": "Partick, Glasgow City, GB"
            },
            "badges": [
                "moderator",
                "author",
                "creator"
            ],
            "profile_badges": [
                {
                    "id": "ccfaa992-6f0c-4f49-9edc-aea5ccaaf55e",
                    "label": "Selfie queen",
                    "description": "Adding more photos increases your chances of finding connections.",
                    "icon_url": "https://storage.googleapis.com/peanut-assets/images/awards/photo_completeness-{status}.png"
                }
            ],
            "liveness": {
                "online": false,
                "last_seen_at": "2022-11-24T03:30:24.552Z"
            }
        },
        "pinned": false,
        "post_type": "text_post",
        "title": "Throw comments in here",
        "body": "Test",
        "sensitive": false,
        "view_count": 25,
        "like_count": 3,
        "comment_count": 43,
        "duplicate": false,
        "reactions": {
            "default": "excited",
            "counters": {
                "angry": 1,
                "excited": 1,
                "sad": 1
            }
        },
        "comments_enabled": true,
        "title_mentions": [],
        "body_mentions": [],
        "boost": {
            "can_view_button": false,
            "boostable": false,
            "progress": null,
            "advertised": false
        },
        "category_id": 17,
        "tags": [
            {
                "id": 59,
                "name": "Breastfeeding"
            }
        ],
        "group_id": 6636,
        "public": true,
        "can_change_category": false,
        "can_change_comments_enabled": false,
        "can_edit": false,
        "can_delete": false,
        "can_hide": false,
        "can_report": true,
        "can_rate": false,
        "can_pin": false,
        "can_unpin": false,
        "can_change_sensitive": false,
        "can_share": true,
        "liked_by_me": false,
        "followed_by_me": false,
        "posted_by_me": false,
        "premium_status": "none",
        "locked": false
    }
}
*/
