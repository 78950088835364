import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "types/api/pages/post";
import { dynamicBaseQuery } from "api";
import { Post } from "types/Post";

import { SchemaPost } from "schema/Post";
import { blockPost } from "reducers/MeReducer";
import { api as FeedApi } from "../Pages/Categories/PostsApi";

export type GetPostRequest = {
    uid: string;
};

export type PostReactionCreateRequest = {
    postUid: string;
    code: string;
};

export type PostReactionCreateResponse = void;

export type PostActionRequest = {
    postUid: string;
};

export type PostReportRequest = {
    postUid: string;
    reasonId: number;
};

const invalidatePostTags: any = (
    result: any,
    error: any,
    arg: PostActionRequest
) => [
    {
        type: "Post",
        id: arg.postUid,
    },
    {
        type: "FeedItem",
        uid: arg.postUid,
    },
];

export const api = createApi({
    reducerPath: "api/pages/posts",
    baseQuery: dynamicBaseQuery,
    tagTypes: ["Post", "FeedItem", "PostByAuthor"],
    endpoints: (builder) => ({
        getPost: builder.query<Post | undefined, GetPostRequest>({
            query: ({ uid }) => ({
                url: `pages/posts/${uid}`,
            }),
            providesTags: (result, error, arg: GetPostRequest) => [
                {
                    type: "Post",
                    id: arg.uid,
                },
            ],
            transformResponse: ({ post }: { post: Api.Post }) =>
                SchemaPost.parse(post),
        }),

        createPostFollow: builder.mutation<void, PostActionRequest>({
            query: ({ postUid }) => ({
                url: `pages/posts/${postUid}/follow`,
                method: "POST",
            }),
            invalidatesTags: invalidatePostTags,
        }),

        deletePostFollow: builder.mutation<void, PostActionRequest>({
            query: ({ postUid }) => ({
                url: `pages/posts/${postUid}/follow`,
                method: "DELETE",
            }),
            invalidatesTags: invalidatePostTags,
        }),

        createPostReaction: builder.mutation<
            PostReactionCreateResponse,
            PostReactionCreateRequest
        >({
            query: ({ postUid, code }) => ({
                url: `pages/posts/${postUid}/reactions`,
                method: "PUT",
                body: {
                    code,
                },
            }),
            invalidatesTags: invalidatePostTags,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    FeedApi.util.invalidateTags([
                        { type: "FeedItem", id: arg.postUid },
                    ])
                );
            },
        }),

        deletePostReaction: builder.mutation<void, PostActionRequest>({
            query: ({ postUid }) => ({
                url: `pages/posts/${postUid}/reactions`,
                method: "DELETE",
            }),
            invalidatesTags: invalidatePostTags,
        }),

        createPostReport: builder.mutation<void, PostReportRequest>({
            query: ({ postUid, reasonId }) => ({
                url: `pages/posts/${postUid}/reports`,
                method: "POST",
                body: {
                    reason_id: reasonId,
                },
            }),
            invalidatesTags: invalidatePostTags,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(blockPost(arg.postUid));
            },
        }),
    }),
});

export const {
    useGetPostQuery,
    useLazyGetPostQuery,
    useCreatePostFollowMutation,
    useDeletePostFollowMutation,
    useCreatePostReactionMutation,
    useDeletePostReactionMutation,
    useCreatePostReportMutation,
} = api;
