import { IconProps } from "../../types";

const BlockedUsers = ({ strokeColor, size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 20.5714C16.7339 20.5714 20.5714 16.7339 20.5714 12C20.5714 7.26613 16.7339 3.42857 12 3.42857C7.26613 3.42857 3.42857 7.26613 3.42857 12C3.42857 16.7339 7.26613 20.5714 12 20.5714Z"
            stroke={strokeColor}
            strokeWidth="1.20189"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18.2624 5.7753L5.77529 18.2624"
            stroke={strokeColor}
            strokeWidth="1.20189"
        />
    </svg>
);

export default BlockedUsers;
