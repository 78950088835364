import QRCode from "react-qr-code";
import styles from "./styles.module.scss";
import RatingMobile from "@/components/RatingMobile";
import ActionButton from "@/components/Buttons/ActionButton";

const QRCodeComposition = ({ rated = true }: any) => {
  return (
    <div>
      <div className={styles.qrCode}>
        <QRCode value={import.meta.env.VITE_BRANCH_LINK_DEFAULT} size={130} />
        <div className={styles.info}>
          <h4 className={styles.title}>Scan the QR code and get the app </h4>
          <RatingMobile rated />
        </div>
      </div>
      <div className={styles.mobile}>
        <a
          href={import.meta.env.VITE_WEBSITE_DOWNLOAD_URL}
          className={styles.button}
        >
          Download the app
        </a>
        <RatingMobile rated center />
      </div>
    </div>
  );
};

export default QRCodeComposition;
