import slugify from "helpers/slugify";
import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

import { transform as transformParticipant } from "./Participant";

export const transform = (source: Api.AudioRoom): Pods.Room => ({
  id: source.id,
  title: source.title,
  description: source.description,
  status: source.status,
  public: source.public,
  group: source.group,
  participantCount: source.participant_count,
  featuredParticipants: source.featured_participants.map(transformParticipant),
  tags: source.tags,
  mode: source.mode,
  scheduledAt: source.scheduled_at,
  createdByMe: source.created_by_me,
  peanutPick: source.peanut_pick,
  creator: source.creator,
  publicUrl: `${import.meta.env.VITE_WEBSITE_URL}/pods/${source.id}/${slugify(
    source.title
  )}`,
});

export default transform;
