import { Api } from "types/api/me/subscriptions";
import { UserSubscriptionTier } from "types/UserSubscriptionTier";

export const transform = (
  response: Api.UserSubscriptionTier,
  currency: string
): UserSubscriptionTier => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0
  });

  const numbericPrice = parseFloat(response.price);
  return {
    id: response.id,
    interval: response.interval,
    intervalCount: response.interval_count,
    price: numbericPrice,
    popular: response.popular,
    productId: response.product_id,
    formattedPrice: formatter.format(numbericPrice),
    offers: response.offers.map((offer) => {
      const numbericOfferPrice = parseFloat(offer.discounted_price);

      return {
        code: offer.code,
        percentOff: offer.percent_off,
        discountedPrice: numbericOfferPrice,
        formattedPrice: formatter.format(numbericOfferPrice)
      };
    })
  };
};

export default transform;
