import { createApi } from "@reduxjs/toolkit/query/react";
import { api as PostsApi } from "api/posts";

import { dynamicBaseQuery } from "api";

export type BlockedUserRequest = {
  userUid: string;
  source: string;
};

export const api = createApi({
  reducerPath: "api/me/blocked_users",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    createdBlockedUser: builder.mutation<void, BlockedUserRequest>({
      query: ({ userUid, source }) => ({
        url: `me/blocked_users`,
        method: "POST",
        body: {
          uid: userUid,
          source
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          PostsApi.util.invalidateTags([
            { type: "PostByAuthor", id: arg.userUid }
          ])
        );
      }
    })
  })
});

export const { useCreatedBlockedUserMutation } = api;
