import React, { useMemo } from "react";
import classNames from "classnames";

import AppBarButton, {
  AppButtonFeature
} from "components/AppBarButton/AppBarButton";
import { useLocation } from "react-router";
import styles from "./MobileActionBar.module.scss";

export interface Props {
  className?: string;
}

function MobileActionBar({ className }: Props): JSX.Element {
  const { pathname } = useLocation();

  const selectedFeature = useMemo(() => {
    switch (pathname) {
      case "/discovery":
        return AppButtonFeature.Discovery;
      case "/feed":
        return AppButtonFeature.Feed;
      case "/pods":
        return AppButtonFeature.Pods;
      case "/notifications":
        return AppButtonFeature.Notifications;
      case "/posts":
        return AppButtonFeature.Posts;
      default:
        return undefined;
    }
  }, [pathname]);
  return (
    <nav
      className={classNames(
        styles.MobileActionBar,
        className,
        selectedFeature ? styles[selectedFeature] : styles.hidden
      )}
    >
      <AppBarButton feature={AppButtonFeature.Discovery} />
      <AppBarButton feature={AppButtonFeature.Feed} />
      <AppBarButton feature={AppButtonFeature.Pods} />
      <AppBarButton feature={AppButtonFeature.Notifications} count={9} />
    </nav>
  );
}

export default MobileActionBar;
