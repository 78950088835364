import { IconProps } from "../../types";

const SubscriptionIcon = ({
    strokeColor = "#EA515A",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="4.82324"
                y="4.28571"
                width="16.2857"
                height="11.1429"
                rx="1.72155"
                stroke="black"
                strokeWidth="1.20508"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="4.4092"
                y="11.1901"
                width="17.2155"
                height="1.72155"
                fill={fill}
            />
            <path
                d="M9.31562 15.8383C9.31562 15.6956 9.43123 15.58 9.57385 15.58H16.46C16.6027 15.58 16.7183 15.6956 16.7183 15.8383V20.4291C16.7183 20.624 16.5105 20.7486 16.3385 20.6569L13.7056 19.2527C13.2752 19.0231 12.7587 19.0231 12.3283 19.2527L9.69537 20.6569C9.52336 20.7486 9.31562 20.624 9.31562 20.4291V15.8383Z"
                stroke={strokeColor}
                stroke-width="1.20508"
            />
        </svg>
    );
};

export default SubscriptionIcon;
