import { createApi } from "@reduxjs/toolkit/query/react";

import { Api } from "types/api/me/photo_details";

import { dynamicBaseQuery } from "api";
import { PhotoDetails } from "types/PhotoDetails";

export type PhotoDetailsGetRequest = {
  id: number;
};
export type PhotoDetailsGetResponse = PhotoDetails;

export const api = createApi({
  reducerPath: "api/me/photo_details",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPhotoDetails: builder.query<
      PhotoDetailsGetResponse,
      PhotoDetailsGetRequest
    >({
      query: ({ id }) => ({
        url: `me/photo_details/${id}`,
        method: "GET"
      }),
      transformResponse: ({
        nsfw,
        female_portrait,
        bypassed,
        warnings
      }: Api.PhotoDetails) => ({
        nsfw,
        femalePortrait: female_portrait,
        bypassed,
        warnings
      })
    })
  })
});

export const { useLazyGetPhotoDetailsQuery, useGetPhotoDetailsQuery } = api;
export const useLazyGetPhotoDetailsSubscription =
  api.endpoints.getPhotoDetails.useLazyQuerySubscription;
