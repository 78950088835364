import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import {
    StripeCheckoutSessionRequestProps,
    StripeCheckoutSessionResponseProps,
    CustomerPortalSessionRequestProps,
    CustomerPortalSessionResponseProps,
} from "./types";
import {
    StripeCheckoutResponseSchema,
    StripeCheckoutRequestBodySchema,
    CustomerPortalSessionResponseSchema,
    CustomerPortalSessionRequestBodySchema,
} from "@/schema/StripeApi";

export const stripeApi = createApi({
    reducerPath: "api/stripe",
    baseQuery: dynamicBaseQuery,
    tagTypes: ["StripeSession"],
    endpoints: (builder) => ({
        createStipeCheckoutSession: builder.mutation<
            StripeCheckoutSessionResponseProps,
            StripeCheckoutSessionRequestProps
        >({
            query: ({
                priceId,
                offerCode,
                productClientId,
                cancelPath,
                successPath,
            }) => {
                const parsedBody = StripeCheckoutRequestBodySchema.safeParse({
                    product_client_id: productClientId,
                    tier_id: priceId,
                    offer_code: offerCode,
                    cancel_path: cancelPath,
                    success_path: successPath,
                });

                if (!parsedBody.success) {
                    throw new Error("Invalid Stripe request body format");
                }

                return {
                    url: `/stripe/create_checkout_session`,
                    method: "POST",
                    body: parsedBody.data,
                };
            },
            transformResponse: (rawData) => {
                const parsedData =
                    StripeCheckoutResponseSchema.safeParse(rawData);

                if (!parsedData.success) {
                    throw new Error("Invalid Stripe response");
                }

                return {
                    sessionUrl: parsedData.data.session_url,
                };
            },
        }),

        customerPortalSession: builder.mutation<
            CustomerPortalSessionResponseProps,
            CustomerPortalSessionRequestProps
        >({
            query: ({ sessionUrl }) => {
                const parsedBody =
                    CustomerPortalSessionRequestBodySchema.safeParse({
                        session_url: sessionUrl,
                    });

                if (!parsedBody.success) {
                    throw new Error(
                        "Invalid customer portal session request body format"
                    );
                }

                return {
                    url: `/stripe/create_customer_portal_session`,
                    method: "POST",
                    body: parsedBody.data,
                };
            },
            transformResponse: (rawData) => {
                const parsedData =
                    CustomerPortalSessionResponseSchema.safeParse(rawData);

                if (!parsedData.success) {
                    throw new Error("Invalid customer portal session response");
                }

                return {
                    sessionUrl: parsedData.data.session_url,
                };
            },
        }),
    }),
});

export const {
    useCreateStipeCheckoutSessionMutation,
    useCustomerPortalSessionMutation,
} = stripeApi;
