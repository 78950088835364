import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api } from "types/api/me/subscriptions";
import { UserSubscriptionTier } from "types/UserSubscriptionTier";
import { transformUserSubscriptionTier } from "api/dataTransformations";
import * as Sentry from "@sentry/browser";

export type UserSubscriptionsRequest = {
    userUid: string;
    plan: string;
};
export type UserSubscriptionsResponse = {
    subscriptionId: string;
    currency: string;
    tiers: UserSubscriptionTier[];
};

export const api = createApi({
    reducerPath: "api/web/subscriptions",

    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("PEANUT-CLIENT", import.meta.env.VITE_PEANUT_CLIENT!);
            return headers;
        },
    }),
    tagTypes: ["PublicSubscription"],
    endpoints: (builder) => ({
        getPublicSubscriptionTiers: builder.query<
            UserSubscriptionsResponse,
            UserSubscriptionsRequest
        >({
            query: ({ userUid, plan }) => ({
                url: `web/subscriptions/${plan}`,
                method: "GET",
                params: {
                    user_uid: userUid,
                },
            }),
            providesTags: (result) => [
                {
                    type: "PublicSubscription",
                    id: result?.subscriptionId,
                },
            ],
            transformResponse: (response: Api.UserSubscriptionsResponse) => ({
                subscriptionId: response.subscription_id,
                currency: response.currency,
                tiers: response.tiers.map((t) =>
                    transformUserSubscriptionTier(t, response.currency)
                ),
            }),
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "PublicSubscriptionApi",
                    error,
                });

                return error;
            },
        }),
    }),
});

export const { useGetPublicSubscriptionTiersQuery } = api;
