import { z } from "zod";

export const StripeCheckoutResponseSchema = z.object({
    session_url: z.string(),
});

export const StripeCheckoutRequestBodySchema = z.object({
    product_client_id: z.string().optional(),
    tier_id: z.string(),
    offer_code: z.string(),
    cancel_path: z.string(),
    success_path: z.string(),
});

export const PubStripeCheckoutRequestBodySchema =
    StripeCheckoutRequestBodySchema.extend({
        user_uid: z.string(),
    });

export const CustomerPortalSessionResponseSchema = z.object({
    session_url: z.string(),
});

export const CustomerPortalSessionRequestBodySchema = z.object({
    session_url: z.string(),
});
