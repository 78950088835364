import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import SchemaGroup from "schema/Group";
import SchemaGroupsOverview from "schema/GroupsOverview";
import { Group } from "types/Group";
import * as Sentry from "@sentry/browser";
export type PagesGroupsOverviewRequest = void;

export type PagesGroupsOverviewResponse = Record<
    string,
    { title: string; groups: Group[] }
>;

export type GetPagesGroupsSectionRequest = {};

export type GetPagesGroupsSectionResponse = {
    groups: Group[];
};

export const api = createApi({
    reducerPath: "api/pages/groups",
    baseQuery: dynamicBaseQuery,

    endpoints: (builder) => ({
        getOverview: builder.query<
            PagesGroupsOverviewResponse,
            PagesGroupsOverviewRequest
        >({
            query: () => ({
                url: `pages/groups/overview`,
            }),
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "GetOverview",
                    error,
                });

                return error;
            },
            transformResponse: (response: any) => response,
            // SchemaGroupsOverview.parse(response)
        }),

        getMyGroupsSection: builder.query<
            GetPagesGroupsSectionResponse,
            GetPagesGroupsSectionRequest
        >({
            query: () => ({
                url: `pages/groups/section/my_groups`,
            }),

            transformResponse: ({ groups }: any) => ({
                // groups: groups.map((g: any) => SchemaGroup.parse(g)),
                groups: groups.map((g: any) => g),
            }),
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "GetMyGroupsSection",
                    error,
                });

                return error;
            },
        }),
    }),
});

export const { useGetOverviewQuery, useGetMyGroupsSectionQuery } = api;
