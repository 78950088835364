import { ProfileBadge } from "types/ProfileBadge";
import { Api } from "types/api/profile_badge";

export function transform(apiProfileBadge: Api.ProfileBadge): ProfileBadge {
  return {
    id: apiProfileBadge.id,
    label: apiProfileBadge.label,
    description: apiProfileBadge.description,
    iconUrl: apiProfileBadge.icon_url
  };
}

export default transform;
