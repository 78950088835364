import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "types/api/comment";
import { dynamicBaseQuery } from "api";
import { Comment } from "types/Comment";
import { transformComment } from "api/dataTransformations";
import { Mention } from "types/Mention";

export type GetFlatComentsRequest = {
  postUid: string;
  before?: string;
  after?: string;
};

export type GetFlatCommentsResponse = {
  data: Comment[];
  cursors: {
    before?: string;
    after?: string;
  };
};

export type CommentCreateRequest = {
  body: string;
  mentions: Mention[];
  postedAs: "self" | "incognito";
  postUid: string;
  uploadImage: boolean;
  uploadVideo: boolean;
};

export type CommentUpdateResponse = {
  comment: Comment;
};

export type CommentUpdateRequest = Omit<CommentCreateRequest, "postUid"> & {
  uid: string;
};

export type CommentDeleteRequest = { uid: string };

export type CommentCreateResponse = {
  askToInvite: boolean;
  comment: Comment;
};

export type CommentEventRequest = {
  commentUid: string;
  eventType: "like";
};

export type CommentCreateReportRequest = {
  commentUid: string;
  reasonId: number;
};

export const api = createApi({
  reducerPath: "api/comments",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["FlatComments", "Comment"],
  endpoints: (builder) => ({
    getFlatComments: builder.query<
      GetFlatCommentsResponse,
      GetFlatComentsRequest
    >({
      query: ({ after, before, postUid }) => ({
        url: `community/posts/${postUid}/flat_comments`,
        params: {
          before,
          after
        }
      }),
      providesTags: (result, error, arg: GetFlatComentsRequest) => [
        {
          type: "FlatComments",
          id: arg.postUid,
          before: arg.before,
          after: arg.after
        },
        ...(result?.data || []).map(({ uid }) => ({
          type: "Comment" as const,
          uid
        }))
      ],
      transformResponse: ({
        data,
        cursors
      }: {
        data: Api.Comment[];
        cursors: { before: string; after: string };
      }) => ({
        data: data.map(transformComment),
        cursors
      })
    }),

    createComment: builder.mutation<
      CommentCreateResponse,
      CommentCreateRequest
    >({
      query: ({
        postUid,
        body,
        mentions,
        postedAs,
        uploadImage,
        uploadVideo
      }) => ({
        url: `pages/posts/${postUid}/comments`,
        method: "POST",
        body: {
          comment: {
            body,
            mentions,
            posted_as: postedAs,
            upload_image: uploadImage,
            upload_video: uploadVideo
          }
        }
      }),

      transformResponse: ({
        ask_to_invite,
        comment
      }: {
        ask_to_invite: boolean;
        comment: Api.Comment;
      }) => ({
        askToInvite: ask_to_invite,
        comment: transformComment(comment)
      }),
      invalidatesTags: (result, error, arg: CommentCreateRequest) => [
        { type: "FlatComments", id: arg.postUid }
      ]
    }),

    updateComment: builder.mutation<
      CommentUpdateResponse,
      CommentUpdateRequest
    >({
      query: ({ uid, body, mentions, uploadImage, uploadVideo }) => ({
        url: `pages/comments/${uid}`,
        method: "PATCH",
        body: {
          comment: {
            body,
            mentions,
            upload_image: uploadImage,
            upload_video: uploadVideo
          }
        }
      }),
      transformResponse: ({ comment }: { comment: Api.Comment }) => ({
        comment: transformComment(comment)
      }),
      invalidatesTags: (result, error, arg: CommentUpdateRequest) => [
        { type: "Comment", uid: arg.uid }
      ]
    }),

    deleteComment: builder.mutation<void, CommentDeleteRequest>({
      query: ({ uid }) => ({
        url: `pages/comments/${uid}`,
        method: "DELETE"
      }),
      invalidatesTags: (result, error, arg: CommentDeleteRequest) => [
        { type: "Comment", uid: arg.uid }
      ]
    }),

    createCommentEvent: builder.mutation<void, CommentEventRequest>({
      query: ({ commentUid, eventType }) => ({
        url: `pages/comments/${commentUid}/events`,
        method: "POST",
        body: {
          event_type: eventType
        }
      }),
      invalidatesTags: (result, error, arg: CommentEventRequest) => [
        { type: "Comment", uid: arg.commentUid }
      ]
    }),

    deleteCommentEvent: builder.mutation<void, CommentEventRequest>({
      query: ({ commentUid, eventType }) => ({
        url: `pages/comments/${commentUid}/${eventType}`,
        method: "DELETE"
      }),
      invalidatesTags: (result, error, arg: CommentEventRequest) => [
        { type: "Comment", uid: arg.commentUid }
      ]
    }),

    createCommentReport: builder.mutation<void, CommentCreateReportRequest>({
      query: ({ commentUid, reasonId }) => ({
        url: `pages/comments/${commentUid}/reports`,
        method: "POST",
        body: {
          reason_id: reasonId
        }
      })
    })
  })
});

export const {
  useLazyGetFlatCommentsQuery,
  useCreateCommentMutation,
  useGetFlatCommentsQuery,
  useCreateCommentEventMutation,
  useDeleteCommentEventMutation,
  useCreateCommentReportMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation
} = api;
