import { z } from "zod";

import { SchemaGroup } from "./Group";

export const SchemaMeGroup = SchemaGroup.and(
  z
    .object({
      unread_post_count: z.number()
    })
    .transform((data) => {
      const x = {
        unreadPostCount: data.unread_post_count
      };

      return x;
    })
);

export default SchemaMeGroup;
