import {
    ApiMeRegistration,
    ApiMeRegistrationBlocked,
} from "types/api/me/registration";
import { Registration, RegistrationBlock } from "types/Registration";

const notNull = (value: any) => (value === null ? undefined : value);
const transformBlocked = (
    apiBlocked: ApiMeRegistrationBlocked | null
): RegistrationBlock | undefined =>
    apiBlocked !== null
        ? {
              body: notNull(apiBlocked?.body),
              createdAt: apiBlocked?.created_at,
              guidelineTitle: notNull(apiBlocked?.guideline_title),
              reason: notNull(apiBlocked?.reason),
              thumbnailUrl: notNull(apiBlocked?.thumbnail_url),
              title: notNull(apiBlocked?.title),
              type: apiBlocked?.type,
          }
        : undefined;

export const transformRegBody = ({
    status,
    current_step,
    blocked,
}: ApiMeRegistration): Registration => ({
    status,
    currentStep: current_step,
    blocked: transformBlocked(blocked),
});

export default transformRegBody;
