import { z } from "zod";

import type { UserBadge } from "types/UserBadge";
import type { UserSummary, ConnectionState } from "types/UserSummary";

export const SchemaUserSummary = z
  .object({
    uid: z.string(),
    name: z.string(),
    first_name: z.string(),
    last_name: z.string().optional(),
    badges: z.array(z.string()),
    avatar_url: z.string(),
    connection_state: z.enum(["connected", "waved", "none"]).optional(),
    location: z
      .object({
        name: z.string()
      })
      .optional(),
    can_be_tipped: z.boolean().optional()
  })
  .transform(
    (data): UserSummary => ({
      uid: data.uid,
      name: data.name,
      firstName: data.first_name,
      lastName: data.last_name,
      badges: data.badges as UserBadge[],
      avatarUrl: data.avatar_url,
      connectionState: data.connection_state as ConnectionState,
      location: data.location,
      permissions: {
        canBeTipped: data.can_be_tipped
      }
    })
  );

export default SchemaUserSummary;
