import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ActionButton from "components/Buttons/ActionButton";
import Skeleton from "react-loading-skeleton";

import { Pods } from "types/Pods";
import styles from "./PodCard.module.scss";

import Card from "../Card";

export interface Props {
  room: Pods.Room;
  onClick: () => void;
}

// i18next.addResourceBundle("en", "podCard", require("./i18n/en.json"));

function PodCard({
  onClick,
  room: { title, featuredParticipants }
}: Props): JSX.Element {
  const { t } = useTranslation("PodCard");

  const hostNames = useMemo(
    () =>
      featuredParticipants
        .filter(({ status }) => ["host", "cohost"].includes(status))
        .map(({ firstName }) => firstName),
    [featuredParticipants]
  );

  const handleClick = () => {
    onClick();
  };

  return (
    <Card className={styles.wrapper}>
      <h3>{title}</h3>
      {hostNames.length > 0 && (
        <p className={styles.hostNames}>
          <span>{t("hosts", { count: hostNames.length, ns: "podCard" })}</span>{" "}
          {hostNames.slice(0, 3).join(", ")}
        </p>
      )}
      <ActionButton onClick={handleClick} className={styles.button}>
        {t("joinNow")}
      </ActionButton>
    </Card>
  );
}

PodCard.Mock = function mock() {
  const { t } = useTranslation();
  const handleClick = () => {};

  return (
    <div className={styles.wrapper}>
      <h3>
        <Skeleton />
      </h3>
      <p>
        <Skeleton />
      </p>
      <ActionButton disabled onClick={handleClick} className={styles.button}>
        {t("pod.joinNow")}
      </ActionButton>
    </div>
  );
};
export default PodCard;
