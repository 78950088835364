import { IconProps } from "../../types";
const PregnantIcon = ({
    strokeColor = "black",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.6552 12.0554C21.6552 14.8654 19.4368 17.151 16.71 17.1504C15.7483 17.1504 14.8502 16.8653 14.0903 16.3741L14.0897 16.3747C13.4836 15.9805 12.7676 15.7514 12 15.7514C11.2324 15.7514 10.5158 15.9799 9.90971 16.3741C9.14976 16.8653 8.25175 17.1504 7.28997 17.1504C4.56317 17.151 2.34417 14.8648 2.34475 12.0554C2.34417 9.24592 4.56317 6.95968 7.28997 6.96029C8.25175 6.96029 9.14976 7.24539 9.90971 7.73663C10.5158 8.13083 11.2324 8.35928 12 8.35928C12.7676 8.35928 13.4836 8.13023 14.0897 7.73603L14.0903 7.73663C14.8502 7.24539 15.7483 6.96029 16.71 6.96029C19.4368 6.95968 21.6552 9.24532 21.6552 12.0554Z"
                stroke={strokeColor}
                strokeWidth="1.32"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default PregnantIcon;
