import { IconProps } from "../../types";

const XIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.0474 7.98396C20.0551 8.15996 20.0551 8.32796 20.0551 8.50396C20.0628 13.84 16.1424 20 8.97307 20C6.85853 20 4.78257 19.368 2.99988 18.184C3.30857 18.224 3.61726 18.24 3.92595 18.24C5.67778 18.24 7.38331 17.632 8.7647 16.504C7.09777 16.472 5.63148 15.344 5.12214 13.696C5.70865 13.816 6.3106 13.792 6.88168 13.624C5.06811 13.256 3.76389 11.6 3.75617 9.67197C3.75617 9.65597 3.75617 9.63997 3.75617 9.62397C4.29638 9.93597 4.90605 10.112 5.52344 10.128C3.81791 8.94396 3.28542 6.58396 4.31954 4.73595C6.30288 7.26396 9.22002 8.79196 12.3532 8.95996C12.0368 7.55996 12.469 6.08796 13.48 5.09595C15.0466 3.56795 17.5161 3.64795 18.9978 5.27195C19.8699 5.09595 20.7111 4.75995 21.4751 4.28795C21.1818 5.22395 20.5722 6.01596 19.7619 6.51996C20.5336 6.42396 21.2899 6.20796 21.9999 5.88795C21.4751 6.70396 20.8114 7.40796 20.0474 7.98396Z"
            fill="#1D9BF0"
        />
    </svg>
);

export default XIcon;
