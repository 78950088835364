import { IconProps } from "../../types";
const LogoutIcon = ({
    strokeColor = "black",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.57176 8.14285V5.99999C8.57176 5.05322 9.33927 4.28571 10.286 4.28571H17.1432C18.09 4.28571 18.8575 5.05322 18.8575 5.99999V18C18.8575 18.9468 18.09 19.7143 17.1432 19.7143H10.286C9.33928 19.7143 8.57176 18.9468 8.57176 18V16.5"
                stroke={strokeColor}
                strokeWidth="1.55"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.07145 12.4381H11.4351"
                stroke={strokeColor}
                strokeWidth="1.55"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.6429 12.0001H16.2857"
                stroke={strokeColor}
                strokeWidth="1.55"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.05844 15.0095L3.42857 12.3797L6.05844 9.74978"
                stroke={strokeColor}
                strokeWidth="1.55"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default LogoutIcon;
