import React from "react";
import classNames from "classnames";
import styles from "./Pill.module.scss";

export interface Props {
  children: React.ReactNode;
  className?: string;
}

function Pill({ children, className }: Props): JSX.Element {
  return (
    <div className={classNames(styles.wrapper, className)}>{children}</div>
  );
}

export default Pill;
