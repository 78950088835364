import { useClearProfileMutation } from "api/me";
import { useCreateSessionMutation } from "api/session";
import { useAppDispatch } from "hooks";
import { actionLogout } from "reducers/MeReducer";
import { setMethod } from "reducers/SessionReducer";
import { persistor } from "store";

const useTestingLogin = () => {
  const dispatch = useAppDispatch();
  const [createSession] = useCreateSessionMutation();
  const [clearProfile] = useClearProfileMutation();
  const actAs = async (firebaseAuth: string) => {
    if (firebaseAuth) {
      await dispatch(actionLogout());
      await dispatch(setMethod("firebase"));
      await createSession({
        session: {
          login_method: "firebase",
          token: firebaseAuth
        },
        target: "complete"
      } as any);
    }
  };

  const clear = async () => {
    await clearProfile({});
    await dispatch(actionLogout());

    persistor.pause();
    persistor.pause();
    persistor.flush().then(() => persistor.purge());
  };

  return { login: actAs, clear };
};

export default useTestingLogin;
