import React, { useState } from "react";
import { createPortal } from "react-dom";
import Button from "../Button";
import style from "./styles.module.scss";
import { PopUpProps } from "./types";

function Popup({ content, children }: PopUpProps) {
    const [position, setPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [visible, setVisible] = useState(false);

    const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        setPosition({
            x: bounds.x,
            y: bounds.y + bounds.height,
        });
    };

    const handleClicked = () => {
        setVisible(!visible);
    };

    const anchorProps = {
        onMouseOver: handleMouseOver,
    };

    const anchor = <span {...anchorProps}>{children}</span>;

    return (
        <>
            <span onClick={handleClicked}>{anchor}</span>
            {visible &&
                createPortal(
                    <div
                        className={style.container}
                        style={{
                            top: position ? position.y - 130 : 0,
                            left: position ? position.x + 60 : 0,
                            position: "fixed",
                            zIndex: 1000,
                        }}
                    >
                        <div>{content}</div>
                    </div>,
                    document.body
                )}
        </>
    );
}
export default Popup;
