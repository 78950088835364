import { Api } from "types/api/tags";
import { Tag } from "types/Tag";

export const transform = ({ tag_id, val, icon_url }: Api.Tag): Tag => ({
  id: tag_id,
  title: val,
  iconUrl: icon_url,
});

export default transform;
