import { IconProps } from "../../types";
const GuidlinesIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.10896 5.70836V12.8895C6.10896 15.0169 7.90811 18.1232 12.9601 19.9172C18.0181 18.1232 19.8172 15.0169 19.8172 12.8895L19.8232 5.70836L12.9661 3.42836L6.10896 5.70836Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.003 14.5713C13.003 14.5713 16.4685 12.6513 16.4685 10.5438C16.4685 8.43683 14.1265 7.92183 13.003 9.46733C11.879 7.92183 9.53753 8.43683 9.53753 10.5438C9.53753 12.6513 13.003 14.5713 13.003 14.5713"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default GuidlinesIcon;
