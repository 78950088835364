import { useCreateUserEventMutation } from "api/UserEventsApi";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useRef, useState } from "react";
import {
  removeProcessedEvents,
  selectUnprocessedUserEvents
} from "reducers/UserEventsReducer";

function useUserEventDispatch() {
  const dispatch = useAppDispatch();
  const initialized = useRef(false);
  const unprocessed = useAppSelector(selectUnprocessedUserEvents);
  const [flush, setFlush] = useState(false);
  const [createUserEvents] = useCreateUserEventMutation();

  const sendUserEvents = async () => {
    if (unprocessed.length > 0) {
      const eventIds = unprocessed.map((e) => e.id);
      const events = unprocessed.map((e) => e.event);

      await createUserEvents(events);
      await dispatch(removeProcessedEvents(eventIds));
    }
    setFlush(false);
  };

  useEffect(() => {
    // Prevents the interval from being set multiple times
    if (initialized.current) return;
    initialized.current = true;

    // Triggers the flush useEffect every 5 seconds
    setInterval(() => {
      setFlush(true);
    }, 5_000);
  }, []);

  useEffect(() => {
    if (!flush) return;
    sendUserEvents();
  }, [flush]);
}

export default useUserEventDispatch;
