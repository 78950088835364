import { createApi } from "@reduxjs/toolkit/query/react";

import { Api } from "types/api/me/user_photos";

import { dynamicBaseQuery } from "api";
import { UserPhoto } from "types/UserPhoto";

export type UserPhotoSignedUrlRequest = {};
export type UserPhotoSignedUrlResponse = {
  storageKey: string;
  url: string;
};

export type UserPhotoConfirmationRequest = {
  storageKey: string;
  md5: string;
  moderate?: boolean;
};
export type UserPhotoConfirmationResponse = UserPhoto;

export const api = createApi({
  reducerPath: "api/me/user_photos",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    signedUrl: builder.query<
      UserPhotoSignedUrlResponse,
      UserPhotoSignedUrlRequest
    >({
      query: () => ({
        url: `me/user_photos/new`,
        method: "GET",
      }),
      transformResponse: ({
        user_photo: { storage_key, url },
      }: Api.UserPhotoNew) => ({ storageKey: storage_key, url }),
    }),

    confirmation: builder.mutation<
      UserPhotoConfirmationResponse,
      UserPhotoConfirmationRequest
    >({
      query: ({ storageKey, md5, moderate = false }) => ({
        url: `v1/me/user_photos`,
        method: "POST",
        body: {
          user_photo: {
            storage_key: storageKey,
            md5,
            moderate,
          },
        },
      }),
      transformResponse: ({ user_photo }: { user_photo: Api.UserPhoto }) =>
        user_photo,
    }),
  }),
});

export const { useLazySignedUrlQuery, useConfirmationMutation } = api;
