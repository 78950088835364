import { createApi } from "@reduxjs/toolkit/query/react";
import { transform as transformPod } from "api/dataTransformations/PodTransformation";
import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

import { dynamicBaseQuery } from "api";

interface GetPodRequest {
  id: string;
}

interface GetPodsRequest {
  limit?: number;
  offset?: number;
  status?: "scheduled" | "started" | "ended";
}

export const api = createApi({
  reducerPath: "api/pods",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    getPod: builder.query<Pods.Room, GetPodRequest>({
      query: ({ id }) => ({
        url: `audio_rooms/${id}`,
        method: "GET",
      }),

      transformResponse: ({ audio_room }: { audio_room: Api.AudioRoom }) =>
        transformPod(audio_room),
    }),
    getPods: builder.query<Pods.Room[], GetPodsRequest>({
      query: (params) => {
        const queryString = Object.keys(params)
          .map((key) => `${key}=${(params as any)[key]}`)
          .join("&");

        return {
          url: `audio_rooms/${queryString ? `?${queryString}` : ""}`,
          method: "GET",
        };
      },
      transformResponse: ({ audio_rooms }: { audio_rooms: Api.AudioRoom[] }) =>
        audio_rooms.map((room) => transformPod(room)),
    }),
  }),
});

export const { useGetPodQuery, useGetPodsQuery } = api;
