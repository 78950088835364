import React, { useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

const useReadMore = (target: React.RefObject<HTMLElement> | null) => {
  const [readMore, setReadMore] = useState<boolean>(false);

  useResizeObserver(
    target,
    ({
      target: { scrollHeight },
      contentRect: { height },
    }: {
      target: { scrollHeight: number };
      contentRect: { height: number };
    }) => {
      setReadMore(scrollHeight > height);
    }
  );

  return readMore;
};

export default useReadMore;
