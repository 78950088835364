/* eslint-disable no-param-reassign */
import {
    createSelector,
    createSlice,
    PayloadAction,
    createAction,
} from "@reduxjs/toolkit";

import { MeProps } from "types/Me";

import { api as apiMe } from "api/me";
import { api as apiMeChildren } from "api/me/children";
import { api as apiMeRegistration } from "api/me/registration";
import { api as apiSession } from "api/session";
import { api as apiPosts } from "api/posts";

import type { Geolocation } from "types/Geolocation";

import {
    ChildMenopause,
    ChildPregnancy,
    ChildTTC,
    Experience,
} from "types/Child";
import { PURGE } from "redux-persist";
import { logout } from "reducers/SessionReducer";
import type { RootState } from "../../store/Store";
import { AssumedProfile, ExperimentStatus, MeState } from "./types";

export const actionLogout = createAction("me/logout");



export const initialState: MeState = {
    me: undefined,
    assumed: {},
    geolocation: undefined,
    blockedPostUids: [],
    experiments: {},
    experimentOverrides: {},
    offerPageUrl: null,
};

export const MeSlice = createSlice({
    name: "me",
    initialState,
    reducers: {
        assume: (state, action: PayloadAction<AssumedProfile>) => ({
            ...state,
            assumed: { ...state.assumed, ...action.payload },
        }),
        setMe: (state, action: PayloadAction<MeProps>) => ({
            ...state,
            me: action.payload,
        }),
        setGeolocation: (state, action: PayloadAction<Geolocation>) => ({
            ...state,
            geolocation: action.payload,
        }),
        "session/logout": () => initialState,
        setExperimentStatus: (
            state,
            {
                payload: { key, status },
            }: PayloadAction<{ key: string; status: ExperimentStatus }>
        ) => {
            state.experimentOverrides[key] = status;
        },

        blockPost: (state, action: PayloadAction<string>) => ({
            ...state,
            blockedPostUids: [...state.blockedPostUids, action.payload],
        }),
        setOfferPageUrl: (state, action: PayloadAction<string>) => {
            state.offerPageUrl = action.payload;
        },
        clearOfferPageUrl: (state) => {
            state.offerPageUrl = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState);

        builder.addMatcher(
            apiMeChildren.endpoints.createChild.matchFulfilled,
            (state, { payload }) => {
                if (state.me) {
                    (state.me.children ||= [])?.push(payload.child);
                }
            }
        );

        builder.addMatcher(
            (action) =>
                apiMe.endpoints.getMe.matchFulfilled(action) ||
                apiMe.endpoints.updateMe.matchFulfilled(action) ||
                apiSession.endpoints.createSession.matchFulfilled(action) ||
                apiMeRegistration.endpoints.getRegistration.matchFulfilled(
                    action
                ),
            (state, { payload: { me, experiments } }) => {
                state.me = me;
                if (experiments) {
                    state.experiments = experiments;
                }
            }
        );

        builder.addMatcher(
            apiMeChildren.endpoints.deleteChild.matchFulfilled,
            (state, payload) => {
                const { id } = payload.meta.arg.originalArgs;
                let children = state.me?.children || [];

                children = children.filter(
                    (child: { id: number }) => child.id !== id
                );

                state.me = {
                    ...(state.me as MeProps),
                    children,
                };
            }
        );

        builder.addMatcher(
            apiMeChildren.endpoints.updateChild.matchFulfilled,
            (state, { payload }) => {
                const children = [...(state.me?.children || [])];
                const childIndex = children.findIndex(
                    (child) => child.id === payload.child.id
                );

                children[childIndex] = payload.child;

                state.me = {
                    ...(state.me as MeProps),
                    children,
                };
            }
        );

        builder.addMatcher(
            (action) => action.type === PURGE,
            () => initialState
        );
    },
});

export const { assume, setMe, setGeolocation, setExperimentStatus, blockPost, setOfferPageUrl, clearOfferPageUrl } =
    MeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectChildren = (state: RootState) =>
    state.me?.me?.children || [];

export const selectExistingTTC = (state: RootState) => {
    const children = state.me?.me?.children;
    if (!children) return undefined;

    return (children as ChildTTC[]).find((child) => child.gender === "TTC");
};

export const selectExistingPregnancy = (state: RootState) => {
    const children = state.me?.me?.children;
    if (!children) return undefined;

    return (children as ChildPregnancy[]).find((child) => child.gender === "P");
};

export const selectExistingMenopause = (state: RootState) => {
    const children = state.me?.me?.children;
    if (!children) return undefined;

    return (children as ChildMenopause[]).find(
        (child) => child.gender === "MP"
    );
};

export const selectBasicProfile = ({ me: { me, assumed } }: RootState) => ({
    firstName: me?.firstName || assumed.firstName,
    email: me?.email || assumed.email,
    dateOfBirth: me?.dateOfBirth || assumed.dateOfBirth,
});

const selectChildren2 = (state: any) => state.me?.me?.children || [];
const selectChildrenId = (state: any, id: number) => id;
const selectChildrenLifeStage = (state: any, lifestage: string) => lifestage;

export const selectChildrenById = createSelector(
    [selectChildren2, selectChildrenId],
    (children, id) => children.find((child: any) => child.id === id)
);

export const selectChildByLifestage = createSelector(
    [selectChildren2, selectChildrenLifeStage],
    (children, lifestage) =>
        children.find((child: any) => child.gender === lifestage)
);

export const selectChildByLifestageAndExperience = createSelector(
    [
        selectChildren2,
        (state: any, lifestage: string, experience: string) => [
            lifestage,
            experience,
        ],
    ],
    (children, [lifestage, experience]) =>
        children.find(
            (child: any) =>
                child.gender === lifestage &&
                child.experiences
                    .map((e: Experience) => e.code)
                    .includes(experience)
        )
);

export const selectFirstName = (state: RootState) => state.me?.me?.firstName;

export const selectAvatarUrl = (state: RootState) => state.me?.me?.avatarUrl;

export const selectUserUid = (state: RootState) => state.me?.me?.uid;

export const selectGeolocation = (state: RootState) => state.me?.geolocation;

export const selectTagIds = (state: RootState) =>
    state.me?.me?.tags.map(({ id }: any) => id);

export const selectBlockedPostUids = (state: RootState) =>
    state.me.blockedPostUids;

export const selectOfferPageUrl = (state: RootState) =>
    state.me.offerPageUrl;

export const selectExperimentStatus = (
    state: RootState,
    key: string
): ExperimentStatus => {
    const combinedExperiments = {
        ...state.me.experiments,
        ...state.me.experimentOverrides,
    };
    return combinedExperiments[key] || ExperimentStatus.Control;
};

export const { reducer } = MeSlice;

///WHAT IS GOING ON HERE PLEASE REVIEW
