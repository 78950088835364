import { Mention } from "types/Mention";
import { Api } from "types/api/mention";

export function transform(apiMention: Api.Mention): Mention {
  return {
    anchor: apiMention.anchor,
    uid: apiMention.user_uid
  };
}

export default transform;
