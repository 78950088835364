import { IconProps } from "../../types";

const InfoIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9732 9.3281C11.2835 9.3281 10.7143 8.78569 10.7143 8.09596C10.7143 7.40622 11.2835 6.85712 11.9732 6.85712C12.663 6.85712 13.2322 7.40622 13.2322 8.09596C13.2322 8.78569 12.663 9.3281 11.9732 9.3281ZM10.7813 17.779V10.3259H13.1585V17.779H10.7813Z"
            fill={strokeColor}
        />
    </svg>
);

export default InfoIcon;
