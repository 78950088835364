import React from "react";
import classNames from "classnames";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "../Button";
import styles from "./OutlineButton.module.scss";

export interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
}

function OutlineButton({
  children,
  onClick,
  className,
  loading,
  disabled,
  testId
}: Props): JSX.Element {
  return (
    <Button
      className={classNames(styles.wrapper, className)}
      disabled={disabled}
      onClick={onClick}
      testId={testId}
    >
      {loading ? <BeatLoader loading color="#ea515a" /> : children}
    </Button>
  );
}

export default OutlineButton;
