import { IconProps } from "../../types";

const BabyIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse
            cx="11.9321"
            cy="7.27128"
            rx="2.19497"
            ry="2.12846"
            stroke={strokeColor}
            strokeWidth="1.2"
        />
        <path
            d="M7.27462 8.01207C7.11439 5.29301 9.27624 3.00002 12 3.00002C14.7238 3.00002 16.8856 5.29302 16.7254 8.01207L16.193 17.0469C16.0622 19.2668 14.2238 21 12 21C9.77624 21 7.93784 19.2668 7.80702 17.0469L7.27462 8.01207Z"
            stroke={strokeColor}
            strokeWidth="1.2"
        />
        <path
            d="M16.539 9.24585L7.68073 14.7803"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linecap="round"
        />
    </svg>
);

export default BabyIcon;
