import { IconProps } from "../../types";

const CloseIcon = ({ strokeColor, size = 24, fill = "black" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.7012 7.93693C19.1947 7.43893 19.1947 6.63151 18.7012 6.13351C18.2077 5.63551 17.4076 5.63551 16.9142 6.13351L12.4768 10.6117L8.04577 6.13999C7.5523 5.64199 6.75222 5.64199 6.25875 6.13999C5.76528 6.63799 5.76528 7.44541 6.25875 7.94341L10.6897 12.4151L6.13008 17.0166C5.63661 17.5146 5.63661 18.322 6.13008 18.82C6.62355 19.318 7.42363 19.318 7.9171 18.82L12.4768 14.2185L17.0429 18.8265C17.5363 19.3245 18.3364 19.3245 18.8299 18.8265C19.3233 18.3285 19.3233 17.5211 18.8299 17.0231L14.2638 12.4151L18.7012 7.93693Z"
            fill={strokeColor}
        />
    </svg>
);

export default CloseIcon;
