import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import ActionButton from "components/Buttons/ActionButton";
import Avatar from "components/Avatar";

import type { Pods } from "types/Pods";

import imageMicrophone from "./assets/microphone.svg";
import imageWaveform from "./assets/waveform.svg";

import styles from "./PodActiveCard.module.scss";

export interface Props {
  room: Pods.Room;
}

function PodActiveCard({ room }: Props): JSX.Element {
  const { t } = useTranslation("ComponentsCardsPodActiveCard");
  const navigate = useNavigate();

  const hostNames = useMemo(
    () =>
      room.featuredParticipants
        .filter((participant) =>
          ["host", "cohost"].includes(participant.status)
        )
        .slice(0, 3)
        .map((host) => host.firstName),
    [room]
  );

  const handleJoinClick = useCallback(() => {
    navigate(`/pods/${room.id}`);
  }, []);

  return (
    <Link to={`/pods/${room.id}`} className={styles.wrapper}>
      <header>
        <h3>{room.title}</h3>
        {hostNames && (
          <p className={styles.hosts}>
            <span>{t("hosts", { count: hostNames.length })}</span>{" "}
            {hostNames.join(", ")}
          </p>
        )}
        <div className={styles.userCount}>{room.participantCount}</div>
      </header>
      <p className={styles.description}>{room.description}</p>

      <footer>
        <div className={styles.avatarsContainer}>
          {room.featuredParticipants
            .slice(0, 2)
            .map(({ userUid, avatarUrl, firstName }) => (
              <Avatar
                key={userUid}
                avatarUrl={avatarUrl}
                firstName={firstName}
                className={styles.avatar}
              />
            ))}
        </div>

        <img
          src={imageMicrophone}
          alt={t("microphoneAltText")}
          className={classNames(
            styles.footerImage,
            styles.footerImageMicrophone
          )}
        />
        <img
          src={imageWaveform}
          alt={t("waveformAltText")}
          className={classNames(
            styles.footerImage,
            styles.footerImageMicrophone
          )}
        />

        <ActionButton className={styles.button} onClick={handleJoinClick}>
          {t("join")}
        </ActionButton>
      </footer>
    </Link>
  );
}

PodActiveCard.Mock = function mock() {
  return (
    <div className={classNames(styles.wrapper, styles.wrapperMock)}>
      <header>
        <h3>
          <Skeleton />
        </h3>

        <p className={styles.hosts}>
          <Skeleton />
        </p>
      </header>
      <p className={styles.description}>
        <Skeleton count={3} />
      </p>

      <div>
        <Skeleton height={70} />
      </div>
    </div>
  );
};

export default PodActiveCard;
