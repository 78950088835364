import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FacebookConversionsEventRequestProps } from "./types";
import { FacebookConversionsSchema } from "@/schema/ConversionFacebookAPI";
import { getCookieValue } from "@/helpers/getCookieValue";
import { dynamicBaseQuery } from "api";
import * as Sentry from "@sentry/browser";

export const facebookApi = createApi({
    reducerPath: "api/facebookConversionsEvent",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        createFbEvent: builder.mutation<
            void,
            FacebookConversionsEventRequestProps
        >({
            query: (body: FacebookConversionsEventRequestProps) => {
                const fbpValue = getCookieValue("_fbp");
                const fbcValue = getCookieValue("_fbc");
                const eventData = {
                    event_name: body.event_name,
                    action_source: "website",
                    fbc: fbcValue || "",
                    fbp: fbpValue,
                    custom_data: body.custom_data
                        ? {
                              currency: body.custom_data?.currency,
                              value: body.custom_data?.value,
                          }
                        : undefined,
                    event_source_url: body.event_source_url,
                };

                const result = FacebookConversionsSchema.safeParse(eventData);

                if (!result.success) {
                    throw new Error("Validation Facebook failed!");
                }

                return {
                    url: "/me/facebook_conversions_events",
                    method: "POST",
                    body: result.data,
                };
            },
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "FacebookConversionsApçi",
                    error,
                });

                return error;
            },
        }),
    }),
});

export const { useCreateFbEventMutation } = facebookApi;
