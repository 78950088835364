/* eslint-disable no-param-reassign */
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

import { api as commentsApi } from "api/CommentsApi";
import type { RootState } from "store/Store";

import { Comment } from "types/Comment";

export interface CommentEditorState {
  initialComment: Partial<Comment>;
}

export const actionActAs = createAction<string>("session/actAs");

export const initialState: CommentEditorState = {
  initialComment: {}
};

export const CommentEditorSlice = createSlice({
  name: "commentEditor",
  initialState,
  reducers: {
    setInitialComment: (
      state,
      { payload }: PayloadAction<Partial<Comment>>
    ) => {
      state.initialComment = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      commentsApi.endpoints.createComment.matchFulfilled,
      (state) => {
        state.initialComment = {};
      }
    );
  }
});

export const selectInitialComment = ({
  commentEditor: { initialComment }
}: RootState) => initialComment;

export const { setInitialComment } = CommentEditorSlice.actions;

export const { reducer } = CommentEditorSlice;
