/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAction,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { ConnectionState } from "agora-rtc-sdk-ng";
import type { RootState } from "store/Store";

import { api as apiPodReactions } from "api/pods/reactions";

import type { Pods } from "types/Pods";
import { logout } from "reducers/SessionReducer";

type PodConnectionStatus = ConnectionState;

type ParticipantVolumeLevelMap = {
  [key: string]: number;
};

interface PodsState {
  roomId?: string;
  interactionRequired?: boolean;
  status: PodConnectionStatus;
  participantVolumeLevel: ParticipantVolumeLevelMap;
  participantMuteStatus: { [key: string]: boolean };
  showWelcome: boolean;
  reactionCursor: string | undefined;
  reactionQueue: Pods.Reaction[];
  roomJoinedAt?: string;
}

export const actionActAs = createAction<string>("session/actAs");

export const initialState: PodsState = {
  status: "DISCONNECTED",
  participantVolumeLevel: {},
  participantMuteStatus: {},
  showWelcome: true,
  reactionCursor: undefined,
  reactionQueue: [],
  roomJoinedAt: undefined,
};

export const ActivePodSlice = createSlice({
  name: "pods",
  initialState,
  reducers: {
    clear: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },

    setInteractionRequired: (state, action: PayloadAction<boolean>) => {
      state.interactionRequired = action.payload;
    },

    setRoomId: (state, action: PayloadAction<string | undefined>) => {
      state.roomId = action.payload;
      state.reactionCursor = undefined;
      state.participantMuteStatus = {};
      state.participantVolumeLevel = {};
      state.roomJoinedAt = new Date().toISOString();
    },

    setStatus: (state, action: PayloadAction<PodConnectionStatus>) => {
      state.status = action.payload;
    },

    setVolumeLevels: (
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.participantVolumeLevel = {
        ...state.participantVolumeLevel,
        ...action.payload,
      };
    },

    setMuteStatus: (
      state,
      {
        payload: { uid, muted },
      }: PayloadAction<{ uid: number; muted: boolean }>
    ) => {
      state.participantMuteStatus = {
        ...state.participantMuteStatus,
        [uid]: muted,
      };
    },

    setShowWelcome: (state, action: PayloadAction<boolean>) => {
      state.showWelcome = action.payload;
    },

    deleteReactionQueueByUserUid: (state, action: PayloadAction<string>) => {
      state.reactionQueue = state.reactionQueue.filter(
        (reaction) => reaction.userUid !== action.payload
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addMatcher(
      apiPodReactions.endpoints.getReactions.matchFulfilled,

      (state, { payload: { cursor, reactions } }) => {
        const lastReaction = reactions[reactions.length - 1];
        const lastReactionAt: Date = lastReaction
          ? new Date(lastReaction.createdAt)
          : new Date(state.roomJoinedAt!);

        if (
          state.reactionCursor !== null &&
          lastReactionAt >= new Date(state.roomJoinedAt!)
        ) {
          state.reactionQueue = [...state.reactionQueue, ...reactions];
        } else {
          state.reactionQueue = [];
        }

        if (cursor !== null) {
          state.reactionCursor = cursor;
        }
      }
    );
  },
});

export const selectActivePod = ({
  activePod: { roomId, interactionRequired },
}: RootState): {
  roomId?: string;
  interactionRequired?: boolean;
} => ({ roomId, interactionRequired });

export const {
  clear,
  setInteractionRequired,
  setRoomId,
  setStatus,
  setVolumeLevels,
  setMuteStatus,
  setShowWelcome,
  deleteReactionQueueByUserUid,
} = ActivePodSlice.actions;

const selectParticipantVolumeLevels = (state: RootState) =>
  state.activePod.participantVolumeLevel;

const selectParticipantMuteStatuses = (state: RootState) =>
  state.activePod.participantMuteStatus;

const selectAgoraUid = (state: any, agoraUid: number) => agoraUid;

export const selectParticipantVolumeLevelsByAgoraId = createSelector(
  [selectParticipantVolumeLevels, selectAgoraUid],
  (volumeLevels, agoraUid) => Math.floor(volumeLevels[agoraUid])
);

export const selectParticipantMuteStatusByAgoraId = createSelector(
  [selectParticipantMuteStatuses, selectAgoraUid],
  (muteStatuses, agoraUid) =>
    muteStatuses[agoraUid] === undefined ? true : muteStatuses[agoraUid]
);

// export const selectVolumeLevel = createSelector(
//   [
//     (state: any) => state.activePod.participantVolumeLevel,
//     (participantVolumeLevel: ParticipantVolumeLevelMap, agoraUid: number) =>
//       agoraUid,
//   ],
//   (participantVolumeLevel, agoraUid) => participantVolumeLevel[agoraUid]
// );

export const selectVolumeLevel = (state: RootState, agoraUid: number) =>
  state.activePod.participantVolumeLevel[agoraUid];

export const selectReactionsCursor = (state: RootState) =>
  state.activePod.reactionCursor;

export const selectShowWelcome = ({
  activePod: { showWelcome, interactionRequired },
}: RootState) => interactionRequired || showWelcome;

const selectUserUid = (state: any, userUid: string) => userUid;

const selectReactionQueue = (state: RootState) => state.activePod.reactionQueue;

export const selectNextReactionByUserUid = createSelector(
  [selectReactionQueue, selectUserUid],
  (reactionQueue, userUid) =>
    reactionQueue.find((reaction) => reaction.userUid === userUid)
);

export const { reducer } = ActivePodSlice;
