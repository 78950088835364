import { IconProps } from "../../types";

const FeedSettingsIcon = ({
    strokeColor,
    size = 24,
    fill = "none",
}: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.10012 19.3167C4.10012 17.9125 4.50699 16.89 5.1372 16.223C5.76481 15.5588 6.67237 15.1848 7.80158 15.1848C8.93079 15.1848 9.83828 15.5588 10.4658 16.223C11.096 16.8899 11.5028 17.9125 11.5028 19.3167C11.5028 19.4592 11.3872 19.5748 11.2447 19.5748H4.35824C4.21569 19.5748 4.10012 19.4592 4.10012 19.3167Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
        />
        <path
            d="M14.4294 19.3167C14.4294 17.9125 14.8363 16.89 15.4665 16.223C16.0941 15.5588 17.0017 15.1848 18.1309 15.1848C19.2601 15.1848 20.1676 15.5588 20.7951 16.223C21.4253 16.8899 21.8321 17.9125 21.8321 19.3167C21.8321 19.4592 21.7165 19.5748 21.574 19.5748H14.6875C14.545 19.5748 14.4294 19.4592 14.4294 19.3167Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.80146 15.0127C9.22535 15.0127 10.3838 13.8546 10.3838 12.4304C10.3838 11.0065 9.22535 9.84808 7.80146 9.84808C6.37756 9.84808 5.21913 11.0065 5.21913 12.4304C5.21913 13.8546 6.37756 15.0127 7.80146 15.0127Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.1308 15.0127C19.5546 15.0127 20.7131 13.8546 20.7131 12.4304C20.7131 11.0065 19.5546 9.84808 18.1308 9.84808C16.7069 9.84808 15.5484 11.0065 15.5484 12.4304C15.5484 13.8546 16.7069 15.0127 18.1308 15.0127Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.9661 8.98728C14.39 8.98728 15.5484 7.82911 15.5484 6.40496C15.5484 4.98106 14.39 3.82263 12.9661 3.82263C11.5422 3.82263 10.3838 4.98106 10.3838 6.40496C10.3838 7.82911 11.5422 8.98728 12.9661 8.98728Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default FeedSettingsIcon;
