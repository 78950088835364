import { createApi } from "@reduxjs/toolkit/query/react";
import { transform as transformPodParticipant } from "api/dataTransformations/PodTransformation/Participant";
import { transform as transformPod } from "api/dataTransformations/PodTransformation";
import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

import { dynamicBaseQuery } from "api";

interface GetParticipantRequest {
  roomId: string;
}

interface CreateParticipantResponse {
  participant: Pods.Participant;
  room: Pods.Room;
  accessToken: string;
}

interface CreateParticipantRequest {
  roomId: string;
  listeningOnly?: boolean;
}

export const api = createApi({
  reducerPath: "api/pods/participants",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    getParticipants: builder.query<Pods.Participant[], GetParticipantRequest>({
      query: ({ roomId }) => ({
        url: `audio_rooms/${roomId}/participants`,
        method: "GET",
      }),

      transformResponse: (response: {
        audio_room_participants: Api.AudioRoomParticipant[];
      }) => {
        const { audio_room_participants: audioRoomParticipants } =
          response || {};

        return (
          audioRoomParticipants &&
          audioRoomParticipants.map(transformPodParticipant)
        );
      },
    }),

    createParticipant: builder.mutation<
      CreateParticipantResponse,
      CreateParticipantRequest
    >({
      query: ({ roomId, listeningOnly }) => ({
        url: `audio_rooms/${roomId}/participants`,
        method: "POST",
        body: {
          listening_only: listeningOnly,
        },
      }),

      transformResponse: ({
        audio_room_participant,
        audio_room,
        access_token,
      }: {
        audio_room: Api.AudioRoom;
        audio_room_participant: Api.AudioRoomParticipant;
        access_token: string;
      }) => ({
        room: transformPod(audio_room),
        participant: transformPodParticipant(audio_room_participant),
        accessToken: access_token,
      }),
    }),
  }),
});

export const { useGetParticipantsQuery, useCreateParticipantMutation } = api;
