import { IconProps } from "../../types";

const DiscoverySettingsIcon = ({
    strokeColor,
    size = 24,
    fill = "none",
}: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.9947 15.6155C17.2097 16.4166 16.7292 17.2486 15.9282 17.4635L8.60482 19.4254C7.80302 19.6404 6.97179 19.1599 6.75758 18.3589L3.96597 7.94142C3.75099 7.13961 4.23147 6.30839 5.03253 6.09342L12.3559 4.13077C13.1569 3.91655 13.9889 4.39628 14.2031 5.19808L16.9947 15.6155Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.1827 8.02408L14.9968 6.36616C14.8324 6.32241 14.6672 6.31412 14.505 6.32543L16.9949 15.6152C17.2091 16.417 16.7294 17.2482 15.9283 17.4632L12.4669 18.391L18.0071 19.8747C18.8089 20.0896 19.6402 19.6099 19.8551 18.8081L22.2492 9.87133C22.4642 9.07028 21.9837 8.23905 21.1827 8.02408Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default DiscoverySettingsIcon;
