import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import {
    PubStripeCheckoutSessionRequestProps,
    StripeCheckoutSessionResponseProps,
} from "./types";
import {
    StripeCheckoutResponseSchema,
    PubStripeCheckoutRequestBodySchema,
} from "@/schema/StripeApi";

export const stripePublicApi = createApi({
    reducerPath: "api/stripePublic",
    baseQuery: dynamicBaseQuery,
    tagTypes: ["StripePublicSession"],
    endpoints: (builder) => ({
        stripePub: builder.mutation<
            StripeCheckoutSessionResponseProps,
            PubStripeCheckoutSessionRequestProps
        >({
            query: ({
                userUid,
                priceId,
                offerCode,
                productClientId,
                cancelPath,
                successPath,
            }) => {
                const parsedBody = PubStripeCheckoutRequestBodySchema.safeParse(
                    {
                        user_uid: userUid,
                        product_client_id: productClientId,
                        tier_id: priceId,
                        offer_code: offerCode,
                        cancel_path: cancelPath,
                        success_path: successPath,
                    }
                );

                if (!parsedBody.success) {
                    throw new Error(
                        "Invalid Public Stripe request body format"
                    );
                }

                return {
                    url: `/web/stripe/create_checkout_session/`,
                    method: "POST",
                    body: parsedBody.data,
                };
            },
            transformResponse: (rawData) => {
                const parsedData =
                    StripeCheckoutResponseSchema.safeParse(rawData);

                if (!parsedData.success) {
                    throw new Error("Invalid Stripe response");
                }

                return {
                    sessionUrl: parsedData.data.session_url,
                };
            },
        }),
    }),
});

export const { useStripePubMutation } = stripePublicApi;
