import { useCustomerPortalSessionMutation } from "@/api/stripe/StripeApi";
import { persistor } from "@/store/Store";
import { useCallback } from "react";

export const generateMenuItems = ({
    stripeAccess,
}: {
    stripeAccess: boolean;
}) => {
    const [createCustomerPortalSession] = useCustomerPortalSessionMutation();

    const handleLogout = useCallback(() => {
        persistor.pause();
        persistor.flush().then(() => persistor.purge());
    }, [persistor]);

    const manageSubscriptionHandler = useCallback(async () => {
        try {
            const response = await createCustomerPortalSession({
                sessionUrl: "/",
            }).unwrap();

            window.location.href = response.sessionUrl;
        } catch (err) {
            console.error("Error creating customer portal session:", err);
        }
    }, [createCustomerPortalSession]);

    return [
        {
            key: "manageSubscriptions",
            title: "manage_subscription",
            icon: "subscription",
            onClick: manageSubscriptionHandler,
            show: stripeAccess,
        },
        {
            key: "logout",
            title: "logout",
            icon: "logout",
            onClick: handleLogout,
            show: true,
        },
    ];
};
