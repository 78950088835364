import React from "react";
import classNames from "classnames";
import styles from "./Card.module.scss";

export interface Props {
  children: React.ReactNode;
  className?: string;
  background?: string;
}

function Card({ children, className, background }: Props): JSX.Element {
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.pink]: background === "pink",
      })}
    >
      {children}
    </div>
  );
}

export default Card;
