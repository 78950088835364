import { z } from "zod";

import type { Author } from "types/Author";
import { UserBadge } from "types/UserBadge";
import { AuthorIncognito } from "types/Author";
import { SchemaProfileBadge } from "./ProfileBadge";

export const SchemaAuthor = z
  .object({
    uid: z.string(),
    name: z.string(),
    avatar_url: z.string(),
    badges: z.array(z.string()),
    profile_badges: z.array(SchemaProfileBadge).optional(),
    liveness: z
      .object({
        online: z.boolean(),
        last_seen_at: z.string()
      })
      .optional(),
    location: z
      .object({
        name: z.string()
      })
      .optional(),
    can_be_tipped: z.boolean().optional(),
    connection_state: z.enum(["connected", "waved", "none"]).optional()
  })
  .transform(
    (data): Author => ({
      uid: data.uid,
      name: data.name,
      avatarUrl: data.avatar_url,
      location: data.location,
      badges: data.badges as UserBadge[],
      profileBadges: data.profile_badges,
      liveness: data.liveness
        ? {
            online: data.liveness.online,
            lastSeenAt: data.liveness.last_seen_at
          }
        : undefined,
      connectionState: data.connection_state,
      permissions: {
        canBeTipped: data.can_be_tipped
      }
    })
  );

export const SchemaAuthorIncognito = z
  .object({
    name: z.string(),
    avatar_url: z.string()
  })
  .transform(
    (data): AuthorIncognito => ({
      name: data.name,
      avatarUrl: data.avatar_url
    })
  );

export const SchemaAuthorOrIncognito = z.union([
  SchemaAuthor,
  SchemaAuthorIncognito
]);

export default SchemaAuthor;
