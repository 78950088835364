import { IconProps } from "../../types";

const EditIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.77687 20.6143C5.62912 20.4435 6.73839 20.2208 7.20579 20.127C7.4242 20.0832 7.62436 19.9758 7.78189 19.8179L20.2412 7.31931C20.4527 7.10741 20.5714 6.81977 20.5714 6.51978C20.5714 6.21979 20.4523 5.93214 20.2412 5.72025C19.6936 5.17112 18.8452 4.31996 18.2978 3.77069C18.0865 3.55891 17.7998 3.43939 17.5007 3.43939C17.2017 3.43939 16.9149 3.55853 16.7037 3.77069C14.4267 6.05494 5.55047 14.9592 4.24444 16.2693C4.08705 16.4273 3.97998 16.6281 3.93633 16.8472C3.84285 17.3161 3.62079 18.4289 3.45057 19.2838C3.37646 19.6546 3.49221 20.038 3.75868 20.3052C4.02502 20.5725 4.40725 20.6886 4.77687 20.6143ZM4.57997 17.4616L6.59335 19.4813L4.62967 19.8752C4.50637 19.9001 4.37905 19.8612 4.28997 19.7722C4.20127 19.6829 4.16252 19.5552 4.18731 19.4315L4.57997 17.4616ZM4.88066 16.6972L7.35538 19.1797L17.3453 9.15825L14.8704 6.67559L4.88066 16.6972ZM17.8765 8.62528L15.4017 6.14261L17.235 4.30349C17.3053 4.23307 17.4012 4.19306 17.5007 4.19306C17.6002 4.19306 17.6961 4.23307 17.7664 4.30349L19.7099 6.25305C19.7801 6.3236 19.82 6.41977 19.82 6.5196C19.82 6.61943 19.7801 6.7156 19.7099 6.78615L17.8765 8.62528Z"
            fill={fill}
            stroke={strokeColor}
            strokeWidth="0.472917"
        />
    </svg>
);

export default EditIcon;
