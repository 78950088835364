import {  useMemo } from "react";
import { Outlet, useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import ImageNextGen from "components/ImageNextGen";


import imageArrow from "./assets/arrow.svg";

import images from "./assets";
import imageLogo from "./assets/logo.svg";
import styles from "./Error503.module.scss";

export interface Props {}

const FEATURED_IMAGE_MAP: { [key: string]: any } = {
  default: images.featureImageRegistration
};

function Error503(): JSX.Element {

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const imageManifest = useMemo(() => {
    let path = pathname;

    return FEATURED_IMAGE_MAP[path] || FEATURED_IMAGE_MAP.default;
  }, [pathname]);

  const imageDefault = useMemo(
    () => imageManifest.medium.webp["1x"],
    [imageManifest]
  );

  return (
    <div className={styles.wrapper}>
      <img src={imageLogo} alt="Peanut" className={styles.logo} />
      <div className={styles.container}>
        <div className={styles.featureImage}>
          <ImageNextGen
            manifest={imageManifest}
            src={imageDefault}
            alt="Peanut"
            className={styles.image}
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.content}>

            <main>
            <p className={styles.messageTitle}>We're having a glow up.</p>
            <p className={styles.messageSubtitle}>It should only take a couple of hours.<br/>Please bear with us while we make the finishing touches. 🪄</p>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error503;
