import Container from "@/DesignSystem/components/Container";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks";
import { purchase } from "reducers/EventsReducer";

import {
    selectUserUID,
    selectSelectedTier,
    selectCurrency,
} from "@/reducers/PublicSubscriptionReducer";
import QRCodeComposition from "@/components/_compositions/QRCode";
import genSubscriptionGA from "@/helpers/genSubscriptionGA";
import { useCreateFbEventMutation } from "@/api/me/facebookConversion";

const SuccessPage = ({
    eventTag,
    translation,
}: {
    eventTag?: string;
    translation: string;
}) => {
    const { t } = useTranslation(translation);
    const dispatch = useAppDispatch();
    const userUID = useSelector(selectUserUID);
    const selectedTier = useSelector(selectSelectedTier);
    const currency = useSelector(selectCurrency);
    const [createFbEvent] = useCreateFbEventMutation();

    if (eventTag && !!userUID) {
        const analytics = genSubscriptionGA(selectedTier, currency!);
        dispatch(
            purchase({
                ...analytics,
                transactionId: userUID,
            })
        );
        createFbEvent({
            event_name: "purchase",
            event_source_url: "/subscription/success",
        });
    }
    return (
        <div className={styles.background}>
            <Container>
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <div>
                            <h3>{t("successPeanutPlus.heading")}</h3>
                            <h3>{t("successPeanutPlus.title")}</h3>
                        </div>
                        <p>{t("successPeanutPlus.description")}</p>
                        <QRCodeComposition />
                    </div>
                    <div className={styles.img} />
                </div>
            </Container>
        </div>
    );
};

export default SuccessPage;
