import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import useOnClickOutside from "hooks/useOnClickOutside";

import useKeyPress from "hooks/useKeyPress";
import useCopyToClipboard from "hooks/useCopyToClipboard";

import styles from "./ShareMenu.module.scss";

interface Props {
  onClose: () => void;
  shareUrl: string;
  title: string;
  visible: boolean;
}

function ShareMenu({ onClose, shareUrl, title, visible }: Props) {
  const escapePressed = useKeyPress("Escape");
  const [copyStatus, copyToClipboard] = useCopyToClipboard();
  const [displayCopied, setDisplayCopied] = useState(false);
  const ref = React.useRef(null);

  const linkCopied = useEffect(() => {
    if (copyStatus !== null && !displayCopied) {
      setDisplayCopied(true);

      setTimeout(() => {
        setDisplayCopied(false);
      }, 2000);
    }
  }, [copyStatus]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (escapePressed) {
      handleClose();
    }
  }, [escapePressed]);

  useOnClickOutside(ref, handleClose);

  const handleCopyLink = useCallback(() => {
    copyToClipboard(shareUrl);

    setTimeout(() => {
      handleClose();
    }, 1000);
  }, []);

  const handleTwitterPress = useCallback(() => {
    handleClose();
    window.open(
      `http://twitter.com/share?text=${title}&url=${shareUrl}&hashtags=peanut`,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
  }, []);

  const handleFacebookPress = useCallback(() => {
    handleClose();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?display=popup&u=${shareUrl}`,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=400"
    );
  }, []);

  if (!visible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <ul
      className={classNames({
        [styles.visible]: visible,
        [styles.submenu]: true,
      })}
      ref={ref}
      data-testid="share-menu"
    >
      <li>
        <span>Share</span>
      </li>
      <li>
        <button
          type="button"
          aria-label="Share on Facebook"
          className={styles.facebookLink}
          onClick={handleFacebookPress}
        >
          Facebook
        </button>
      </li>
      <li>
        <button
          type="button"
          aria-label="Share on Twitter"
          className={styles.twitterLink}
          onClick={handleTwitterPress}
        >
          Twitter
        </button>
      </li>
      <li>
        <button
          type="button"
          onClick={handleCopyLink}
          aria-label="Copy a link to Clipboard"
          className={classNames({
            [styles.linkCopied]: linkCopied,
            [styles.copyLink]: true,
          })}
        >
          {displayCopied ? "Copied" : "Copy Link"}
        </button>
      </li>
    </ul>
  );
}

export default ShareMenu;
