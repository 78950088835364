import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import { hasAuthor } from "helpers/typeGuards";
import { SchemaGroup } from "schema/Group";
import { SchemaPost } from "schema/Post";
import { Group } from "types/Group";
import { PostWithGroup } from "types/Post";
import * as Sentry from "@sentry/browser";

export type CategoryPostsGetRequest = {
    id: number;
    after?: string;
};

export type CursorPaging = {
    nextCursor?: string;
};

export type CategoryPostsGetResponse = {
    feed: PostWithGroup[];
    paging: CursorPaging;
};

const parseGroups = (groups: any[]) => {
    const parsedGroups = groups
        .map((group) => {
            let g;
            try {
                g = SchemaGroup.parse(group);
            } catch (error) {
                Sentry.captureException({ source: group, error });
            }
            return g;
        })
        .filter((group) => group !== undefined) as Group[];

    return parsedGroups.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
    }, {} as Record<number, Group>);
};

const parseFeed = (feed: any[], indexedGroups: Record<number, Group>) =>
    feed
        .map((post) => {
            let item: PostWithGroup | undefined;
            try {
                item = SchemaPost.parse(post) as PostWithGroup;
                if (item.groupId) {
                    item.group = indexedGroups[item.groupId];
                }
                return item;
            } catch (error) {
                Sentry.captureException({ source: post, error });
            }
        })
        .filter((item) => item !== undefined) as PostWithGroup[];

export const api = createApi({
    reducerPath: "api/pages/categories/posts",
    baseQuery: dynamicBaseQuery,
    tagTypes: ["FeedItem", "FeedItemsPage", "FeedItemAuthor"],
    endpoints: (builder) => ({
        getPosts: builder.query<
            CategoryPostsGetResponse,
            CategoryPostsGetRequest
        >({
            query: ({ id, after }) => ({
                url: `pages/categories/${id}/posts`,
                params: {
                    after,
                },
            }),
            providesTags: (data) => [
                ...(data?.feed || []).map(({ uid }: { uid: string }) => ({
                    type: "FeedItem" as const,
                    id: uid,
                })),
                ...(data?.feed || []).map((post) => {
                    const authorUid = hasAuthor(post.author)
                        ? post.author.uid
                        : "anon";
                    return {
                        type: "FeedItemAuthor" as const,
                        id: authorUid,
                    };
                }),
            ],

            transformResponse: ({
                data,
                groups,
                paging,
            }: {
                data: any[];
                groups: any[];
                paging: {
                    next_cursor: string;
                };
            }) => {
                const indexedGroups = parseGroups(groups);

                const feed = parseFeed(data, indexedGroups);

                const pagingResult: CursorPaging = {};

                if (paging.next_cursor) {
                    pagingResult.nextCursor = paging.next_cursor;
                }

                return {
                    feed,
                    paging: pagingResult,
                };
            },
            transformErrorResponse: (error) => {
                Sentry.captureException({
                    source: "PublicSubscriptionApi",
                    error,
                });

                return error;
            },
        }),
    }),
});

export const { useGetPostsQuery } = api;
