import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "types/api/tags";
import { dynamicBaseQuery } from "api";
import { Tag } from "types/Tag";
import transfromTag from "api/dataTransformations/TagTransformation";

export type TagsGetRequest = {
  groups?: string[];
};

export const api = createApi({
  reducerPath: "api/tags",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    getTags: builder.query<Tag[], TagsGetRequest>({
      query: ({ groups }) => ({
        url: "tags",
        params: {
          tag_groups: groups?.join(","),
        },
      }),
      transformResponse: ({ tags }: { tags: Api.Tag[] }) =>
        tags.map((tag) => transfromTag(tag)),
    }),
  }),
});

export const { useGetTagsQuery } = api;
