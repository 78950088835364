import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
} from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { setupListeners } from "@reduxjs/toolkit/query";

import { api as CommentsApi } from "api/CommentsApi";
import { api as GeolocationApi } from "api/geolocation";
import { api as MeApi } from "api/me";
import {
    api as MeBlockedUsersApi,
    api as BlockedUsersApi,
} from "api/me/blocked_users";
import { api as MeChildrenApi } from "api/me/children";
import { api as MeGroupsApi } from "api/me/groups";
import { api as MeRegistrationApi } from "api/me/registration";
import { api as MeSubscriptionsApi } from "api/me/subscriptions";
import { api as PublicSubscriptionApi } from "api/publicSubscription";
import { api as MeTags } from "api/me/tags";
import { api as PhotoDetailsApi } from "api/me/photo_details";
import { api as PodsApi } from "api/pods";
import { api as PodsParticipantsApi } from "api/pods/participants";
import { api as PodsReactionsApi } from "api/pods/reactions";
import { api as PostApi } from "api/posts";
import { api as ReactionsApi } from "api/reactions";
import { api as S3UploadApi } from "api/s3Upload";
import { api as SessionApi } from "api/session";
import {
    stripeApi as StripeApi,
    stripePublicApi as StripePublicApi,
} from "@/api/stripe";
import { facebookApi as FacebookApi } from "@/api/me/facebookConversion";
import { api as TagsApi } from "api/tags";
import { api as UserEventsApi } from "api/UserEventsApi";
import { api as UserPhotoApi } from "api/me/user_photos";
import { api as PageCategoriesPostsApi } from "api/Pages/Categories/PostsApi";

import { api as PageGroupsApi } from "api/Pages/GroupsApi";
import { api as PagesPollsApi } from "api/Pages/PollsApi";

import { reducer as ActivePodReducer } from "reducers/ActivePodReducer";
import { reducer as CommentEditorReducer } from "reducers/CommentEditorReducer";
import { reducer as EventsReducer } from "reducers/EventsReducer";
import { reducer as GroupsReducer } from "reducers/GroupsReducer";
import { reducer as MeReducer } from "reducers/MeReducer";
import { reducer as SessionReducer } from "reducers/SessionReducer";
import { reducer as SubscriptionReducer } from "reducers/SubscriptionReducer";
import { reducer as UserEventsReducer } from "reducers/UserEventsReducer";
import { reducer as LayoutReducer } from "reducers/LayoutReducer";
import { reducer as PublicSubscriptionReducer } from "reducers/PublicSubscriptionReducer";
import { reducer as StripeReducer } from "reducers/StripeReducer";
import { reducer as StripePublicReducer } from "reducers/StripePublicReducer";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["session", "publicSubcription"],
};

export const rootReducer = combineReducers({
    [CommentsApi.reducerPath]: CommentsApi.reducer,
    [GeolocationApi.reducerPath]: GeolocationApi.reducer,
    [MeApi.reducerPath]: MeApi.reducer,
    [MeChildrenApi.reducerPath]: MeChildrenApi.reducer,
    [MeGroupsApi.reducerPath]: MeGroupsApi.reducer,
    [MeRegistrationApi.reducerPath]: MeRegistrationApi.reducer,
    [MeSubscriptionsApi.reducerPath]: MeSubscriptionsApi.reducer,
    [PublicSubscriptionApi.reducerPath]: PublicSubscriptionApi.reducer,
    [MeTags.reducerPath]: MeTags.reducer,
    [PhotoDetailsApi.reducerPath]: PhotoDetailsApi.reducer,
    [PodsApi.reducerPath]: PodsApi.reducer,
    [PodsParticipantsApi.reducerPath]: PodsParticipantsApi.reducer,
    [PodsReactionsApi.reducerPath]: PodsReactionsApi.reducer,
    [PostApi.reducerPath]: PostApi.reducer,
    [ReactionsApi.reducerPath]: ReactionsApi.reducer,
    [S3UploadApi.reducerPath]: S3UploadApi.reducer,
    [SessionApi.reducerPath]: SessionApi.reducer,
    [StripeApi.reducerPath]: StripeApi.reducer,
    [FacebookApi.reducerPath]: FacebookApi.reducer,
    [StripePublicApi.reducerPath]: StripePublicApi.reducer,
    [TagsApi.reducerPath]: TagsApi.reducer,
    [UserEventsApi.reducerPath]: UserEventsApi.reducer,
    [UserPhotoApi.reducerPath]: UserPhotoApi.reducer,
    [PageCategoriesPostsApi.reducerPath]: PageCategoriesPostsApi.reducer,
    [PageGroupsApi.reducerPath]: PageGroupsApi.reducer,
    [PagesPollsApi.reducerPath]: PagesPollsApi.reducer,
    [MeBlockedUsersApi.reducerPath]: MeBlockedUsersApi.reducer,
    [BlockedUsersApi.reducerPath]: BlockedUsersApi.reducer,

    activePod: ActivePodReducer,
    commentEditor: CommentEditorReducer,
    events: EventsReducer,
    groups: GroupsReducer,
    me: MeReducer,
    session: SessionReducer,
    subscription: SubscriptionReducer,
    publicSubcription: PublicSubscriptionReducer,
    stripe: StripeReducer,
    stripePublic: StripePublicReducer,
    userEvents: UserEventsReducer,
    layout: LayoutReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const middleware = [
    CommentsApi.middleware,
    GeolocationApi.middleware,
    MeApi.middleware,
    MeChildrenApi.middleware,
    MeGroupsApi.middleware,
    MeRegistrationApi.middleware,
    MeSubscriptionsApi.middleware,
    PublicSubscriptionApi.middleware,
    MeTags.middleware,
    PhotoDetailsApi.middleware,
    PodsApi.middleware,
    PodsParticipantsApi.middleware,
    PodsReactionsApi.middleware,
    PostApi.middleware,
    ReactionsApi.middleware,
    S3UploadApi.middleware,
    SessionApi.middleware,
    StripeApi.middleware,
    FacebookApi.middleware,
    StripePublicApi.middleware,
    TagsApi.middleware,
    UserPhotoApi.middleware,
    PageCategoriesPostsApi.middleware,
    PageGroupsApi.middleware,
    PagesPollsApi.middleware,
    MeBlockedUsersApi.middleware,
    UserEventsApi.middleware,
];
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

// @ts-ignore
if (window.Cypress) {
    // @ts-ignore
    window.store = store;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
