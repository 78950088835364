import classNames from "classnames";
import styles from "./styles.module.scss";
import { ContainerProps } from "@/DesignSystem/components/Container/types";

const Container = ({ children, variant = "medium", debug }: ContainerProps) => {
    return (
        <div
            className={classNames(styles.container, {
                [styles.wide]: variant === "wide",
                [styles.medium]: variant === "medium",
                [styles.narrow]: variant === "narrow",
                [styles.debug]: debug,
            })}
        >
            {children}
        </div>
    );
};

export default Container;
