import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import usePortal from "react-useportal";
import imageClose from "./assets/close.svg";

import styles from "./Modal.module.scss";

export interface Props {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  visible?: boolean;
  inputRef?: React.RefObject<HTMLDivElement>;
}

function Modal({
  children,
  className,
  onClose,
  visible,
  inputRef
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { Portal } = usePortal();

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      onClose?.();
    },
    [onClose]
  );

  if (!visible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Portal>
      <div className={classNames(styles.modalWrapper)}>
        <div className={classNames(styles.container, className)} ref={inputRef}>
          {children}
          {onClose && (
            <button
              type="button"
              onClick={handleClose}
              className={styles.buttonClose}
            >
              <img src={imageClose} alt={t("generic.close")} />
            </button>
          )}
        </div>
      </div>
    </Portal>
  );
}

export default Modal;
