import { z } from "zod";

import { SchemaUserSummary } from "./UserSummary";

const SchemaUserReaction = z.object({
  id: z.number(),
  label: z.string(),
  user: SchemaUserSummary
});

export default SchemaUserReaction;
