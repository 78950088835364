/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from "react";
import AgoraRTC, { ConnectionState, IAgoraRTCClient } from "agora-rtc-sdk-ng";

import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectActivePod,
  setInteractionRequired,
  setVolumeLevels,
  setMuteStatus,
  setStatus,
} from "reducers/ActivePodReducer/ActivePodReducer";
import { useCreateParticipantMutation } from "api/pods/participants";

import AgoraClient from "./AgoraClient";

const APP_ID = import.meta.env.VITE_AGORA_APP_ID!;

function Listener({ children }: { children: JSX.Element }): JSX.Element {
  const activePod = useAppSelector(selectActivePod);
  const [currentRoomId, setCurrentRoomId] = React.useState<string>();
  const dispatch = useAppDispatch();

  const clientRef = useRef<IAgoraRTCClient>();

  const [createParticipant, { data, isLoading }] =
    useCreateParticipantMutation();

  const handleUserInfoUpdated = (uid: number, msg: string) => {
    if (msg === "mute-audio") {
      dispatch(setMuteStatus({ uid, muted: true }));
    } else if (msg === "unmute-audio") {
      dispatch(setMuteStatus({ uid, muted: false }));
    }
  };

  const handleVolumeLevels = (
    rawVolumeLevels: { uid: string; level: number }[]
  ) => {
    const volumeLevels = rawVolumeLevels.reduce(
      (previous, { uid, level }) => ({ ...previous, [uid]: level }),
      {} as { [uid: string]: number }
    );
    dispatch(setVolumeLevels(volumeLevels));
  };

  const handleConnectionStateChange = (curState: ConnectionState) => {
    dispatch(setStatus(curState));
  };
  useEffect(() => {
    if (activePod?.roomId && !isLoading) {
      if (currentRoomId !== activePod) {
        setCurrentRoomId(activePod.roomId);

        if (clientRef.current) {
          clientRef.current.leave();
        }
      }
      createParticipant({ roomId: activePod.roomId!, listeningOnly: true });
      dispatch(setInteractionRequired(false));
    }

    if (!activePod?.roomId && clientRef.current) {
      clientRef.current.leave();
    }
  }, [activePod?.roomId]);

  useEffect(() => {
    if (data) {
      clientRef.current ||= AgoraClient();

      clientRef.current.join(
        APP_ID,
        activePod.roomId!,
        data.accessToken,
        data.participant.agoraId
      );

      // Listen for changes in a users volume
      clientRef.current.enableAudioVolumeIndicator();
      clientRef.current.on("volume-indicator", handleVolumeLevels);

      // Listen for changes in mute / unmute
      clientRef.current.on("user-info-updated", handleUserInfoUpdated);

      clientRef.current.on(
        "connection-state-change",
        handleConnectionStateChange
      );
    }
    return () => {
      clientRef.current?.leave();
    };
  }, [data?.accessToken]);

  AgoraRTC.onAutoplayFailed = () => {
    const updatedPod = {
      ...activePod,
      interactionRequired: true,
    };
    dispatch(setInteractionRequired(true));
  };

  return children;
}

export default Listener;
