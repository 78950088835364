import { IconProps } from "../../types";

const TelegramIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.16145 11.0268C9.69622 9.05112 12.7201 7.74861 14.2331 7.11929C18.5531 5.32248 19.4507 5.01036 20.0358 5.00005C20.1645 4.99778 20.4522 5.02967 20.6386 5.1809C20.7959 5.3086 20.8392 5.4811 20.86 5.60217C20.8807 5.72324 20.9065 5.99904 20.886 6.21454C20.6519 8.67424 19.6389 14.6433 19.1236 17.3982C18.9055 18.5639 18.4762 18.9548 18.0605 18.993C17.1572 19.0761 16.4712 18.396 15.5963 17.8225C14.2272 16.925 13.4537 16.3664 12.1248 15.4906C10.589 14.4785 11.5846 13.9223 12.4599 13.0132C12.6889 12.7753 16.6691 9.15503 16.7461 8.82662C16.7557 8.78554 16.7647 8.63244 16.6737 8.5516C16.5828 8.47075 16.4485 8.4984 16.3516 8.52038C16.2143 8.55155 14.0272 9.99717 9.79021 12.8572C9.16939 13.2835 8.60708 13.4912 8.10327 13.4804C7.54785 13.4684 6.47946 13.1663 5.68521 12.9081C4.71105 12.5915 3.93679 12.4241 4.00421 11.8863C4.03933 11.6062 4.42508 11.3197 5.16145 11.0268Z"
            fill="url(#paint0_linear_1549_2085)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1549_2085"
                x1="12.4468"
                y1="4.99994"
                x2="12.4468"
                y2="18.8961"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AABEE" />
                <stop offset="1" stop-color="#229ED9" />
            </linearGradient>
        </defs>
    </svg>
);

export default TelegramIcon;
