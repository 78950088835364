import { IconProps } from "../../types";

const BetaMobileIcon = ({}: IconProps) => (
    <svg
        width="37"
        height="18"
        viewBox="0 0 37 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="37" height="18" rx="9" fill="#DF898F" />
        <path
            d="M6.37012 12.8769V4.85771H9.06871C10.5358 4.85771 11.7245 5.44849 11.7245 6.98012C11.7245 7.6256 11.3818 8.29295 10.75 8.61022C11.5638 8.92749 12.0779 9.64955 12.0779 10.5466C12.0779 12.0236 11.0177 12.8769 9.34714 12.8769H6.37012ZM8.88667 6.31277H7.93359V8.09603H8.98305C9.64699 8.09603 10.1396 7.86628 10.1396 7.24269C10.1396 6.8379 9.93612 6.31277 8.88667 6.31277ZM9.13297 9.39792H7.93359V11.4219H9.10084C9.79691 11.4219 10.4823 11.0827 10.4823 10.3935C10.4823 9.71519 9.92541 9.39792 9.13297 9.39792Z"
            fill="white"
        />
        <path
            d="M13.5232 12.8769V4.86865H18.7062V6.37841H15.1081V8.23825H17.796V9.74801H15.1081V11.3672H18.8026V12.8769H13.5232Z"
            fill="white"
        />
        <path
            d="M19.3483 6.40029V4.85771H25.3451V6.40029H23.1284V12.8769H21.5542V6.40029H19.3483Z"
            fill="white"
        />
        <path
            d="M24.7738 12.8769L27.8258 4.85771H29.2179L32.2806 12.8769H30.5672L29.9354 11.1046H27.1083L26.4872 12.8769H24.7738ZM29.5071 9.80271L28.5326 7.04576L27.5474 9.80271H29.5071Z"
            fill="white"
        />
    </svg>
);

export default BetaMobileIcon;
