import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import SchemaUserReaction from "schema/UserReaction";
import { UserReaction } from "types/UserReaction";

interface Reaction {
  code: string;
  label: string;
}
type GetReactionsResponse = Reaction[];

type GetPostReactionsRequest = {
  postUid: string;
  after?: string;
};
type GetPostReactionsResponse = {
  count: number;
  cursor: string;
  reactions: UserReaction[];
};

export const api = createApi({
  reducerPath: "api/reactions",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    getReactions: builder.query<GetReactionsResponse, void>({
      query: () => ({
        url: `pages/reactions`,
        method: "GET"
      }),
      transformResponse: (response: { reactions: Reaction[] }) =>
        response.reactions
    }),
    getPostReactions: builder.query<
      GetPostReactionsResponse,
      GetPostReactionsRequest
    >({
      query: ({ postUid, after }) => ({
        url: `pages/posts/${postUid}/reactions`,
        method: "GET",
        params: {
          cursor: after
        }
      }),
      transformResponse: (response: {
        count: number;
        cursor: string;
        reactions: any[];
      }) => ({
        count: response.count,
        cursor: response.cursor,
        reactions: response.reactions.map((r) => SchemaUserReaction.parse(r))
      })
    })
  })
});

export const { useGetReactionsQuery, useGetPostReactionsQuery } = api;
