import { useEffect } from "react";
import { useNavigate } from "react-router";

function useMaintenanceNavigation(error?: any): void {
    const navigate = useNavigate();

    useEffect(() => {
        if (error && "status" in error && error.status === 503) {
            navigate("/503");
        }
    }, [error, navigate]);
}

export default useMaintenanceNavigation;
