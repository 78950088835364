import React, { useCallback } from "react";
import classNames from "classnames";

import { Link, useNavigate } from "react-router-dom";
import styles from "./Card.module.scss";

export interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  href?: string;
  softHref?: string;
  onClick?: () => void;
}

function CardInteraction({
  children,
  href,
  softHref,
  onClick
}: Omit<Props, "className">) {
  const navigate = useNavigate();

  const handleCardClick = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      if (e.target instanceof HTMLButtonElement) return;
      if (
        e.target instanceof HTMLImageElement &&
        (e.target.getAttribute("src") || "").indexOf(".svg") > 0
      )
        return;
      if (softHref) {
        navigate(softHref);
      }
    },
    [softHref]
  );

  // Generic on click actions return a button
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={styles.CardInteractionButton}
        data-testid="card-interaction-button"
      >
        {children}
      </button>
    );
  }

  if (href) {
    // External links use an anchor tag
    if (href.indexOf("http") === 0) {
      return (
        <a
          href={href}
          target="_blank"
          className={styles.CardInteractionLink}
          rel="noreferrer"
          data-testid="card-interaction-external"
        >
          {children}
        </a>
      );
    }
    // Internal links use a react-router-dom Link
    if (href.indexOf("/") === 0) {
      return (
        <Link
          to={href}
          className={styles.CardInteractionLink}
          data-testid="card-interaction-internal"
        >
          {children}
        </Link>
      );
    }
  }

  if (softHref) {
    return (
      <div
        onClick={handleCardClick}
        className={styles.CardInteractionSoftLink}
        onKeyDown={handleCardClick}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }
  return children as JSX.Element;
}

/* A Cards general layout should be:

    <Card>
        <header>
            <h3>Card Title</h3>
            </header>
            <div>

            </div>
            <footer>

            </footer>
    </Card>

 */

function Card({
  children,
  className,
  href,
  onClick,
  softHref,
  style
}: Props): JSX.Element {
  return (
    <CardInteraction onClick={onClick} href={href} softHref={softHref}>
      <div style={style} className={classNames(styles.Card, className)}>
        {children}
      </div>
    </CardInteraction>
  );
}

export default Card;
