import { IconProps } from "../../types";

const DashboardIcon = ({
    strokeColor,
    size = 24,
    fill = "none",
}: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="3.54842"
            y="16.8712"
            width="18.937"
            height="1.20508"
            rx="0.602542"
            fill="current"
        />
        <mask id="path-2-inside-1_1442_1144" fill="white">
            <rect
                x="6.13075"
                y="8.60773"
                width="4.30387"
                height="9.46852"
                rx="0.860775"
            />
        </mask>
        <rect
            x="6.13075"
            y="8.60773"
            width="4.30387"
            height="9.46852"
            rx="0.860775"
            stroke={strokeColor}
            strokeWidth="2.41017"
            mask="url(#path-2-inside-1_1442_1144)"
        />
        <mask id="path-3-inside-2_1442_1144" fill="white">
            <rect
                x="11.2954"
                y="5.16467"
                width="4.30387"
                height="12.9116"
                rx="0.860775"
            />
        </mask>
        <rect
            x="11.2954"
            y="5.16467"
            width="4.30387"
            height="12.9116"
            rx="0.860775"
            stroke={strokeColor}
            strokeWidth="2.41017"
            mask="url(#path-3-inside-2_1442_1144)"
        />
        <mask id="path-4-inside-3_1442_1144" fill="white">
            <rect
                x="16.4601"
                y="11.1901"
                width="4.30387"
                height="6.8862"
                rx="0.860775"
            />
        </mask>
        <rect
            x="16.4601"
            y="11.1901"
            width="4.30387"
            height="6.8862"
            rx="0.860775"
            stroke={strokeColor}
            strokeWidth="2.41017"
            mask="url(#path-4-inside-3_1442_1144)"
        />
    </svg>
);

export default DashboardIcon;
