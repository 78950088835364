import { useGetPodQuery } from "api/pods";
import OutlineButton from "components/Buttons/OutlineButton";
import RoundButton from "components/Buttons/RoundButton";
import { useAppDispatch, useAppSelector } from "hooks";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { selectActivePod, setRoomId } from "reducers/ActivePodReducer";

import { useTranslation } from "react-i18next";

import classNames from "classnames";
import styles from "./ActivePodBar.module.scss";

import imageLeave from "./assets/leave.svg";

function ActivePodBar(): JSX.Element {
  const { roomId } = useAppSelector(selectActivePod);
  const { data } = useGetPodQuery({ id: roomId! });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("PodsActivePodBar");

  const handleLeaveClick = useCallback(() => {
    dispatch(setRoomId());
  }, [dispatch]);

  const handleReturnClick = useCallback(() => {
    navigate(`/pods/${roomId}`);
  }, [navigate, roomId]);

  return (
    <>
      <section className={styles.wrapper}>
        <div>
          <h3>{data?.title}</h3>
          <RoundButton className={styles.exitButton} onClick={handleLeaveClick}>
            <img src={imageLeave} alt={t("leave")} />
          </RoundButton>

          <OutlineButton
            className={classNames(styles.button, styles.buttonDesktop)}
            onClick={handleReturnClick}
          >
            {t("returnToPod")}
          </OutlineButton>

          <RoundButton
            className={classNames(styles.button, styles.buttonMobile)}
            onClick={handleReturnClick}
          />
        </div>
      </section>
      <div className={styles.padding} />
    </>
  );
}

export default ActivePodBar;
