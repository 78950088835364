import { IconProps } from "../../types";

const BetaIcon = ({}: IconProps) => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M45.5227 0.06137L60 0.0613693L8.52729e-08 60L0 28.5068L13.6568 14.792L28.1419 0L45.5227 0.06137Z"
            fill="#DF898F"
        />
        <path
            d="M13.0416 38.7616L5.65337 31.342L8.19338 28.7912C9.57426 27.4045 11.2374 26.8275 12.6485 28.2446C13.2432 28.8418 13.5355 29.7832 13.2331 30.674C14.2914 30.1982 15.4405 30.3804 16.267 31.2104C17.6277 32.5769 17.416 34.3686 15.8437 35.9476L13.0416 38.7616ZM9.36259 30.3096L8.46553 31.2104L10.1085 32.8603L11.0963 31.8684C11.7212 31.2408 11.9732 30.5626 11.3986 29.9857C11.0257 29.6111 10.3504 29.3176 9.36259 30.3096ZM12.4368 32.9312L11.3079 34.0649L13.1726 35.9375L14.2713 34.8342C14.9264 34.1762 15.259 33.2146 14.624 32.5769C13.9991 31.9494 13.1827 32.1822 12.4368 32.9312ZM19.7743 32.0003L12.3962 24.5908L17.2746 19.6917L18.6656 21.0886L15.2789 24.4896L16.9924 26.2104L19.5223 23.6697L20.9133 25.0666L18.3833 27.6073L19.8751 29.1053L23.3525 25.6132L24.7435 27.01L19.7743 32.0003ZM19.29 20.502L17.8688 19.0747L23.5133 13.4063L24.9345 14.8335L22.8481 16.9288L28.8151 22.9212L27.3334 24.4091L21.3664 18.4168L19.29 20.502ZM30.3638 21.3659L25.8482 11.0615L27.1585 9.74565L37.4294 14.2703L35.8167 15.8898L33.5892 14.8472L30.9282 17.5195L31.9765 19.7464L30.3638 21.3659ZM31.9865 14.0476L28.5293 12.4179L30.142 15.8999L31.9865 14.0476Z"
            fill="white"
        />
    </svg>
);

export default BetaIcon;
