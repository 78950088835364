import React, { useCallback, useState, useMemo } from "react";
import { Props as ButtonProps } from "components/Buttons/Button";
import ShareMenu from "components/ShareMenu";

export interface Props {
  children: React.ReactNode;
  component: React.FC<ButtonProps>;
  className?: string;
  title?: string;
  url?: string;
}

function ShareButtonWrapper({
  children,
  className,
  component: Component,
  title,
  url,
}: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  const canonicalLink = document.querySelector("link[rel=canonical]");

  const shareData = useMemo(
    () => ({
      title: title || document.title,
      url:
        url ||
        (canonicalLink ? (canonicalLink as any).href : document.location.href),
    }),
    [document.title, canonicalLink, document.location]
  );

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible, visible]);

  const handleClick = useCallback(() => {
    if (navigator.share) {
      navigator.share(shareData);
    } else {
      setVisible(!visible);
    }
  }, [setVisible, visible]);

  return (
    <div style={{ position: "relative" }}>
      <Component className={className} onClick={handleClick}>
        {children}
      </Component>
      {!navigator.share && (
        <ShareMenu
          visible={visible}
          title={shareData.title}
          shareUrl={shareData.url}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

export default ShareButtonWrapper;
