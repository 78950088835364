import React from "react";

function SuspenseWrapper({ children }: { children: React.ReactNode }) {
  return (
    <React.Suspense fallback={<div style={{ backgroundColor: "pink" }} />}>
      {children}
    </React.Suspense>
  );
}

export default SuspenseWrapper;
