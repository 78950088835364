import { useAppSelector } from "hooks";
import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { selectLoginMethod } from "reducers/SessionReducer";

interface Props {
  to: string;
}
// Temporary page to redirect to another page while reorganizing the routes
function RedirectPage({ to }: Props): JSX.Element {
  const navigate = useNavigate();
  const loginMethod = useAppSelector(selectLoginMethod);

  useEffect(() => {
    if (loginMethod) {
      navigate(to);
    }
  }, [loginMethod]);

  return <div />;
}

export default RedirectPage;
