import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import PostReactionListPage from "containers/PostReactionListPage";
import styles from "./SecondaryPostReactions.module.scss";

export interface Props {
  className?: string;
  postUid: string;
  postTitle: string;
  reactionCount?: number;
}

function SecondaryPostReactions({
  className,
  postUid,
  postTitle,
  reactionCount
}: Props): JSX.Element {
  const { t } = useTranslation("ComponentsPostReactionContextMenu");
  const [pageCursors, setPageCursors] = useState<string[]>([]);

  const handleLoadNext = (cursor: string) => {
    setPageCursors([...pageCursors, cursor]);
  };

  return (
    <div className={classNames(styles.SecondaryPostReactions, className)}>
      <header>
        <h4>{t("reactions")}</h4>
        <p>{postTitle}</p>
      </header>
      <ul>
        <PostReactionListPage
          postUid={postUid}
          onLoadNext={handleLoadNext}
          loadingCount={reactionCount}
        />
        {pageCursors.map((cursor) => (
          <PostReactionListPage
            postUid={postUid}
            onLoadNext={handleLoadNext}
            after={cursor}
          />
        ))}
      </ul>
    </div>
  );
}

export default SecondaryPostReactions;
