/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import type { RootState } from "store/Store";
import { UserEvent } from "types/UserEvent";

type UserEventLog = {
  id: string;
  event: UserEvent;
};
export interface UserEventsState {
  unprocessedEvents: UserEventLog[];
}

export const initialState: UserEventsState = {
  unprocessedEvents: []
};

export const SessionSlice = createSlice({
  name: "userEvents",
  initialState,
  reducers: {
    addEvent: (state, { payload }: PayloadAction<UserEvent>) => {
      state.unprocessedEvents.push({
        id: uuidv4(),
        event: payload
      });
    },
    removeProcessedEvents: (state, { payload }: PayloadAction<string[]>) => {
      state.unprocessedEvents = state.unprocessedEvents.filter(
        (event) => !payload.includes(event.id)
      );
    }
  }
});

export const selectUnprocessedUserEvents = ({
  userEvents: { unprocessedEvents }
}: RootState) => unprocessedEvents;

export const { addEvent, removeProcessedEvents } = SessionSlice.actions;

export const { reducer } = SessionSlice;
