import { IconProps } from "../../types";
const ReadReceiptsIcon = ({
    strokeColor = "#EA515A",
    size,
    fill = "#EA515A",
}: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.87999 13.296L5.81717 16.32L12.9503 7.67999M13.3098 14.4686L14.9468 16.32L22.08 7.67999"
                stroke={strokeColor}
                strokeWidth="2.8913"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default ReadReceiptsIcon;
