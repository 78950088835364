import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiSessionCreateResponse } from "types/api/session/SessionCreateResponse";

import { transform as transformMe } from "api/dataTransformations/MeTransformation";
import { transformRegBody } from "api/dataTransformations/RegistrationTransformation";
import {
    SessionCreateRequestProps,
    SessionCreateResponseProps,
    SessionThirdPartyPayloadProps,
} from "./types";

export const api = createApi({
    reducerPath: "api/session",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("PEANUT-CLIENT", import.meta.env.VITE_PEANUT_CLIENT!);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createSession: builder.mutation<
            SessionCreateResponseProps,
            SessionCreateRequestProps<SessionThirdPartyPayloadProps>
        >({
            query: (body: SessionCreateRequestProps) => ({
                url: "session",
                method: "POST",
                body,
            }),

            transformResponse: ({
                user,
                registration,
            }: ApiSessionCreateResponse) => ({
                registration: transformRegBody(registration),
                me: transformMe(user),
                session: {
                    authToken: user.auth_token!,
                },
            }),
        }),
    }),
});

export const { useCreateSessionMutation } = api;
