import useBranchSdk from "hooks/useBranchSdk";
import { useCallback, useMemo } from "react";

import { useLocation } from "react-router";
import { DeepLinkData } from "branch-sdk";

const REGEX_POD_ID = /^\/pods\/([^/]+)$/;
const REGEX_PODS_ID = /^\/pods\/$/;

export const DEFAULT_LINK_PROPS: DeepLinkData = {
  channel: "web-client",
  data: {
    $desktop_url: "https://www.peanut-app.io/download/",
    $og_title: "Peanut",
    $og_description:
      "Peanut is a free, open-source, peer-to-peer audio chat app.",
    $og_image_url: "https://peanut.app/static/images/peanut-logo.png",
    $og_url: "https://www.peanut-app.io/",
  },
};

// Returns a branch link, deeplinking to the correct section in app.
const useAppLink = () => {
  const { asyncInit } = useBranchSdk();
  const { pathname } = useLocation();

  const deeplinkPod: () => DeepLinkData = useCallback(() => {
    const matches = REGEX_POD_ID.exec(pathname);

    return {
      ...DEFAULT_LINK_PROPS,
      data: {
        ...DEFAULT_LINK_PROPS.data,
        $deeplink: `peanut:/audio_room/${matches![1]}/`,
      },
    };
  }, [pathname]);

  const deeplinkPods: () => DeepLinkData = useCallback(
    () => ({
      ...DEFAULT_LINK_PROPS,
      data: {
        ...DEFAULT_LINK_PROPS.data,
        $deeplink: `peanut:/audio_room/`,
      },
    }),
    []
  );

  const REGEX_COLLECTION = [
    { regex: REGEX_POD_ID, handler: deeplinkPod },
    { regex: REGEX_PODS_ID, handler: deeplinkPods },
  ];

  const deepLinkData = useMemo(
    () =>
      REGEX_COLLECTION.find(({ regex }) => regex.test(pathname))?.handler() ||
      DEFAULT_LINK_PROPS,
    [pathname]
  );

  const getBranchUrl = async () => {
    await asyncInit();

    return new Promise((resolve, reject) => {
      window.branch.link(deepLinkData, (err: any, result: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const getUrl = async () => getBranchUrl() as Promise<string>;

  return getUrl;
};
export default useAppLink;
