import React, { useCallback } from "react";

import { useGetPostReactionsQuery } from "api/reactions";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import type { UserReaction } from "types/UserReaction";
import UserReactionListItem from "components/UserReactionListItem";
import UserReactionListItemSkeleton from "components/UserReactionListItem/UserReactionListItem.skeleton";

export interface Props {
  postUid: string;
  after?: string;
  onLoadNext: (after: string) => void;
  loadingCount?: number;
}

// Hard coded in the API
const PAGE_SIZE = 50;

function PostReactionListPage({
  postUid,
  after,
  onLoadNext,
  loadingCount = 3
}: Props): JSX.Element {
  const { data } = useGetPostReactionsQuery({ postUid, after });

  const handleLoadMore = useCallback(() => {
    if (!data?.cursor) return;

    onLoadNext(data.cursor);
  }, [data?.cursor, onLoadNext]);

  const refLoader = useInfiniteScroll(handleLoadMore);

  const loaderIndex =
    data?.count === PAGE_SIZE ? Math.floor(PAGE_SIZE / 2) : -1;

  return data?.reactions ? (
    <>
      {data.reactions.map((item: UserReaction, index) => (
        <React.Fragment key={item.id}>
          <UserReactionListItem userReaction={item} />
          {index === loaderIndex && <div ref={refLoader} />}
        </React.Fragment>
      ))}
    </>
  ) : (
    <div>
      {[...Array(loadingCount)]
        .map((_, index) => `loading_${index}`)
        .map((key) => (
          <UserReactionListItemSkeleton key={key} />
        ))}
    </div>
  );
}

export default PostReactionListPage;
