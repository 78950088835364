import React from "react";
import classNames from "classnames";
import Button from "../Button";

import styles from "./ProActionButton.module.scss";

export interface Props {
  disabled?: boolean;
  children?: React.ReactNode;
  submit?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function ProActionButton({
  children,
  className,
  disabled,
  submit,
  onClick
}: Props): JSX.Element {
  return (
    <Button
      className={classNames(styles.wrapper, className)}
      submit={submit}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default ProActionButton;
