import { IconProps } from "../../types";

const HelpIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6447 19.9285C17.201 19.9285 20.8947 16.2349 20.8947 11.6785C20.8947 7.12218 17.201 3.42853 12.6447 3.42853C8.08833 3.42853 4.39468 7.12218 4.39468 11.6785C4.39468 16.2349 8.08833 19.9285 12.6447 19.9285Z"
            stroke={strokeColor}
            strokeWidth="1.20508"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.7855 13.226V12.646C11.7855 12.3686 11.8296 12.1185 11.9179 11.8958C12.0061 11.6688 12.1448 11.4608 12.334 11.2716C12.5231 11.0825 12.7606 10.9018 13.0464 10.7295C13.2902 10.5781 13.4856 10.431 13.6327 10.2881C13.784 10.141 13.8933 9.98132 13.9605 9.80899C14.0278 9.63667 14.0614 9.43913 14.0614 9.21637V9.20376C14.0614 8.94317 13.9984 8.71201 13.8723 8.51027C13.7462 8.30852 13.5676 8.14881 13.3364 8.03112C13.1052 7.91344 12.832 7.8546 12.5168 7.8546C12.2016 7.8546 11.9263 7.91344 11.6909 8.03112C11.4555 8.14881 11.2685 8.31693 11.1298 8.53548C10.9953 8.75404 10.9176 9.01252 10.8965 9.31094L10.8839 9.31724L9.81217 9.32355V9.31094C9.83739 8.86122 9.95507 8.45142 10.1652 8.08156C10.3754 7.71169 10.6801 7.41748 11.0794 7.19893C11.4787 6.98037 11.9725 6.87109 12.5609 6.87109C13.0905 6.87109 13.5549 6.97197 13.9542 7.17371C14.3577 7.37125 14.6708 7.64235 14.8936 7.98699C15.1206 8.32743 15.234 8.71411 15.234 9.14702V9.15963C15.234 9.48746 15.1794 9.78588 15.0701 10.0549C14.965 10.3197 14.8137 10.5571 14.6162 10.7673C14.4187 10.9774 14.1854 11.1645 13.9164 11.3284C13.6516 11.4839 13.4436 11.6331 13.2923 11.776C13.1451 11.9189 13.0401 12.0723 12.977 12.2362C12.9182 12.4001 12.8888 12.5935 12.8888 12.8162V13.226H11.7855ZM12.4222 16.1513C12.1658 16.1513 11.9557 16.0694 11.7918 15.9054C11.6279 15.7415 11.5459 15.5314 11.5459 15.275C11.5459 15.0144 11.6279 14.8022 11.7918 14.6382C11.9557 14.4743 12.1658 14.3924 12.4222 14.3924C12.6786 14.3924 12.8867 14.4743 13.0464 14.6382C13.2103 14.8022 13.2923 15.0144 13.2923 15.275C13.2923 15.5314 13.2103 15.7415 13.0464 15.9054C12.8867 16.0694 12.6786 16.1513 12.4222 16.1513Z"
            fill={strokeColor}
        />
    </svg>
);

export default HelpIcon;
