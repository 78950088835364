import { useAppSelector } from "hooks";
import { selectSecondaryPanel } from "reducers/LayoutReducer";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import SecondaryPostReactions from "containers/SecondaryPostReactions";
import { useTranslation } from "react-i18next";
import SecondaryMyGroups from "containers/SecondaryMyGroups";

import styles from "./SecondaryPanel.module.scss";

function SecondaryPanel() {
  return <div>SidebarPostReaction</div>;
}
const SIDEBAR_COMPONENT_MAP: { [key: string]: any } = {
  PostReactions: SecondaryPostReactions,
  MyGroups: SecondaryMyGroups
};

export function SecondardPanel() {
  const { t } = useTranslation();
  const { secondaryPanelOpen: open, secondaryPanelAction } =
    useAppSelector(selectSecondaryPanel);

  const [state, setState] = useState(true);
  const helloRef = useRef(null);
  const goodbyeRef = useRef(null);
  const nodeRef = state ? helloRef : goodbyeRef;
  const mode = "out-in";

  useEffect(() => {}, [open]);

  const ActionComponent =
    secondaryPanelAction && SIDEBAR_COMPONENT_MAP[secondaryPanelAction.action];

  return (
    <div
      className={classNames(styles.SecondaryPanelContainer, {
        [styles.open]: open
      })}
    >
      <div className={styles.SecondaryPanel}>
        <div className={styles.searchContainer}>
          <input type="text" placeholder={t("search")} />
        </div>
        <SwitchTransition mode={mode}>
          <CSSTransition
            key={
              secondaryPanelAction
                ? `${secondaryPanelAction?.action}-${secondaryPanelAction.params.postUid}`
                : "Hello"
            }
            nodeRef={nodeRef}
            addEndListener={(done: any) => {
              // @ts-ignore
              nodeRef?.current?.addEventListener("transitionend", done, false);
            }}
            classNames={{
              appear: styles.containerAppear,
              appearActive: styles.containerAppearActive,
              appearDone: styles.containerAppearDone,
              enter: styles.containerEnter,
              enterActive: styles.containerEnterActive,
              enterDone: styles.containerEnterDone,
              exit: styles.containerExit,
              exitActive: styles.containerExitActive,
              exitDone: styles.containerExitDone
            }}
          >
            <div ref={nodeRef}>
              <div className={styles.container}>
                {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
                {ActionComponent && (
                  <ActionComponent {...secondaryPanelAction?.params} />
                )}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}

export default SecondaryPanel;
