import AppBar from "components/AppBar";
import ActivePodBar from "containers/Pods/ActivePodBar";
import PodListener from "containers/Pods/Listener";
import { useAppSelector } from "hooks";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router";
import { selectActivePod } from "reducers/ActivePodReducer";

import MobileActionBar from "components/MobileActionBar";

import { SecondardPanel } from "containers/SecondaryPanel";
import styles from "./AppLayout.module.scss";
import BetaIcon from "@/DesignSystem/components/Icon/Icons/special/beta";

function AppLayout(): JSX.Element {
    const { roomId: activePod } = useAppSelector(selectActivePod);
    const { pathname } = useLocation();

    const isPodPage = useMemo(() => pathname.match(/\/pods\/.+/), [pathname]);

    return (
        <PodListener>
            <>
                <div className={styles.appLayout}>
                    <AppBar />
                    <div className={styles.container}>
                        <Outlet />
                        <SecondardPanel />
                    </div>
                    {activePod && !isPodPage && <ActivePodBar />}
                    <MobileActionBar />
                </div>
                {/* <ModalDownload /> */}
            </>
        </PodListener>
    );
}

export default AppLayout;
