import { Comment } from "types/Comment";
import { Api } from "types/api/comment";

import parseJSONDate from "date-fns/parseJSON";
import transformAuthor from "../AuthorTransformation";
import transformMention from "../MentionTransformation";

export function transform(apiComment: Api.Comment): Comment {
  return {
    uid: apiComment.uid,
    body: apiComment.body,
    createdAt: parseJSONDate(apiComment.created_at).toISOString(),
    likeCount: apiComment.like_count,
    replyCount: apiComment.reply_count,
    postUid: apiComment.post.uid,
    author: transformAuthor(apiComment.author),
    mentions: apiComment.mentions.map(transformMention),
    canEdit: apiComment.can_edit,
    canDelete: apiComment.can_delete,
    canReport: apiComment.can_report,
    canHide: apiComment.can_hide,
    likedByMe: apiComment.liked_by_me,
    postAuthor: apiComment.post_author,
    removed: apiComment.removed,
    image: apiComment.image,
    giphy: apiComment.giphy
  };
}

export default transform;
