import { useCallback, useEffect, useState } from "react";

const useBranchSdk = () => {
  const [branchData, setBranchData] = useState();
  const [branchError, setBranchError] = useState();

  const windowDefined = typeof window !== "undefined";
  const windowReady = windowDefined && window;

  const loadBranchSdk = useCallback(() => {
    if (!windowDefined) return;
    if (window.branch) return;

    // prettier-ignore
    // eslint-disable-next-line
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData".split(" "), 0);
  }, [windowDefined]);

  const initBranchSdk = () => {
    loadBranchSdk();

    window.branch.init(import.meta.env.VITE_BRANCH_KEY, {}, (err, data) => {
      setBranchData(data?.data_parsed);
      setBranchError(err);
    });
  };

  const asyncInit = async () => {
    loadBranchSdk();

    return new Promise((resolve, reject) => {
      window.branch.init(import.meta.env.VITE_BRANCH_KEY, {}, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  // If the page *may* need the branch SDK, and we are not in SSR, then load it.
  useEffect(() => {
    if (windowReady && !window.branch) {
      loadBranchSdk();
    }
  }, [loadBranchSdk, windowReady]);

  return {
    data: branchData,
    error: branchError,
    asyncInit,
    initialize: initBranchSdk,
  };
};

export default useBranchSdk;
