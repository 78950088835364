import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import { api as apiPagesCategories } from "api/Pages/Categories/PostsApi";
import { api as apiPosts } from "api/posts/PostsApi";

export type CreateVoteRequest = {
  pollUid: string;
  optionUid: string;
};

export type CreateVoteResponse = void;

export const api = createApi({
  reducerPath: "api/pages/polls",
  baseQuery: dynamicBaseQuery,

  endpoints: (builder) => ({
    createVote: builder.mutation<CreateVoteResponse, CreateVoteRequest>({
      query: ({ pollUid, optionUid }) => ({
        url: `pages/polls/${pollUid}/vote`,
        method: "POST",
        body: {
          option: {
            uid: optionUid
          }
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          apiPagesCategories.util.invalidateTags([
            { type: "FeedItem", id: arg.pollUid }
          ])
        );
        dispatch(
          apiPosts.util.invalidateTags([{ type: "Post", id: arg.pollUid }])
        );
      }
    })
  })
});

export const { useCreateVoteMutation } = api;
