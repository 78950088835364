/* eslint-disable no-param-reassign */
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { api as apiComments } from "api/CommentsApi";
import type { RootState } from "store/Store";

export interface GroupState {
  askToJoin: boolean;
}

export const actionActAs = createAction<string>("session/actAs");

export const initialState: GroupState = {
  askToJoin: false
};

export const GroupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setAskToJoin: (state, { payload }: PayloadAction<boolean>) => {
      state.askToJoin = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiComments.endpoints.createComment.matchFulfilled,
      (state, { payload }) => {
        state.askToJoin = payload.askToInvite;
      }
    );
  }
});

export const selectGroupAskToJoin = ({ groups: { askToJoin } }: RootState) =>
  askToJoin;

export const { setAskToJoin } = GroupsSlice.actions;

export const { reducer } = GroupsSlice;
