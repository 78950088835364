import React from "react";
import styles from "./Error403.module.scss";

export interface Props {}

function Error403(): JSX.Element {
  return <div className={styles.wrapper} />;
}

export default Error403;
