import { IconProps } from "../../types";

const FertilityIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.3982 13.9948C17.2906 9.05649 11.9997 3.6001 11.9997 3.6001C11.9997 3.6001 6.70913 9.05649 6.60191 13.9948C6.54597 16.54 7.79421 20.4001 11.9997 20.4001C16.2056 20.4001 17.4534 16.54 17.3982 13.9948Z"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.60171 13.9948C6.59705 14.1997 6.60207 14.4139 6.61534 14.6333C7.35546 15.101 8.27918 15.3883 9.42342 15.3883C13.6293 15.3883 14.8772 11.5282 14.8219 8.98302C14.8047 8.18797 14.6516 7.3798 14.4092 6.58268C13.1628 4.79975 11.9999 3.6001 11.9999 3.6001C11.9999 3.6001 6.70929 9.05649 6.60171 13.9948Z"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.4092 6.5828C13.1624 4.79987 11.9995 3.60022 11.9995 3.60022C11.9995 3.60022 10.3077 5.34589 8.81775 7.75555C8.88445 9.70232 9.90893 12.36 12.9723 12.36C13.4363 12.36 13.8487 12.2934 14.2227 12.183C14.6731 11.1165 14.8427 9.95411 14.8216 8.9828C14.8047 8.18774 14.6516 7.37993 14.4092 6.5828Z"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linejoin="round"
        />
    </svg>
);

export default FertilityIcon;
