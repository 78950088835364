import AgoraRTC from "agora-rtc-sdk-ng";

export default () => {
  AgoraRTC.setLogLevel(4);

  const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

  client.setClientRole("audience");

  client.on("user-published", async (user, mediaType) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "audio") {
      const { audioTrack } = user;
      audioTrack!.play();
    }
  });

  return client;
};
