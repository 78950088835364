import { IconProps } from "../../types";

const RedditIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.0586 12.1594C21.0586 10.9369 20.1208 9.94482 18.9652 9.94482C18.3958 9.94482 17.8933 10.1751 17.5249 10.5649C16.1013 9.48419 14.1251 8.77553 11.9479 8.68695L12.9025 3.95663L16.0009 4.64758C16.0343 5.48025 16.6875 6.15348 17.4914 6.15348C18.312 6.15348 18.9819 5.44482 18.9819 4.57671C18.9819 3.7086 18.312 2.99994 17.4914 2.99994C16.9052 2.99994 16.4028 3.35427 16.1683 3.88577L12.7016 3.10624C12.6011 3.08852 12.5006 3.10624 12.4169 3.15939C12.3331 3.21254 12.2829 3.30112 12.2494 3.40742L11.1943 8.68695C8.96684 8.75781 6.97387 9.44876 5.53357 10.5649C5.16512 10.1929 4.64595 9.94482 4.09327 9.94482C2.93769 9.94482 1.99982 10.9369 1.99982 12.1594C1.99982 13.0629 2.50225 13.8247 3.23914 14.1791C3.20565 14.3917 3.1889 14.622 3.1889 14.8523C3.1889 18.2539 6.92363 20.9999 11.546 20.9999C16.1683 20.9999 19.9031 18.2539 19.9031 14.8523C19.9031 14.622 19.8863 14.4094 19.8528 14.1968C20.5395 13.8425 21.0586 13.0629 21.0586 12.1594ZM6.7394 13.7362C6.7394 12.868 7.40931 12.1594 8.22994 12.1594C9.05058 12.1594 9.72048 12.868 9.72048 13.7362C9.72048 14.6043 9.05058 15.3129 8.22994 15.3129C7.40931 15.3129 6.7394 14.6043 6.7394 13.7362ZM15.063 17.8995C14.0414 18.9803 12.0986 19.0511 11.5292 19.0511C10.9598 19.0511 9.00033 18.9625 7.99547 17.8995C7.84475 17.7401 7.84475 17.4743 7.99547 17.3149C8.1462 17.1555 8.39742 17.1555 8.54815 17.3149C9.18456 17.9881 10.5579 18.2362 11.546 18.2362C12.5341 18.2362 13.8906 17.9881 14.5438 17.3149C14.6945 17.1555 14.9458 17.1555 15.0965 17.3149C15.2137 17.4921 15.2137 17.7401 15.063 17.8995ZM14.795 15.3129C13.9744 15.3129 13.3045 14.6043 13.3045 13.7362C13.3045 12.868 13.9744 12.1594 14.795 12.1594C15.6157 12.1594 16.2856 12.868 16.2856 13.7362C16.2856 14.6043 15.6157 15.3129 14.795 15.3129Z"
            fill="#FF4500"
        />
    </svg>
);

export default RedditIcon;
