/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGetPodQuery } from "api/pods";
import { Helmet } from "react-helmet";

import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate, useParams } from "react-router";

import { useTranslation } from "react-i18next";

import Button from "components/Buttons/Button";

import RoundButton from "components/Buttons/RoundButton";

import {
  selectActivePod,
  selectReactionsCursor,
  setInteractionRequired,
  setRoomId
} from "reducers/ActivePodReducer";
import { useAppDispatch, useAppSelector } from "hooks";
import Participants from "containers/Pods/Participants";
import Pill from "components/Pill";
import Participant from "containers/Pods/Participant";
import ProActionButton from "components/Buttons/ProActionButton";
import ExpandableParagraph from "components/ExpandableParagraph";
import DownloadActionWrapper from "containers/DownloadActionWrapper";
import ShareButtonWrapper from "containers/ShareButtonWrapper";

import Modal from "containers/Modal";
import PodWelcomeModal from "containers/Pods/PodWelcomeModal";
import Skeleton from "react-loading-skeleton";

import slugify from "helpers/slugify";
import { useGetReactionsQuery } from "api/pods/reactions";
import useAppLink from "hooks/useAppLink";
import imageShare from "./assets/share.svg";
import imageReact from "./assets/heart.svg";
import imageSpeak from "./assets/microphone.svg";
import imageLeave from "./assets/leave.svg";
import imageProStar from "./assets/pro-star.svg";

import styles from "./PodPage.module.scss";

function PodPage(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("PagesPodPage");

  const { data, isError, error } = useGetPodQuery(
    { id: id! },
    { pollingInterval: 30000 }
  );

  const reactionsCursor = useAppSelector(selectReactionsCursor);

  const timestampRef = useRef(Date.now()).current;
  useGetReactionsQuery(
    { roomId: id!, cursor: reactionsCursor, timestampRef },
    { pollingInterval: 10000 }
  );

  useEffect(() => {
    if (isError && error) {
      navigate("/404");
    }
  }, [isError]);

  useEffect(() => {
    if (data && (data.status as string) !== "started") {
      const slug = slugify(data.title);

      window.location.href = `${
        import.meta.env.VITE_WEBSITE_URL
      }/pods/${id}/${slug}`;
    }
  }, [data]);

  const activePod = useAppSelector(selectActivePod);
  const dispatch = useAppDispatch();

  const { title, description, publicUrl } = data || {};

  useEffect(() => {
    if (data?.id) {
      dispatch(setRoomId(data.id));
    }
  }, [data]);

  const handleLeave = () => {
    dispatch(setRoomId(undefined));
    navigate("/pods");
  };

  // Connect button is just to have a user interaction on the page,
  // which will enable agora.
  const handleConnect = () => {
    dispatch(setInteractionRequired(false));
  };

  const host = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data.featuredParticipants.find((p) => p.status === "host");
  }, [data]);

  const isProPod = useMemo(() => !!data?.creator, [data]);

  return (
    <>
      {data && (
        <Helmet>
          <title>{`${title} - ${import.meta.env.VITE_DEFAULT_TITLE}`}</title>

          <link rel="canonical" href={publicUrl} />
        </Helmet>
      )}
      <div className={classNames(styles.wrapper, "dark-mode")}>
        <main>
          <div>
            <header>
              <h1>{data ? title : <Skeleton count={1.5} />}</h1>
              {host && (
                <p>
                  <span>Host</span> {host.firstName}
                </p>
              )}
            </header>

            <ExpandableParagraph numberOfLines={4} showReadLess>
              {description}
            </ExpandableParagraph>

            <div className={styles.tags}>
              {data?.tags.map(({ id: tagId, name: tagName }) => (
                <Pill key={`tag_${tagId}`}>{tagName}</Pill>
              ))}
            </div>

            {isProPod && host && (
              <div className={styles.proHost}>
                <Participant
                  participant={host}
                  className={styles.proHostParticipant}
                  showProBadge
                  showRole
                />

                <DownloadActionWrapper
                  component={ProActionButton}
                  className={styles.proHostTipButton}
                >
                  <img src={imageProStar} alt="Star" />
                  <span>{t("tipNow")}</span>
                </DownloadActionWrapper>
              </div>
            )}
            {data ? (
              <Participants
                roomId={data.id}
                excludeHost={isProPod}
                className={styles.participants}
              />
            ) : (
              <Participants.Mock />
            )}
          </div>
        </main>
        <footer>
          <div>
            <div className={styles.buttonsLeft}>
              <Button onClick={handleLeave} className={styles.buttonLeaveLarge}>
                <img src={imageLeave} alt="Leave" />
                {t("leavePod")}
              </Button>
              <RoundButton
                onClick={handleLeave}
                className={styles.buttonLeaveSmall}
              >
                <img src={imageLeave} alt="Leave" />
              </RoundButton>
            </div>

            <ShareButtonWrapper
              component={RoundButton}
              className={styles.buttonShare}
            >
              <img src={imageShare} alt="share" />
            </ShareButtonWrapper>

            <DownloadActionWrapper
              component={RoundButton}
              className={styles.buttonReact}
            >
              <img src={imageReact} alt="share" />
            </DownloadActionWrapper>

            {activePod.interactionRequired ? (
              <Button onClick={handleConnect} className={styles.buttonSpeak}>
                <img src={imageSpeak} alt="Speak" />
                {t("listenNow")}
              </Button>
            ) : (
              <DownloadActionWrapper
                component={Button}
                className={styles.buttonSpeak}
              >
                <img src={imageSpeak} alt="Speak" />
                {t("requestToTalk")}
              </DownloadActionWrapper>
            )}
          </div>
        </footer>
        <PodWelcomeModal />
      </div>
    </>
  );
}

export default PodPage;
