import React from "react";
import classNames from "classnames";
import BeatLoader from "react-spinners/BeatLoader";

import Button from "../Button";

import styles from "./ActionButton.module.scss";

export interface Props {
  disabled?: boolean;
  children: React.ReactNode;
  submit?: boolean;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  testId?: string;
}

function ActionButton({
  children,
  className,
  disabled,
  onClick,
  loading = false,
  submit,
  testId
}: Props): JSX.Element {
  return (
    <Button
      className={classNames(styles.wrapper, className)}
      submit={submit}
      disabled={disabled || loading}
      onClick={onClick}
      testId={testId}
    >
      {loading ? <BeatLoader loading color="#ffffff" /> : children}
    </Button>
  );
}

export default ActionButton;
