import Container from "@/DesignSystem/components/Container";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

import { ErrorTemplateProps } from "./types";
import { Link } from "react-router-dom";
import arrowRight from "@/assets/svg/arrow-right.svg";
import { useSelector } from "react-redux";
import { selectUserUID } from "@/reducers/PublicSubscriptionReducer/PublicSubscriptionReducer";

const ErrorTemplate = ({
    toUrl,
    pageType = "errorCheckout",
    hrefRedirect,
    subscriptionError,
}: ErrorTemplateProps) => {
    const { t } = useTranslation("ErrorPages");
    const userUID = useSelector(selectUserUID);

    if (subscriptionError && !hrefRedirect) {
        toUrl = `/subscription/${userUID}`;
    }

    return (
        <div className={styles.background}>
            <Container>
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <div>
                            <h3>{t(`${pageType}.title`)}</h3>
                        </div>
                        <p>{t(`${pageType}.description`)}</p>
                        {!!toUrl && (
                            <Link className={styles.buttonReturn} to={toUrl}>
                                {t(`${pageType}.button`)}{" "}
                                <img src={arrowRight} alt="arrow-right" />
                            </Link>
                        )}
                        {!!hrefRedirect && (
                            <a
                                href={hrefRedirect.toString()}
                                className={styles.buttonReturn}
                            >
                                {t(`${pageType}.button`)}{" "}
                                <img src={arrowRight} alt="arrow-right" />
                            </a>
                        )}
                    </div>
                    <div className={styles.img} />
                </div>
            </Container>
        </div>
    );
};

export default ErrorTemplate;
