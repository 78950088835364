import useAppLink from "hooks/useAppLink";
import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";

import styles from "./PodStartCard.module.scss";

export interface Props {}

function PodStartCard(): JSX.Element {
  const { t } = useTranslation("PodStartCard");
  const getAppLink = useAppLink();

  const handleClick = useCallback(async () => {
    window.location.href = await getAppLink();
  }, [getAppLink]);

  return (
    <button type="button" onClick={handleClick} className={styles.wrapper}>
      <h3>{t("createAPod")}</h3>
      <p>{t("tapToStart")}</p>
    </button>
  );
}

export default PodStartCard;
