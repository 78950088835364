import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "api";
import { SchemaMeGroup } from "schema/MeGroup";
import { MeGroup } from "types/MeGroup";

export type GetPostRequest = {
  uid: string;
};

export type GetMeGroupResponse = MeGroup;

export type PostReactionCreateRequest = {
  postUid: string;
  code: string;
};

export type PostReactionCreateResponse = void;

export type GetMeGroupRequest = {
  uid: string;
};

export type UpdateGroupMembershipRequest = {
  uid: string;
  status: "join" | "left" | "requested";
};

export const api = createApi({
  reducerPath: "api/me/groups",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["MeGroup"],
  endpoints: (builder) => ({
    getMeGroup: builder.query<GetMeGroupResponse, GetMeGroupRequest>({
      query: ({ uid }) => ({
        url: `me/groups/${uid}`
      }),
      providesTags: (result, error, arg: GetMeGroupRequest) => [
        {
          type: "MeGroup",
          id: arg.uid
        }
      ],
      transformResponse: ({ group }: { group: any }) =>
        SchemaMeGroup.parse(group) as MeGroup
    }),

    updateMeGroupMembership: builder.mutation<
      void,
      UpdateGroupMembershipRequest
    >({
      query: ({ uid, status }) => ({
        url: `me/groups/${uid}`,
        method: "PATCH",
        body: {
          status
        }
      })
    })
  })
});

export const { useGetMeGroupQuery, useUpdateMeGroupMembershipMutation } = api;
