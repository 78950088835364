import { ProfileBadge } from "types/ProfileBadge";
import { z } from "zod";

export const SchemaProfileBadge = z
  .object({
    id: z.string(),
    label: z.string(),
    description: z.string(),
    icon_url: z.string()
  })
  .transform(
    (data): ProfileBadge => ({
      id: data.id,
      label: data.label,
      description: data.description,
      iconUrl: data.icon_url
    })
  );

export default SchemaProfileBadge;
