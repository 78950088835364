import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiMeResponse } from "types/api/me";
import { dynamicBaseQuery } from "api";
import { MeProps } from "types/Me";
import { transform as transformMe } from "api/dataTransformations/MeTransformation";

export type MeUpdateRequest = {
    firstName?: string;
    lastName?: string;
    age?: number;
    email?: string;
    marketingOptIn?: boolean;
    dateOfBirth?: string;
};
type MeUpdateResponse = { me: MeProps };

type MeResponse = { me: MeProps };
type MeRequest = {};

export const api = createApi({
    reducerPath: "api/me",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        getMe: builder.query<MeResponse, MeRequest>({
            query: () => "/me",
            transformResponse: ({ user }: ApiMeResponse) => ({
                me: transformMe(user),
            }),
        }),

        updateMe: builder.mutation<MeUpdateResponse, MeUpdateRequest>({
            query: ({
                firstName,
                lastName,
                age,
                email,
                marketingOptIn,
                dateOfBirth,
            }) => {
                const processedDateOfBirth = dateOfBirth
                    ? new Date(dateOfBirth)
                    : undefined;

                const birthday =
                    dateOfBirth && processedDateOfBirth
                        ? {
                              year: processedDateOfBirth.getFullYear(),
                              month: processedDateOfBirth.getMonth() + 1,
                              day: processedDateOfBirth.getDate(),
                          }
                        : undefined;

                return {
                    url: "me",
                    method: "PATCH",
                    body: {
                        user: {
                            first_name: firstName,
                            last_name: lastName,
                            age,
                            email,
                            marketing_email_opt_in: marketingOptIn,
                            birthday,
                        },
                    },
                };
            },
            transformResponse: ({ user }: ApiMeResponse) => ({
                me: transformMe(user),
            }),
        }),
        clearProfile: builder.mutation({
            query: () => ({
                url: "me/clear_profile",
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useUpdateMeMutation,
    useClearProfileMutation,
} = api;

///can access customer portal session
