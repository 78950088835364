/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { api as apiPublicSubscriptions } from "api/publicSubscription";
import { PURGE } from "redux-persist";
import type { RootState } from "store/Store";
import { PublicSubscriptionState } from "./types";

export const initialState: PublicSubscriptionState = {
  data: [],
  currency: undefined,
  isLoading: undefined,
  error: undefined,
  userUID: undefined,
  selectedTier: undefined
};

export const PublicSubscriptionSlice = createSlice({
  name: "publicSubscription",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setUserUID: (state, { payload }) => {
      state.userUID = payload;
    },
    setSelectedTier: (state, { payload }) => {
      state.selectedTier = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiPublicSubscriptions.endpoints.getPublicSubscriptionTiers
        .matchFulfilled,
      (state, { payload }) => {
        state.data = payload.tiers;
        state.currency = payload.currency;
        state.isLoading = false;
        state.error = undefined;
      }
    );

    builder.addMatcher(
      apiPublicSubscriptions.endpoints.getPublicSubscriptionTiers.matchPending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = undefined;
        state.data = [];
      }
    );

    builder.addMatcher(
      apiPublicSubscriptions.endpoints.getPublicSubscriptionTiers.matchRejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );

    builder.addMatcher(
      (action) => action.type === PURGE,
      () => initialState
    );
  }
});

export const selectPublicSubscription = ({ publicSubcription }: RootState) => {
  return publicSubcription;
};
export const selectUserUID = (state: RootState) =>
  state.publicSubcription.userUID;
export const selectSelectedTier = (state: RootState) =>
  state.publicSubcription.selectedTier;
export const selectCurrency = (state: RootState) =>
  state.publicSubcription.currency;

export const { resetState, setUserUID, setSelectedTier } =
  PublicSubscriptionSlice.actions;

export const { reducer } = PublicSubscriptionSlice;
