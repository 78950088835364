import PodCard from "components/Cards/PodCard";
import Modal from "containers/Modal";
import React, { useCallback } from "react";

import { useParams } from "react-router";
import { useGetPodQuery } from "api/pods";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectFirstName } from "reducers/MeReducer";

import {
  selectShowWelcome,
  setInteractionRequired,
  setShowWelcome
} from "reducers/ActivePodReducer";

import { useTranslation } from "react-i18next";

import styles from "./PodWelcomeModal.module.scss";

function PodWelcomeModal(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("PodsPodWelcomeModal");
  const { data } = useGetPodQuery({ id: id! });
  const firstName = useAppSelector(selectFirstName);
  const showWelcome = useAppSelector(selectShowWelcome);
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(setShowWelcome(false));
    dispatch(setInteractionRequired(false));
  }, [dispatch]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!showWelcome) return <></>;
  return (
    <Modal onClose={handleClose} visible={showWelcome}>
      <h1 className={styles.title}>
        {t("welcomeTo")}
        <br />
        {firstName
          ? t("peanutWithFirstName", {
              firstName
            })
          : t("peanut")}
      </h1>
      <p>{t("introduction")}</p>

      {data ? <PodCard room={data} onClick={handleClose} /> : <PodCard.Mock />}
    </Modal>
  );
}

export default PodWelcomeModal;
