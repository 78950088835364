import { createApi } from "@reduxjs/toolkit/query/react";

import { dynamicBaseQuery } from "api";

export type MeTagsUpdateRequest = {
  tagIds: number[];
};
export type MeTagsUpdateResponse = {};

export const api = createApi({
  reducerPath: "api/me/tags",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    updateTags: builder.mutation<MeTagsUpdateResponse, MeTagsUpdateRequest>({
      query: ({ tagIds }) => ({
        url: `me/tags`,
        method: "PUT",
        body: {
          tags: tagIds
        }
      })
    })
  })
});

export const { useUpdateTagsMutation } = api;
