import { useGetPodsQuery } from "api/pods";
import PodActiveCard from "components/Cards/PodActiveCard";
import PodStartCard from "components/Cards/PodStartCard";

import styles from "./PodsActiveCards.module.scss";
import useMaintenanceNavigation from "@/hooks/useMaintenanceNavigation";

function PodsActiveCards(): JSX.Element {
    const { data, error, isLoading } = useGetPodsQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            refetchOnFocus: true,
        }
    );

    useMaintenanceNavigation(error);

    return (
        <div className={styles.wrapper}>
            {data &&
                data.map((room) => <PodActiveCard key={room.id} room={room} />)}

            {data && <PodStartCard />}

            {!data && isLoading && (
                <>
                    <PodActiveCard.Mock />
                    <PodActiveCard.Mock />
                    <PodActiveCard.Mock />
                    <PodActiveCard.Mock />
                </>
            )}
        </div>
    );
}

export default PodsActiveCards;
