import { ApiMe } from "types/api/me";
import { MeProps } from "types/Me";

import { transform as transformChild } from "api/dataTransformations/ChildTransformation";
import transformTag from "api/dataTransformations/TagTransformation";

export const transform = ({
    id,
    uid,
    can_access_stripe_customer_portal,
    registered,
    email,
    first_name,
    birthday,
    registration_status,
    children,
    tags,
    avatar_url,
}: ApiMe): MeProps => ({
    id,
    uid,
    registered,
    email,
    firstName: first_name,
    dateOfBirth: birthday,
    registrationStatus: registration_status,
    stripeAccess: can_access_stripe_customer_portal,
    children: children?.map(transformChild),
    tags: tags?.map(transformTag),
    avatarUrl: avatar_url,
});

export default transform;
