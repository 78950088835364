import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "./Avatar.module.scss";

export interface Props {
  avatarUrl: string;
  className?: string;
  firstName: string;
  baseImageSize?: number;
}

function Avatar({
  avatarUrl,
  firstName,
  className,
  baseImageSize = 120
}: Props): JSX.Element {
  const processedAvatarUrl = useMemo(
    () =>
      avatarUrl
        ? avatarUrl
            .replace("{width}", `${baseImageSize}`)
            .replace("{height}", `${baseImageSize}`)
            .replace("{scale_factor}", "3")
        : "https://storage.googleapis.com/peanut-assets/images/pages_virtual_author_icons/incognito@2x.png",
    [avatarUrl]
  );

  return (
    <div className={classNames(styles.wrapper, className)}>
      <img src={processedAvatarUrl} alt={firstName} loading="lazy" />
    </div>
  );
}

export default Avatar;
