import { IconProps } from "../../types";

const ChildIcon = ({ strokeColor, size, fill = "none" }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.7822 7.35503C13.0677 7.35503 14.1098 6.31295 14.1098 5.02749C14.1098 3.74203 13.0677 2.69995 11.7822 2.69995C10.4968 2.69995 9.45468 3.74203 9.45468 5.02749C9.45468 6.31295 10.4968 7.35503 11.7822 7.35503Z"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18.4055 6.1836C18.8851 6.6636 18.8851 7.43932 18.4055 7.91931L14.6119 11.7092L14.6005 18.0469V20.3081C14.6005 20.9769 14.0641 21.5206 13.3981 21.5343C12.7093 21.5479 12.1456 20.9701 12.1456 20.2831V18.0469H11.4183V20.3081C11.4183 20.9769 10.8818 21.5206 10.2158 21.5343C9.52712 21.5479 8.96341 20.9701 8.96341 20.2831V18.0469L8.95205 11.7092L5.15838 7.91703C4.67196 7.43022 4.68105 6.6363 5.18339 6.15858C5.66981 5.69906 6.43809 5.72863 6.91088 6.2018L9.78397 9.07721C10.8864 10.1828 12.6775 10.1828 13.7822 9.07721L16.6712 6.1836C17.1508 5.70361 17.9259 5.70361 18.4055 6.1836Z"
            stroke={strokeColor}
            strokeWidth="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default ChildIcon;
