import { useGetParticipantsQuery } from "api/pods/participants";
import React, { useMemo } from "react";
import classNames from "classnames";
import Participant from "containers/Pods/Participant";

import { useTranslation } from "react-i18next";

import { Pods } from "types/Pods";
import Skeleton from "react-loading-skeleton";
import styles from "./Participants.module.scss";

export interface Props {
  roomId: string;
  excludeHost?: boolean;
  className?: string;
}

function Participants({
  className,
  roomId,
  excludeHost
}: Props): JSX.Element | null {
  const { t } = useTranslation("PodsParticipants");
  const { data } = useGetParticipantsQuery(
    { roomId },
    { pollingInterval: 2000 }
  );

  const processedData: Pods.Participant[] | undefined = useMemo(
    () =>
      excludeHost
        ? data?.filter((participant) => participant.status !== "host")
        : data,
    [data]
  );

  return processedData?.length ? (
    <div className={classNames(styles.wrapper, className)}>
      <h3>{t("attending", { count: processedData?.length })}</h3>

      <div className={styles.grid}>
        {processedData?.map((participant) => (
          <Participant
            key={participant.userUid}
            participant={participant}
            className={styles.participant}
            showProBadge={participant.badges.includes("creator")}
          />
        ))}
      </div>
    </div>
  ) : null;
}

Participants.Mock = function mock({ className }: { className?: string }) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <h3>
        <Skeleton />
      </h3>

      <div className={styles.grid}>
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />

        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
        <Participant.Mock className={styles.participant} />
      </div>
    </div>
  );
};

export default Participants;
