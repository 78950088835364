import Text from "../../Text";
import Icon from "../../Icon";
import styles from "./styles.module.scss";
import { ItemsProps, SelectProps } from "./types";
import { useTranslation } from "react-i18next";
import { IconProps } from "../../Icon/types";

const Select = ({ items, translation }: SelectProps) => {
    const { t } = useTranslation(translation);
    return (
        <ul className={styles.content}>
            {items.map(
                ({ title, icon, onClick, show }: any) =>
                    !!show && (
                        <li key={title} onClick={onClick}>
                            <Text> {t(`${title}`)} </Text>
                            <Icon size={18} variant={icon as any} />
                        </li>
                    )
            )}
        </ul>
    );
};

export default Select;
