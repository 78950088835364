import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import iconChatOn from "./assets/Chat_On.svg";
import iconChatOff from "./assets/Chat.svg";
import iconDiscoveryOn from "./assets/Discovery_On.svg";
import iconDiscoveryOff from "./assets/Discovery.svg";
import iconFeedOn from "./assets/Community_On.svg";
import iconFeedOff from "./assets/Community.svg";
import iconNotificationsOn from "./assets/Notification_On.svg";
import iconNotificationsOff from "./assets/Notification.svg";
import iconPodsOn from "./assets/pods_On.svg";
import iconPodsOff from "./assets/Pods.svg";
import iconPostsOn from "./assets/Blog_On.svg";
import iconPostsOff from "./assets/Blog.svg";
import iconWhoWavedOn from "./assets/Plus_On.svg";
import iconWhoWavedOff from "./assets/Plus.svg";

import styles from "./AppBarButton.module.scss";

export enum AppButtonFeature {
  Chat = "chat",
  Discovery = "discovery",
  Feed = "feed",
  Notifications = "notifications",
  Pods = "pods",
  Posts = "posts",
  WhoWaved = "who-waved"
}

const FEATURE_ICON_MAP = {
  [AppButtonFeature.Chat]: [iconChatOn, iconChatOff],
  [AppButtonFeature.Discovery]: [iconDiscoveryOn, iconDiscoveryOff],
  [AppButtonFeature.Feed]: [iconFeedOn, iconFeedOff],
  [AppButtonFeature.Notifications]: [iconNotificationsOn, iconNotificationsOff],
  [AppButtonFeature.Pods]: [iconPodsOn, iconPodsOff],
  [AppButtonFeature.Posts]: [iconPostsOn, iconPostsOff],
  [AppButtonFeature.WhoWaved]: [iconWhoWavedOn, iconWhoWavedOff]
};

export interface Props {
  className?: string;
  feature: AppButtonFeature;
  count?: number;
  selected?: boolean;
}

function AppBarButton({
  className,
  feature,
  count = 0,
  selected
}: Props): JSX.Element {
  const { t } = useTranslation("ComponentsAppBarButton");
  const [iconOn, iconOff] = FEATURE_ICON_MAP[feature];

  return (
    <Link
      to={`/${feature}`}
      title={t(`features.${feature}`)}
      className={classNames(
        styles.AppBarButton,
        {
          [styles.selected]: selected
        },
        className
      )}
    >
      <img
        src={selected ? iconOn : iconOff}
        alt={t("generic.icon")}
        data-testid="appButtonIcon"
      />
      {selected && count > 0 && (
        <div className={styles.count} data-testid="appIconCount">
          {count > 9 ? "9+" : count}
        </div>
      )}
    </Link>
  );
}

export default AppBarButton;
