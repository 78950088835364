import imageFeatureImageRegistrationJpg from "./featureImageRegistration.jpg";
import imageFeatureImageRegistrationJpg2x from "./featureImageRegistration@2x.jpg";
import imageFeatureImageRegistrationJpg3x from "./featureImageRegistration@3x.jpg";
import imageFeatureImageRegistrationWebP from "./featureImageRegistration.webp";
import imageFeatureImageRegistrationWebP2x from "./featureImageRegistration@2x.webp";
import imageFeatureImageRegistrationWebP3x from "./featureImageRegistration@3x.webp";

const images = {
  featureImageRegistration: {
    medium: {
      jpg: {
        "1x": imageFeatureImageRegistrationJpg,
        "2x": imageFeatureImageRegistrationJpg2x,
        "3x": imageFeatureImageRegistrationJpg3x,
      },
      webp: {
        "1x": imageFeatureImageRegistrationWebP,
        "2x": imageFeatureImageRegistrationWebP2x,
        "3x": imageFeatureImageRegistrationWebP3x,
      },
    },
  },
};
export default images;
