/* eslint-disable no-param-reassign */
import ReactGA from "react-ga4";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api as sessionApi } from "../../api/session";
import {
    AddToCartEvent,
    BeginCheckoutEvent,
    GAItem,
    MarketingOnboardingStepEvent,
    OnboardingStepEvent,
    PromotionEvent,
    PurchaseEvent,
    UserSigninEvent,
} from "./types";

const GAItemToGoogleItem = (item: GAItem) => ({
    item_id: item.id,
    item_name: item.name,
    coupon: item.coupon,
    discount: item.discount,
    price: item.price,
});

export const EventsSlice = createSlice({
    name: "events",
    initialState: { isOnboardingCompleted: false },
    reducers: {
        addToCart: (
            state,
            {
                payload: { currency, value, items },
            }: PayloadAction<AddToCartEvent>
        ) => {
            ReactGA.event("add_to_cart", {
                currency,
                value,
                items: items.map(GAItemToGoogleItem),
            });
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "add_to_cart", {
                    currency,
                    value,
                    items: items.map(GAItemToGoogleItem),
                });
            }
        },
        beginCheckout: (
            state,
            {
                payload: { currency, value, coupon, items },
            }: PayloadAction<BeginCheckoutEvent>
        ) => {
            ReactGA.event("begin_checkout", {
                currency,
                value,
                coupon,
                items: items.map(GAItemToGoogleItem),
            });
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "begin_checkout", {
                    currency,
                    value,
                    coupon,
                    items: items.map(GAItemToGoogleItem),
                });
            }
        },

        trackOnboardingStepAnswers: (
            state,
            {
                payload: { index, stepType, value },
            }: PayloadAction<MarketingOnboardingStepEvent>
        ) => {
            ReactGA.event(`track_onboarding_step_answers_${index}`, {
                stepType,
                value,
            });
            if (typeof window.fbq === "function") {
                window.fbq(
                    "trackCustom",
                    `conversion_onboarding_step_${index}`,
                    {
                        stepType,
                        value,
                    }
                );
            }
        },
        setOnboardingComplete: (state) => {
            state.isOnboardingCompleted = true;
        },

        // onboardingStep: (
        //     state,
        //     { payload: { stepName } }: PayloadAction<OnboardingStepEvent>
        // ) => {
        //     ReactGA.event(`onboarding_${stepName}`, {});
        //     if (typeof window.fbq === "function") {
        //         window.fbq("trackCustom", `onboarding_${stepName}`, {});
        //     }
        // },

        purchase: (
            state,
            {
                payload: { currency, value, coupon, items, transactionId },
            }: PayloadAction<PurchaseEvent>
        ) => {
            ReactGA.event("purchase", {
                currency,
                transaction_id: transactionId,
                value,
                coupon,
                items: items.map(GAItemToGoogleItem),
            });
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "purchase", {
                    currency,
                    transaction_id: transactionId,
                    value,
                    coupon,
                    items: items.map(GAItemToGoogleItem),
                });
            }
        },
        subscriptionsJoinReducers: (
            state,
            { payload: { type } }: PayloadAction<any>
        ) => {
            ReactGA.event("subscribe_join", {
                method: type,
            });
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "subscribe_join", {
                    method: type,
                });
            }
        },
        selectPromotion: (
            state,
            {
                payload: {
                    creativeName,
                    creativeSlot,
                    promotionId,
                    promotionName,
                    items,
                },
            }: PayloadAction<PromotionEvent>
        ) => {
            ReactGA.event("select_promotion");
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "select_promotion", {
                    creative_name: creativeName,
                    creative_slot: creativeSlot,
                    promotion_id: promotionId,
                    promotion_name: promotionName,
                    items: items.map(GAItemToGoogleItem),
                });
            }
        },

        userSignUp: (
            state,
            { payload: { method } }: PayloadAction<UserSigninEvent>
        ) => {
            ReactGA.event("sign_up", {
                method,
            });
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "sign_up", {
                    method,
                });
            }
        },
        viewPromotion: (
            state,
            {
                payload: {
                    creativeName,
                    creativeSlot,
                    promotionId,
                    promotionName,
                    items,
                },
            }: PayloadAction<PromotionEvent>
        ) => {
            const selectPromotion = {
                creative_name: creativeName,
                creative_slot: creativeSlot,
                promotion_id: promotionId,
                promotion_name: promotionName,
                items: items.map(GAItemToGoogleItem),
            };

            ReactGA.event("view_promotion", selectPromotion);
            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "view_promotion", selectPromotion);
            }
        },
        showOnboardingIntro: () => {
            ReactGA.event("show_onboarding_intro");
        },
        lpDownloadClick: () => {
            ReactGA.event("lp_download_click");

            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "lp_download_click");
            }
        },
        offerSubscribeClick: () => {
            ReactGA.event("offer_subscribe_click");

            if (typeof window.fbq === "function") {
                window.fbq("trackCustom", "offer_subscribe_click");
            }
        },
    },

    extraReducers: (builder) => {
        builder.addMatcher(
            sessionApi.endpoints.createSession.matchFulfilled,
            (
                _state,
                {
                    payload: {
                        me: { uid },
                    },
                }
            ) => {
                ReactGA.event("sign_in");
                window.AF("pba", "setCustomerUserId", uid);
                if (typeof window.fbq === "function") {
                    window.fbq("trackCustom", "sign_in");
                }
            }
        );
    },
});

export const {
    // onboardingStep,
    addToCart,
    beginCheckout,
    purchase,
    selectPromotion,
    setOnboardingComplete,
    showOnboardingIntro,
    subscriptionsJoinReducers,
    trackOnboardingStepAnswers,
    userSignUp,
    viewPromotion,
    lpDownloadClick,
    offerSubscribeClick
} = EventsSlice.actions;

export const { reducer } = EventsSlice;
