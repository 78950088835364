import style from "./style.module.scss";

import { iconMapping } from "./iconMapping";
import { IconSVGProps } from "./types";

const Icon = ({
    variant,
    size = 24,
    strokeColor = "black",
    colorScheme,
    fill = "none",
    outline = false,
    ...props
}: IconSVGProps) => {
    const SvgIcon = iconMapping[variant] || iconMapping["alert"];

    const variantColor = colorScheme === "primary" ? "#EA515A" : "black";

    return (
        <SvgIcon
            size={size}
            strokeColor={colorScheme ? variantColor : strokeColor}
            fill={colorScheme && !outline ? variantColor : fill}
            {...props}
        />
    );
};

export default Icon;
