import { IconProps } from "../../types";

const FacebookIcon = ({ size = 24 }: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.8529 13.0485L16.3439 9.81553H13.2725V7.71845C13.2725 6.83374 13.7008 5.97087 15.0764 5.97087H16.4737V3.21845C16.4737 3.21845 15.2062 3 13.995 3C11.4643 3 9.81182 4.54879 9.81182 7.35146V9.81553H7V13.0485H9.81182V20.8646C10.3764 20.9541 10.9539 21 11.5422 21C12.1305 21 12.708 20.9541 13.2725 20.8646V13.0485H15.8529Z"
            fill="#1877F2"
        />
    </svg>
);

export default FacebookIcon;
