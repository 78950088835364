import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface S3UploadRequest {
  signedUrl: string;
  fileBlob: Blob;
}

interface S3UploadResponse {
  etag?: string;
}

export const api = createApi({
  reducerPath: "api/s3upload",
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    upload: builder.mutation<S3UploadResponse, S3UploadRequest>({
      query: ({ signedUrl, fileBlob }) => ({
        url: signedUrl,
        method: "PUT",
        body: fileBlob,
        headers: {
          "Content-Type": "image/jpeg"
        }
      }),
      transformResponse: (_, meta) => ({
        etag: meta?.response?.headers?.get("etag")?.replace(/"/g, "")
      })
    })
  })
});

export const { useUploadMutation } = api;
