import React from "react";
import classNames from "classnames";
import Button, { Props } from "../Button";
import styles from "./RoundButton.module.scss";

function RoundButton({ children, onClick, className }: Props): JSX.Element {
  return (
    <Button onClick={onClick} className={classNames(styles.wrapper, className)}>
      {children}
    </Button>
  );
}

export default RoundButton;
