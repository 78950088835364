import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Avatar from "components/Avatar";
import type { UserReaction } from "types/UserReaction";
import styles from "./UserReactionListItem.module.scss";

export interface Props {
  className?: string;
  userReaction: UserReaction;
}

function ReactionListItem({
  className,
  userReaction: { label, user }
}: Props): JSX.Element {
  const { t } = useTranslation("ComponentsReactionListItem");

  return (
    <li className={classNames(styles.UserReactionListItem, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.avatar}>
        <Avatar
          avatarUrl={user.avatarUrl}
          firstName={user.firstName}
          className={styles.avatar}
        />
      </div>
      <div className={styles.meta}>
        <div>{user.firstName}</div>

        <div>{user.location?.name}</div>
      </div>
      <div className={styles.actions}>
        <button type="button">{t("action")}</button>
      </div>
    </li>
  );
}

export default ReactionListItem;
