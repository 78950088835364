import { Api } from "types/api/audio_room";
import { Pods } from "types/Pods";

export const transform = (source: Api.AudioRoomReaction): Pods.Reaction => ({
  id: source.id,
  userUid: source.user_uid,
  roomId: source.room_id,
  code: source.code,
  createdAt: source.created_at,
});

export default transform;
