import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Api } from "types/api/geolocation";
import { Geolocation } from "types/Geolocation";
import { Base64 } from "js-base64";

export type TagsGetRequest = {
    groups?: string[];
};

export const api = createApi({
    reducerPath: "api/geolocation",
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_BASE_URL,
        prepareHeaders: (headers) => {
            const token = Base64.encode(
                `${import.meta.env.VITE_PEANUT_BACKEND_USER}:${
                    import.meta.env.VITE_PEANUT_BACKEND_TOKEN
                }`
            );

            headers.set("Authorization", `Basic ${token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getGeolocation: builder.query<Geolocation, void>({
            query: () => ({
                url: "/web/geolocation",
            }),
            transformResponse: ({
                accuracy_radius,
                latitude,
                longitude,
                time_zone,
                country_iso,
                ip,
            }: Api.Geolocation): Geolocation => ({
                accuracyRadius: accuracy_radius,
                latitude,
                longitude,
                timeZone: time_zone,
                countryIso: country_iso,
                ip,
            }),
        }),
    }),
});

export const { useGetGeolocationQuery } = api;
