import { z } from "zod";

export const FacebookConversionsSchema = z.object({
    event_name: z.string(),
    action_source: z.string(),
    fbc: z.string().optional(),
    fbp: z.string().optional(),
    custom_data: z
        .object({
            value: z.any(),
            currency: z.string().optional(),
        })
        .optional(),

    event_source_url: z.string(),
});
