/* eslint-disable import/prefer-default-export */
// import crossFetch from "cross-fetch";

import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import type { RootState } from "store/Store";

const rawBaseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_URL,
  // fetchFn: crossFetch,
  prepareHeaders: (headers, { getState }) => {
    const { authToken } = (getState() as RootState).session;
    headers.set("PEANUT-CLIENT", import.meta.env.VITE_PEANUT_CLIENT!);
    headers.set("Authorization", `Bearer ${authToken}`);
    return headers;
  },
});

export const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const x = await rawBaseQuery(args, api, extraOptions);

  return x;
};
